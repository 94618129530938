import { AccumulationChartComponent, Inject, AccumulationDataLabel, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, PieSeries, AccumulationLegend, AccumulationTooltip } from '@syncfusion/ej2-react-charts'
import React from 'react'
import arrayColors from './ArrayColors.json'

export default function ChartDonout({ data, name, idData }) {

    let newObjeto = data?.map((item) => {
        let randomIndex = Math.floor(Math.random() * 8);
        return {
            ...item,
            HexColor: arrayColors[randomIndex]
        };
    });

    return (
        <AccumulationChartComponent id={`${idData}`} title={`${name}`} legendSettings={{ visible: false }} enableSmartLabels={true} enableAnimation={false} center={{ x: '50%', y: '50%' }} enableBorderOnMouseMove={false} tooltip={{ enable: true, format: '<b>${point.x}</b><br>Cantidad: <b>${point.y}</b>', header: "" }} >
            <Inject services={[AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
            <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective dataSource={newObjeto} pointColorMapping="HexColor" name='Browser' xName='Nombre' yName='Valor' explode={true} explodeOffset='10%' explodeIndex={0} startAngle={75} dataLabel={{ visible: true, position: 'Outside', name: 'text', font: { fontWeight: '600' }, connectorStyle: { length: '20px', type: 'Curve' } }} radius={'60%'} />
            </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
    )
}

