import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as ClearIcon } from "./FirmaAutografa/clear.svg";
import SignaturePad from "react-signature-canvas";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch,  useSelector } from "react-redux";
import { getFile } from "../../../../store/actions";
import { SignatureService } from "../../../../services/SignatureService";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertConfirm } from "../../../../components/Alert";
import GrabacionVideo from "./GrabacionVideo";
import { makeStyles } from "@mui/styles";
import { GeoLocationGeneric } from "../../../../components/GeoLocationGeneric";
import { createCanvas, loadImage } from "canvas";
import { obtenerDireccionIP } from "../../../../utils";
import IndexVideo from "../indexVideo";
import { CustomModalDialogGeolocation } from "../../../../components/CustomModalDialogGeolocation";
import { renderToString } from "react-dom/server";

const useStyles = makeStyles((theme) => ({
  sigPad: {
    width: "102%",
    height: "102%",
  },
  paperResponsive: {
    display: "flex",
    padding: 12,
    flexWrap: "wrap",
    borderLeft: `1.8px solid ${theme.palette.secondary.main60}`,
    alignContent: "space-between",
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      borderLeft: `0px solid ${theme.palette.secondary.main60}`,
      padding: 7,
      minHeight: "auto",
    },
  },
  botonReponsive: {
    minWidth: '70%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  firmaAqui: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '@media only screen and (max-width: 812px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  signPlaceholder: {
    color: theme.palette.primary.main,
    alignSelf: 'center',
    fontWeight: 550,
    fontSize: 18
  }
}));

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  let atob = require("atob");
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export default function FirmaAutografa({ setValue, setOpenModal, setOpenModalReenvio, isActiveMassiveSignature }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timerSeconds, setTimerSeconds] = useState(0);
  const document = useSelector((state) => state.File.document);
  const fileState = useSelector((state) => state.File.item);
  const stageFirma = useSelector((state) => state.File.item?.SignatureOptions);
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );
  const fileAuthentication = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.EnviromentVars
  );
  const participant = fileState?.Participants?.filter(
    (part) => part.UserId === userData.Id && part.Sign === true
  );
  const participantStage = useSelector(
    (state) => state.File.item?.SignatureOptions?.SignatureType
  );

  const containerSignDetectRef = useRef(null);
  const canvasRef = useRef(null);
  const [hidden, setHidden] = useState(false);
  const [reloadSing, setReloadSing] = useState(false);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [inicioFirma, setInicioFirma] = useState();
  const [bodyGUID, setBodyGUID] = useState(null);
  const [ipDirection, setIpDirection] = useState(null);
  const [bodySignInfo, setBodySignInfo] = useState(null);
  const [finFirma, setFinFirma] = useState();
  const [urlImagen, setUrlImagen] = useState();
  const [isWriteSign, setIsWriteSign] = useState(false);
  const [estaFirmando, setEstaFirmando] = useState(false)
  const [reloadGeolocation, setReloadGeolocation] = useState(true)
  const [geolocationIP, setGeolocationIP] = useState(false);
  const [openAlerte, setOpenAlerte] = useState(false);

  useEffect(() => {
    if (!bodyGUID) {
      sincronizacionSing();
      setBodySignInfo(null);
    }
  }, []);

  useEffect(() => {
    console.log("isWriteSign'''''.............:useEffect:...............: ", isWriteSign)
  }, [isWriteSign]);

  useEffect(() => {
    console.log("estaFirmando'''''.............:useEffect:...............: ", estaFirmando)
  }, [estaFirmando]);

  useEffect(() => {
    const obtenerIP = async () => {
      obtenerDireccionIP((ip) => {
        if (ip) {
          setIpDirection(ip);
          console.log("Dirección IP:", ip);
        } else {
          console.log("La dirección IP está vacía o nula. Intentando de nuevo...");
          obtenerIP(); // Llamamos a la función de nuevo si la dirección IP es vacía o nula
        }
      });
    };
    obtenerIP(); // Inicializamos el proceso de obtención de la dirección IP
  }, []);


  const sincronizacionSing = async () => {
    try {
      const signatureService = SignatureService.getInstance();
      const responseSync = await signatureService.autographSignSync({
        File_Id: fileState?.FileData?.Id
      })

      if (responseSync?.Body) {
        setBodyGUID(responseSync?.Body)
        traerCodigoQR(responseSync?.Body)
      }
      console.log('responseSync', responseSync)
    } catch (error) {
      console.log(error)
    }
  }

  const traerCodigoQR = async (data) => {
    const signatureService = SignatureService.getInstance();
    const responseSync = await signatureService.postFileQRSignPost({
      Body: data.Url
    });
    const url = window.URL.createObjectURL(new Blob([responseSync.data.data]));
    setUrlImagen(url);
  };

  const signSyncInfo = async () => {
    try {
      const signatureService = SignatureService.getInstance();
      const responseSyncInfo = await signatureService.autographSignSyncInfo({
        Body: {
          File_Id: fileState?.FileData?.Id
        }
      })
      if (responseSyncInfo?.Body?.Status === 'Firma Realizada') {
        setBodySignInfo(responseSyncInfo?.Body);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/Android/i)) {
      if (timerSeconds === 0) {
        // console.log('PIDIENDO')
        signSyncInfo();
        setTimerSeconds(5)
      }
      // salir cuando lleguemos a 0
      if (!timerSeconds) return;
      const intervalId = setInterval(() => {
        setTimerSeconds(timerSeconds - 1);
      }, 1000);
      // clear interval al volver a renderizar para evitar pérdidas de memoria
      return () => clearInterval(intervalId);
    }
  }, [timerSeconds]);

  console.log('participantStage', participantStage, participant)

  let handleModalButton = async () => {
    try {
      if (!lat && !lon && (fileState?.CheckGPS === true || fileState?.CheckGPS == null || fileState?.CheckGPS == undefined)) {
        setReloadGeolocation(true)
        return null;
      }

      //let cnv = bodySignInfo ? null : canvasRef.current.getCanvas().toDataURL("image/png");
      let cnv = bodySignInfo ? null : canvasRef.current.getSignaturePad().toDataURL("image/png");

      const puntos = bodySignInfo ? null : canvasRef.current.toData();
      console.log('cnvcnvcnv', cnv)
      let signSplitted;
      // Función para ajustar el tamaño de la imagen
      if (cnv) {
        const resizeImage = async (base64Image, width, height) => {
          const canvas = createCanvas(width, height);
          const context = canvas.getContext("2d");
          const image = await loadImage(base64Image);
          context.drawImage(image, 0, 0, width, height);
          const resizedBase64Image = canvas.toDataURL("image/png");
          return resizedBase64Image;
        };
        const resizedImage = await resizeImage(cnv, 390, 190);
        console.log('resizedImage', resizedImage);
        signSplitted = bodySignInfo ? null : resizedImage.split(",");
      }

      let sign = bodySignInfo ? null : signSplitted[1];

      console.log('FIRMAS', sign, bodySignInfo?.SignData)
      // return;

      const signatureService = SignatureService.getInstance();
      const body = {
        ObjectId: document?.Id,
        Signature: bodySignInfo ? bodySignInfo?.SignData : sign,
        TimeStamp: `${new Date().getTime()}`,
        ContinueWorkFlow: true,
        Width: 590,
        Height: 290,
        OriginLocation: (fileState?.CheckGPS == null || fileState?.CheckGPS == undefined) ? 'GPS' : fileState?.CheckGPS === false ? null : (bodySignInfo ? bodySignInfo?.SignInfo?.OriginLocation : (geolocationIP === true ? 'IP' : 'GPS')),
        Latitude: (fileState?.CheckGPS == null || fileState?.CheckGPS == undefined || fileState?.CheckGPS === true) ? (bodySignInfo ? bodySignInfo?.SignInfo?.Latitude : lat) : null,
        Longitud: (fileState?.CheckGPS == null || fileState?.CheckGPS == undefined || fileState?.CheckGPS === true) ? (bodySignInfo ? bodySignInfo?.SignInfo?.Longitud : lon) : null,
        PuntosControl: bodySignInfo ? bodySignInfo?.SignInfo?.PuntosControl : puntos,
        MacAddress: bodySignInfo &&
          (!navigator.userAgent.match(/iPhone/i) &&
            !navigator.userAgent.match(/Android/i)) ?
          bodySignInfo?.SignInfo?.MacAddress :
          ipDirection !== undefined && //Feliz cumple brayan  29/08/2023 
            ipDirection !== null && ipDirection !== "" ?
            ipDirection : "000.000.000.000",
        // MacAddress: bodySignInfo ? bodySignInfo?.SignInfo?.MacAddress : ipDirection,
        StartedAt: bodySignInfo ? bodySignInfo?.SignInfo?.StartedAt : inicioFirma,
        EndedAt: bodySignInfo ? bodySignInfo?.SignInfo?.EndedAt : finFirma,
        signetAtFSimple: bodySignInfo ? bodySignInfo?.SignInfo?.signetAtFSimple : new Date().toISOString(),
        TrackingData: null,
        Length: "",
        IsMasive: isActiveMassiveSignature
      };
      setReloadSing(true);
      console.log("autographSignDocument------------++++++++++++++: ", body)

      if ((body.StartedAt === undefined || body.StartedAt === null || body.StartedAt === "") && (body.EndedAt === undefined || body.EndedAt === null || body.EndedAt === "")) {
        clearCanvas()
        Alert({
          icon: "error",
          text: `Hemos detectado que no hay un trazo dentro del panel, por favor realiza el trazado de tu firma y da clic en “Enviar firma”.`,
          okbtntext: "Aceptar",
        });
        setReloadSing(false);
        return null;
      }

      console.log('body', body)
      const resSign = await signatureService.autographSignDocument(body);
      console.log('resSign', resSign)
      
      if (resSign.Body) {
        setReloadSing(false);

        if (participantStage === 'Simple' || participantStage === 'Avanzada') {
          if (stageFirma.VideoRecording) {
            dispatch(getFile({ FileId: fileState.FileData.Id }));
            setOpenModal(true);
            console.log('ENTRA AL STATE DE GRABACION');
            if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true) {
              return <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
            } else if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === false) {
              return <IndexVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
            }
          } else {
            setOpenModal(true);
            setOpenModalReenvio(true);
          }
        } else {
          if (participant[0].SignatureOptions.VideoRecording === true) {
            dispatch(getFile({ FileId: fileState.FileData.Id }));
            setOpenModal(true);
            console.log('ENTRA AL STATE DE GRABACION');
            if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true) {
              return <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
            } else if (fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === false) {
              return <IndexVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
            }
          } else {
            setOpenModal(true);
            setOpenModalReenvio(true);
          }
        }
      }
    } catch (error) {
      console.log('errorerror', error)
      Alert({
        icon: "error",
        text: `Ha ocurrido un error por favor intenta de nuevo. ${error.message}`,
        okbtntext: "Aceptar",
      });
      setReloadSing(false);
      clearCanvas()
    }
  };

  const confirmSignUser = () => {
    let imageSRC = '';
    if (canvasRef.current) {
      const dataURL = canvasRef.current.getCanvas().toDataURL('image/png');
      imageSRC = dataURL
    } else if (bodySignInfo) {
      const imageSource = `data:image/jpeg;base64,${bodySignInfo.SignData}`;
      imageSRC = imageSource
    }

    console.log('bodySignInfo', bodySignInfo, imageSRC)

    AlertConfirm(
      {
        icon: null,
        html: renderToString(
          <Box>
            <Typography
              textAlign={"center"}
              variant="h6"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              Asegúrate que tu firma fue trazada correctamente
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: "center" }}>
              {imageSRC && <img style={{ border: '1px solid red', borderRadius: '10px' }} src={imageSRC} alt="Captura de Canvas" />}
            </Box>
          </Box>
        ),
        okbtntext: "Aceptar",
        notbtntext: "Cancelar",
      },
      (result) => {
        if (result.isConfirmed) {
          handleModalButton();
        } else if (result.dismiss === "cancel" && result.isDismissed) {
          resetFirma()
          clearCanvas()
        }
      }
    );
  };

  let setPlaceholder = async () => {
    console.log("setPlaceholder................: ", isWriteSign)
    setIsWriteSign(true)
    console.log("setIsWriteSign(true) ................: ", isWriteSign)
    if (!inicioFirma) {
      setInicioFirma(new Date().toISOString());
    }
    setHidden(true);
  };

  let finaliza = () => {
    console.log("finaliza................: ", isWriteSign)
    setIsWriteSign(false)
    setFinFirma(new Date().toISOString());
  };

  let clearCanvas = () => {
    try {
      canvasRef.current.clear();
      setFinFirma(null);
      setInicioFirma(null);
      setHidden(false);

      // PARA EL PAD
      setBodyGUID(null);
      setUrlImagen(null);
      setBodySignInfo(null);
      setTimerSeconds(0);
      sincronizacionSing();
    } catch (e) {

    }
  };

  const resetFirma = () => {
    sincronizacionSing();
    setBodyGUID(null);
    setUrlImagen(null);
    setBodySignInfo(null);
    setTimerSeconds(0);
  }

  const handleMouseEnter = () => {
    setEstaFirmando(true);
  };

  const handleTouchMove = (event) => {
    try {
      if (!estaFirmando) return;

      const rect = containerSignDetectRef.current.getBoundingClientRect();
      const touch = event.touches[0];

      if (touch.clientX < rect.left || touch.clientX > rect.right ||
        touch.clientY < rect.top || touch.clientY > rect.bottom) {
        handleMouseLeave();
      }
    } catch (e) { }
  };

  const handleMouseLeave = () => {
    if (estaFirmando && isWriteSign) {
      // Realizar acciones necesarias cuando se sale del div al firmar
      Alert({
        icon: "warning",
        okbtntext: "Entendido",
        text: "Detectamos que tu firma se plasmó fuera de los límites, por favor, dibuja tu grafo sin salirte de los bordes.",
      }, (event) => {
        clearCanvas()
      });
      clearCanvas()
    }
    setEstaFirmando(false);
  };

  useEffect(() => {
    if (geolocationIP === true) {
      setReloadGeolocation(true)
    }
  }, [geolocationIP])

  return (
    <Fragment>
      {((fileState?.CheckGPS === true || fileState?.CheckGPS == null || fileState?.CheckGPS == undefined) && reloadGeolocation) &&
        <GeoLocationGeneric SuccessFN={(latitude, longitude) => {
          console.log("succes,latitude, longitude ", latitude, longitude);
          setLat(latitude);
          setLon(longitude);
        }}
          ErrorFN={(error) => console.log('error', error)}
          setOpenAlerte={setOpenAlerte}
          ReloadGeoLocation={reloadGeolocation}
          setGeolocationIP={setGeolocationIP}
          geolocationIP={geolocationIP}
          setReloadGeoLocation={setReloadGeolocation} />}
      <CustomModalDialogGeolocation Open={openAlerte} setOpenAlerte={setOpenAlerte} setGeolocationIP={setGeolocationIP} />
      <Box sx={{ mt: 2 }}>
        <Paper elevation={2} className={classes.paperResponsive}>
          <Grid container>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Box>
                <Typography variant="titulo">
                  Firma Autógrafa en Mensaje de Datos
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Box sx={{ mt: 1, mb: 3 }}>
                <Typography variant="subtitle1">
                  Al firmar estás aceptando la aplicación de tu Firma Autógrafa en Mensaje de Datos mostrada en el visor
                </Typography>
              </Box>
            </Grid>
            <Grid container justifyContent={'center'}>
              <Box sx={{ textAlign: 'center', overflow: 'hidden' }}>
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  height: '25vh',
                  alignContent: 'center',
                  marginTop: '0px',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textAlign: 'center',
                  justifyContent: 'center',
                  border: `2px solid #C20E30`,
                  position: 'relative',
                  borderRadius: '8px',
                  // backgroundColor: "#FFFFFF",
                  '@media only screen and (min-width: 301px) and (max-width: 600px)': {
                    height: '33vh',
                    maxWidth: '300px',
                  },
                  '@media only screen and (min-width: 601px) and (max-width: 896px)': {
                    height: '55vh',
                    maxWidth: '600px',
                  },
                }} ref={containerSignDetectRef}
                  onTouchStart={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onTouchEnd={handleMouseLeave}
                  onTouchMove={handleTouchMove}
                >
                  {bodySignInfo?.SignData ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ width: '70%', height: '100%', p: 2 }}>
                        <img src={`data:image/jpeg;base64,${bodySignInfo?.SignData}`} alt="ImagenQR" style={{ width: '100%!important', height: '100%!important' }} />
                      </Box>
                    </Box>
                  ) : <><SignaturePad
                    ref={canvasRef}
                    backgroundColor="rgba(255,255,255, 0.0)"
                    penColor="black"
                    canvasProps={{ className: classes.sigPad }}
                    onBegin={setPlaceholder}
                    onEnd={finaliza}
                  />
                    {hidden ? null :
                      <Box className={classes.firmaAqui}>
                        <Typography
                          variant="body1" 
                          gutterBottom
                          className={classes.signPlaceholder}
                        >
                          Traza tu firma aquí
                        </Typography>
                      </Box>}
                  </>}
                </Box>
                <Box sx={{
                  width: "100%", display: 'flex',
                  alignContent: 'flex-end',
                  alignItems: 'flex-start',
                  overflow: 'hidden',
                  marginTop: '16px',
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                }}>
                  {hidden ? (
                    <ClearIcon
                      sx={{ height: 100, width: 100 }}
                      onClick={clearCanvas}
                    />
                  ) : (
                    <ClearIcon
                      sx={{ height: 100, width: 100 }}
                      fill="#818285"
                      onClick={clearCanvas}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 1 }}>
                <Box className={classes.botonReponsive}>
                  <Box sx={{ mt: 0.8 }}>
                    <LoadingButton
                      fullWidth
                      disabled={hidden || bodySignInfo?.SignData ? false : true}
                      sx={{ height: 45 }}
                      variant="contained"
                      size="medium"
                      onClick={confirmSignUser}
                      loading={reloadSing}
                    >
                      <Typography sx={{ letterSpacing: 1.2 }}>Enviar firma</Typography>
                    </LoadingButton>
                  </Box>
                  <Box sx={{ mt: 0.8 }}>
                    <Button
                      sx={{ height: 45 }}
                      fullWidth
                      variant="outlined"
                      size="medium"
                      onClick={() => setValue(0)}
                    >
                      <Typography sx={{ letterSpacing: 1.2, fontWeight: 550 }}>
                        Cancelar
                      </Typography>
                    </Button>
                  </Box>
                  {bodySignInfo?.SignData ? (
                    <Box sx={{ mt: 0.8 }}>
                      <Button
                        sx={{ height: 45 }}
                        fullWidth
                        variant="outlined"
                        size="medium"
                        onClick={() => resetFirma()}
                      >
                        <Typography sx={{ letterSpacing: 1.2, fontWeight: 550 }}>
                          Reintentar
                        </Typography>
                      </Button>
                    </Box>) : null
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ display: { xs: 'none', md: 'block' } }}>
              {
                bodySignInfo?.SignData ? (
                  null
                ) : (
                  <>
                    <Box sx={{ mt: 3, mb: 1, ml: 1 }}>
                      <Typography variant="subtitle1">
                        Para una mejor experiencia de firmado, escanea este código QR desde tu dispositivo móvil y traza tu firma.
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                      <img src={urlImagen} alt="ImagenQR" style={{ width: '35%' }} />
                    </Box>
                  </>
                )
              }
            </Grid>
          </Grid>

        </Paper>
      </Box>
      {/* <Dialog
        maxWidth="sm"
        fullWidth
        open={signed}
      >
        <Box sx={{ p: 5 }}>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            Documento firmado
          </Typography>
          <Typography variant="body2" align="center" sx={{ mb: 3 }}>
            La Firma Autógrafa en Mensaje de Datos, ha sido aplicada con éxito
            al documento. <br />
            Los participantes serán notificados de la aplicación de su firma.
          </Typography>
          <Box
            sx={{ mx: "auto", maxWidth: "70%", borderTop: "1px solid #ddd" }}
          >
            <Button
              color="primary"
              onClick={() => reenviarVentana()}
              sx={{ mx: "auto", display: "flex", mt: 1, minWidth: 100 }}
            >
              Cerrar
            </Button>
          </Box>
        </Box>
      </Dialog> */}
    </Fragment>
  );
}
