import { graphicsConstants } from "../constants";
import { GraphicsService } from "../../services/GraphicsService";

const request = (actionType) => {
    return {
        type: actionType,
    };
};

const success = (filesData, actionType) => {
    return {
        type: actionType,
        payload: filesData,
    };
};

const failure = (error, actionType) => {
    return {
        type: actionType,
        payload: error,
    };
};


export const GetGraphicsByLastMonth = (filesData) => {
    return async (dispatch) => {
        dispatch(request(graphicsConstants.GET_FILES_CHARTS_BY_LASTMONTH_REQUEST));
        try {
            const graphicsService = GraphicsService.getInstance();
            const fileData = await graphicsService.GetGraphicsByLastMonth(filesData);
            dispatch(success(fileData.Body, graphicsConstants.GET_FILES_CHARTS_BY_LASTMONTH_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, graphicsConstants.GET_FILES_CHARTS_BY_LASTMONTH_FAILTURE));
        }
    };
};


export const GetGraphicsByApplicant = (filesData) => {
    return async (dispatch) => {
        dispatch(request(graphicsConstants.GET_FILES_CHARTS_BY_APPLICANT_REQUEST));
        try {
            const graphicsService = GraphicsService.getInstance();
            const fileData = await graphicsService.GetGraphicsByApplicant(filesData);
            dispatch(success(fileData.Body, graphicsConstants.GET_FILES_CHARTS_BY_APPLICANT_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, graphicsConstants.GET_FILES_CHARTS_BY_APPLICANT_FAILTURE));
        }
    };
};


export const GetGraphicsByTypeSign = (filesData) => {
    return async (dispatch) => {
        dispatch(request(graphicsConstants.GET_FILES_CHARTS_BY_TYPESIGN_REQUEST));
        try {
            const graphicsService = GraphicsService.getInstance();
            const fileData = await graphicsService.GetGraphicsByTypeSign(filesData);
            dispatch(success(fileData.Body, graphicsConstants.GET_FILES_CHARTS_BY_TYPESIGN_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, graphicsConstants.GET_FILES_CHARTS_BY_TYPESIGN_FAILTURE));
        }
    };
};


export const GetGraphicsByStatusDeadLine = (filesData) => {
    return async (dispatch) => {
        dispatch(request(graphicsConstants.GET_FILES_CHARTS_BY_STATUS_DEADLINE_REQUEST));
        try {
            const graphicsService = GraphicsService.getInstance();
            const fileData = await graphicsService.GetGraphicsByStatusDeadLine(filesData);
            dispatch(success(fileData.Body, graphicsConstants.GET_FILES_CHARTS_BY_STATUS_DEADLINE_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, graphicsConstants.GET_FILES_CHARTS_BY_STATUS_DEADLINE_FAILTURE));
        }
    };
};

export const GetFilesChartCurrentMonth = (filesData) => {
    return async (dispatch) => {
        dispatch(request(graphicsConstants.GET_FILES_CHARTS_BY_CURRENT_MONTH_REQUEST));
        try {
            const graphicsService = GraphicsService.getInstance();
            const fileData = await graphicsService.GetFilesChartCurrentMonth(filesData);
            dispatch(success(fileData.Body, graphicsConstants.GET_FILES_CHARTS_BY_CURRENT_MONTH_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, graphicsConstants.GET_FILES_CHARTS_BY_CURRENT_MONTH_FAILTURE));
        }
    };
};

export const GetFilesByStatus = (filesData) => {
    return async (dispatch) => {
        dispatch(request(graphicsConstants.GET_FILES_CHARTS_BY_STATUS_REQUEST));
        try {
            const graphicsService = GraphicsService.getInstance();
            const fileData = await graphicsService.GetFilesByStatus(filesData);
            dispatch(success(fileData.Body, graphicsConstants.GET_FILES_CHARTS_BY_STATUS_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, graphicsConstants.GET_FILES_CHARTS_BY_STATUS_FAILTURE));
        }
    };
};
