export const paymentsConstants = {
    GET_PLANS_REQUEST: "GET_PLANS_REQUEST",
    GET_PLANS_SUCCESS: "GET_PLANS_SUCCESS",
    GET_PLANS_FAILURE: "GET_PLANS_FAILURE",

    PAYMENT_SELECTEDPLAN_USER_SUCCESS: "PAYMENT_SELECTED_PLANUSER_SUCCESS",
    PAYMENT_RESETPLAN_USER: "PAYMENT_RESETPLAN_USER",

    PAYMENT_GETUSERDATA_REQUEST: "PAYMENT_GETUSERDATA_REQUEST",
    PAYMENT_GETUSERDATA_SUCCESS: "PAYMENT_GETUSERDATA_SUCCESS",
    PAYMENT_GETUSERDATA_FAILURE: "PAYMENT_GETUSERDATA_FAILURE",
    PAYMENT_GETUSERDATA_RESET: "PAYMENT_GETUSERDATA_RESET",

    PAYMENT_GETBILLING_REQUEST: "PAYMENT_GETBILLING_REQUEST",
    PAYMENT_GETBILLING_SUCCESS: "PAYMENT_GETBILLING_SUCCESS",
    PAYMENT_GETBILLING_FAILURE: "PAYMENT_GETBILLING_FAILURE",

    PAYMENT_REGISTER_BILLING_REQUEST: "PAYMENT_REGISTER_BILLING_REQUEST",
    PAYMENT_REGISTER_BILLING_SUCCESS: "PAYMENT_REGISTER_BILLING_SUCCESS",
    PAYMENT_REGISTER_BILLING_FAILURE: "PAYMENT_REGISTER_BILLING_FAILURE",
    PAYMENT_REGISTER_BILLING_RESET: "PAYMENT_REGISTER_BILLING_RESET",

    PAYMENT_GET_REGIMENFISCAL_REQUEST: "PAYMENT_GET_REGIMENFISCAL_REQUEST",
    PAYMENT_GET_REGIMENFISCAL_SUCCESS: "PAYMENT_GET_REGIMENFISCAL_SUCCESS",
    PAYMENT_GET_REGIMENFISCAL_FAILURE: "PAYMENT_GET_REGIMENFISCAL_FAILURE",

    PAYMENT_CANCEL_SUSCRIPCION_REQUEST: "PAYMENT_CANCEL_SUSCRIPCION_REQUEST",
    PAYMENT_CANCEL_SUSCRIPCION_SUCCESS: "PAYMENT_CANCEL_SUSCRIPCION_SUCCESS",
    PAYMENT_CANCEL_SUSCRIPCION_FAILURE: "PAYMENT_CANCEL_SUSCRIPCION_FAILURE",
    PAYMENT_CANCEL_SUSCRIPCION_RESET: "PAYMENT_CANCEL_SUSCRIPCION_RESET",
};
