import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Draggable from "react-draggable";
import {
  Avatar,
  Box,
  Button,
  Alert as AlertMUI,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { TemplatesServices } from "../../services/TemplatesServices";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ContinueWorkFlow, getFile } from "../../store/actions";
import { LoadingButton } from "@mui/lab";
import { FileFlowService } from "../../services/FileFlowService";
import { Alert } from "../../components/Alert";
import BackdropComponent from "../../components/BackDrop";
import { UseImage } from "../../components/UseImage";
import HelpVideoEdicion from "../../assets/FirmadoZonas.mp4";
import HelpPages from "../../components/HelpPages.jsx";
import { CustomModalAlert } from "../../components/CustomModalAlert";
import { useNavigate } from "react-router-dom";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import { GenerateRandom } from "../../utils";
import { Icon } from "@iconify/react";
import { CustomInputField } from "../../components/CustomInputField";
import { useForm } from "react-hook-form";
import Color from "./Color.json";

const ButtonDocLIst = styled(Button)(({ Button }) => ({
  width: "100%",
  color: " black",
  fontSize: "15px",
  fontWeight: 500,
  minHeight: "44px",
  maxHeight: 60,
  borderRadius: "5px",
  display: "flex",
  textAlign: "left",
  alignItems: "flex-start",
  padding: "0.7em",
  marginTop: "5px",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

export default function ConfigFirmDragTemplates(props) {
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const templateData = useSelector((state) => state.TemplatesData);
  const [loadignDoc, setLoadignDoc] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [mueve, setMueve] = useState(true);
  const [imagesDocument, setImagesDocument] = useState([]);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  const [usersSigners, setUsersSigners] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  const [activeSendData, setActiveSendData] = useState(false);

  console.log("templateData", templateData);

  useEffect(() => {
    if (
      templateData.rolesUsers !== null ||
      templateData.rolesUsers !== undefined
    ) {
      setUsersSigners(templateData.rolesUsers);
    }
  }, [templateData.rolesUsers]);

  const nextStage = async (skip) => {
    try {
      if (props.redEdition === true) {
        let Body = {
          File_Id: fileState?.item?.FileData?.Id,
          Status: null,
          Result: null,
          Stage_Id: null,
          Comments: null,
        };
        dispatch(ContinueWorkFlow(Body));
        console.log("EDICION DE ARCHIVO");
        return navigate("/inbox/files");
      } else {
        setLoadingNext(true);
        const fileFlowService = FileFlowService.getInstance();
        await fileFlowService.registerStatusStage({
          File_id: folioIdSolicitud,
          Status: "Vista previa",
          Result: null,
        });
        if (newFileState.editStack) {
          dispatch(getFile({ FileId: folioIdSolicitud }));
        }
        setLoadingNext(false);
        props.handleNextStep();
        if (skip === true) {
          skipStep();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const skipStep = async () => {
    console.log("ENTRO-SKIP 1");
    try {
      const templatesServices = TemplatesServices.getInstance();
      await templatesServices.DeleteTraceSignDocument(folioIdSolicitud);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setImagesDocument(null);
    traerDocumentoImages();
  }, [fileState.item]);

  useEffect(() => {
    if (activeSendData) {
      setActiveSendData(false);
      enviarDatos();
    }
  }, [coordinates, activeSendData]);

  const traerDocumentoImages = async () => {
    setImagesDocument(null);
    setDocumentoSeleccionado(templateData.item?.DocumentTemplate_Id);

    try {
      setLoadignDoc(true);
      const templatesServices = TemplatesServices.getInstance();
      const responseDocsImages =
        await templatesServices.ConvertDocToImagesProps(
          templateData.item?.DocumentTemplate_Id
        );

      if (
        responseDocsImages.Message !== null &&
        responseDocsImages.Message !== undefined &&
        responseDocsImages.Message !== ""
      ) {
        return Alert({
          icon: "error",
          okbtntext: "Aceptar",
          text: `${responseDocsImages.Message}`,
        });
      }
      if (responseDocsImages.Response.TracesSign !== null) {
        setCoordinates(responseDocsImages?.Response?.TracesSign?.Traces);
        setImagesDocument(responseDocsImages?.Response);
        setMueve(false);
      } else {
        let newData = [];
        let numHojas = responseDocsImages?.Response?.Properties.length;
        for (let i = 0; i < numHojas; i++) {
          newData.push({
            NumberPage: i + 1,
            Points: [],
          });
        }
        setImagesDocument(responseDocsImages?.Response);
        setCoordinates(newData);
        setMueve(true);
      }
      console.log("ENTRO 3");
    } catch (error) {
      setLoadignDoc(false);
      if (error?.Message !== undefined && error?.Message !== null) {
        Alert({
          icon: "error",
          okbtntext: "Aceptar",
          text: `${error.Message}`,
        });
      }
    } finally {
      setLoadignDoc(false);
    }
  };

  const enviarDatos = async () => {
    setLoadignDoc(true);
    console.log("bodyRequestCoordinates 1", coordinates);
    try {
      coordinates.forEach((element, index) => {
        if (element.NumberPage === null || element.NumberPage === 0) {
          coordinates.splice(index, 1);
        }
      });
      const Body = {
        Document_Id: templateData.item?.DocumentTemplate_Id,
        TotalPage: imagesDocument?.Properties.length,
        Traces: coordinates,
      };
      const templatesServices = TemplatesServices.getInstance();
      await templatesServices.AddTraceSignDocumentTemplate({ Body });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadignDoc(false);
    }
  };

  const sendDataCoordinates = async () => {
    try {
      setLoadignDoc(true);
      console.log("bodyRequestCoordinates 2", coordinates);
      coordinates.forEach((element, index) => {
        if (element.NumberPage === null || element.NumberPage === 0) {
          coordinates.splice(index, 1);
        }
      });
      const Body = {
        Document_Id: templateData.item?.DocumentTemplate_Id,
        TotalPage: imagesDocument?.Properties.length,
        Traces: coordinates,
      };
      console.log("bodyRequestCoordinates 3", Body);
      const templatesServices = TemplatesServices.getInstance();
      const response = await templatesServices.AddTraceSignDocumentTemplate({
        Body,
      });
      console.log("responseEnvio", response);
      Alert({
        icon: "success",
        text: "Se registró la plantilla correctamente.",
        okbtntext: "Aceptar",
      });
      navigate("/inbox/TemplateManager");
    } catch (error) {
      console.log("errorerror", error);
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: `Ocurrió un error. Consulta soporte con tu proveedor.`,
      });
    } finally {
      setLoadignDoc(false);
    }
  };

  const activeUser = (item) => {
    return participantSelected?.Role === item?.Role;
  };

  const redirectWindow = () => {
    if (
      templateData?.item?.TypeProcess === "DOCX con campos" ||
      templateData?.item.TypeProcess === "PDF editable"
    ) {
      props.setBackItem(true);
      props.handleBackStep();
    } else {
      props.handleBackDouble();
    }
  };

  return (
    <>
      <Grid container>
        <Grid container lg={3.5} sm={12} md={12} xs={12}>
          <Box>
            <Grid lg={12} md={8} dm={12} xs={12} sx={{ mt: 1 }}>
              <ButtonDocuments templateData={templateData} />
            </Grid>
            <Grid lg={12} md={8} xs={12}>
              <ButtonParticipant
                Title={"Firmantes"}
                setUsersSigners={setUsersSigners}
                usersSigners={usersSigners}
                activeUser={activeUser}
                ParticipantList={usersSigners}
                setParticipant={setParticipantSelected}
              />
            </Grid>
            <Grid lg={12} md={8} xs={12}  sx={{  width: "83%", mt: 2 }}>
              <AlertMUI
                iconMapping={{
                  warning: (
                    <Icon
                      icon="mdi:information-variant-circle"
                      fontSize={30}
                      style={{ color: "#FBBF28" }}
                    />
                  ),
                }}
                severity="warning"
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.warningAlert",
                  ".MuiAlert-icon": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                Puedes agregar más firmantes, aún sin tener el rol de captura,
                es decir solo tendrán la acción de firmar.
              </AlertMUI>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={8} sm={12} md={12} xs={12} sx={{ ml: 2 }}>
          <Box textAlign={"justify"} sx={{ p: 1 }}>
            <Typography>
              Para plasmar una firma, selecciona un documento, una vez que estés
              en él, selecciona el firmante que deseas plasmar, da clic sobre
              cualquier zona del documento para agregar su firma y aparecerá un
              recuadro de color con el nombre del firmante, arrástralo en la
              zona del documento donde desees visualizar su firma.
            </Typography>
          </Box>
          {imagesDocument?.Properties ? (
            <Arrastrable
              templateData={templateData}
              enviarDatos={enviarDatos}
              setCoordinates={setCoordinates}
              coordinates={coordinates}
              documentoSeleccionado={documentoSeleccionado}
              usersSigners={usersSigners}
              mueve={mueve}
              setMueve={setMueve}
              imagesDocument={imagesDocument}
              loadignDoc={loadignDoc}
              ParticipantSelected={participantSelected}
              setParticipantSelected={setParticipantSelected}
              setActiveSendData={setActiveSendData}
            />
          ) : (
            <Spinner />
          )}
        </Grid>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
          {props.redEdition === true ? null : (
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                color="secondary"
                variant="outlined"
                sx={{ height: 45, px: 10 }}
                onClick={() => redirectWindow()}
              >
                Atras
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <LoadingButton
              fullWidth
              loading={loadingNext}
              variant="contained"
              sx={{ height: 45, px: 0 }}
              onClick={() => {
                sendDataCoordinates();
              }}
            >
              Enviar
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <HelpPages
        Body={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <video
              style={{ width: "75%" }}
              className="HelpVideo"
              src={HelpVideoEdicion}
              autoPlay
              loop
              muted
            />
          </Box>
        }
      />

      <CustomModalAlert
        OnClose={() => {
          setModalAlert(false);
        }}
        Open={modalAlert}
        Title="¿Deseas omitir este paso?"
        SubTitle='Al omitir este paso, las firmas no podrán incluirse en el cuerpo de los documentos en las secciones específicas, ya que no fue configurada una zona dentro del documento, únicamente se visualizarán en la hoja adicional "Firma Electrónica de documentos"'
        LeftButtonText="Omitir paso"
        LeftButtonAction={() => {
          nextStage(true);
        }}
        RightButtonText="Configurar zona"
        RightButtonAction={() => setModalAlert(false)}
      />
    </>
  );
}

const Arrastrable = (props) => {
  const [imageSeleted, setImageSeleted] = useState([]);
  const [coordenadas, setCoordenadas] = useState(null);
  const [coordenadasUser, setCoordenadasUser] = useState(null);
  const [participants, setParticipants] = useState([]);
  const valRandom = GenerateRandom();

  const generateUniqueId = () => {
    const newUniqueId = Math.floor(valRandom * 1000000);
    return newUniqueId.toString();
  };

  const newParticipant = () => {
    if (props.ParticipantSelected) {
      props.setMueve(false);
      let newArrayParticipant = {
        TipoFirma: "",
        TempId: generateUniqueId(),
        Role: props.ParticipantSelected.Role,
        PointX: 0,
        PointY: 0,
        Color: props.ParticipantSelected.Color,
        Width: 0,
        Height: 0,
      };
      const newParticipants = [...participants, newArrayParticipant];
      setParticipants(newParticipants);
      props.setParticipantSelected(null);
      setTimeout(() => {
        props.setMueve(true);
      }, 80);
    }
  };

  useEffect(() => {
    console.log(
      "++++++++++++++CoordenadasPrevias 0++++++++++++++++++",
      props.coordinates,
      imageSeleted?.NumberPage
    );
    props.coordinates.forEach((element) => {
      if (element?.NumberPage === imageSeleted?.NumberPage) {
        setParticipants(element.Points);
      }
    });
  }, [imageSeleted]);

  function nextImage(final) {
    let pagina = imageSeleted.NumberPage;
    props.setMueve(false);
    if (
      props?.imagesDocument?.Properties[
        props?.imagesDocument?.Properties.length - 1
      ].NumberPage === pagina
    ) {
    } else if (final === true) {
      setImageSeleted(
        props?.imagesDocument?.Properties[
          props?.imagesDocument?.Properties.length - 1
        ]
      );
      props.setParticipantSelected(null);
      setParticipants([]);
    } else {
      setImageSeleted(props?.imagesDocument?.Properties[pagina]);
      props.setParticipantSelected(null);
      setParticipants([]);
    }
    setCoordenadas(null);
  }

  function prevImage(inicio) {
    let pagina = imageSeleted.NumberPage;
    props.setMueve(false);
    if (pagina === 1) {
    } else if (inicio === true) {
      setImageSeleted(props?.imagesDocument?.Properties[0]);
      props.setParticipantSelected(null);
      setParticipants([]);
    } else {
      setImageSeleted(props?.imagesDocument?.Properties[pagina - 2]);
      props.setParticipantSelected(null);
      setParticipants([]);
    }
    setCoordenadas(null);
  }

  useEffect(() => {
    if (props.imagesDocument) {
      setImageSeleted(props.imagesDocument.Properties[0]);
    }
  }, []);

  useEffect(() => {
    if (
      coordenadasUser !== undefined &&
      coordenadasUser !== null &&
      coordenadasUser !== ""
    ) {
      let replaceId = coordenadasUser.TempId;
      let indexPoints = props.coordinates.find(
        (element) => element?.NumberPage === imageSeleted?.NumberPage
      );
      let newData = [];

      if (indexPoints && indexPoints.Points && indexPoints.Points.length > 0) {
        newData = [...indexPoints.Points]; // Copiamos las coordenadas existentes en newData
        setCoordenadas(indexPoints?.Points);
      }

      let index = newData?.findIndex((element) => element.TempId === replaceId);

      if (index > -1) {
        // Reemplazar coordenadas existentes
        let newCoordenadas = newData.map((element) => {
          if (element.TempId === replaceId) {
            return { ...element, ...coordenadasUser };
          } else {
            return element;
          }
        });
        newData = newCoordenadas;
      } else if (
        coordenadasUser !== undefined &&
        coordenadasUser !== null &&
        coordenadasUser !== ""
      ) {
        newData.push(coordenadasUser);
      }
      setCoordenadas(newData);
    }
  }, [coordenadasUser]);

  useEffect(() => {
    console.log("hasNonEmptyObject", coordenadas?.length);
    if (coordenadas?.length > 0) {
      console.log("BORRANDOTODO 0");
      let replaceId = imageSeleted?.NumberPage;
      let arrayNew = {
        NumberPage: imageSeleted ? imageSeleted?.NumberPage : null,
        Points: coordenadas,
      };
      let index = props.coordinates?.findIndex(
        (element) => element?.NumberPage === replaceId
      );

      console.log("props.coordinates", props.coordinates, coordenadas);

      console.log("index 1", index);
      if (index > -1) {
        let newCoordenadasForPage = props.coordinates.map((element) => {
          if (element.NumberPage === replaceId) {
            return { ...element, ...arrayNew };
          } else {
            return element;
          }
        });
        console.log("BORRANDOTODO 0.0", newCoordenadasForPage);
        props.setCoordinates(newCoordenadasForPage);
      } else if (
        arrayNew.NumberPage !== null &&
        arrayNew.NumberPage !== undefined &&
        arrayNew.NumberPage !== ""
      ) {
        props.coordinates.push(arrayNew);
      }
    }
  }, [coordenadas]);

  const eliminarDato = (item) => {
    props.setMueve(false);
    console.log("filterEliminatePaticipants 0", coordenadas);
    let idPage = imageSeleted?.NumberPage;
    let tempId = item.TempId;

    let UpdateArrayCoords = props.coordinates?.map((itemCoord) =>
      itemCoord.NumberPage === idPage
        ? {
            ...itemCoord,
            Points: itemCoord?.Points?.filter(
              (itemCoord) => itemCoord.TempId !== tempId
            ),
          }
        : itemCoord
    );

    const objetoEncontrado = UpdateArrayCoords.find(
      (objeto) => objeto.NumberPage === idPage
    );

    let filterEliminateCoordenadas = coordenadas?.filter(
      (itemFilter) => itemFilter.TempId !== item.TempId
    );

    setCoordenadas(filterEliminateCoordenadas);
    props.setCoordinates(UpdateArrayCoords);
    setParticipants(objetoEncontrado.Points);
    props.setActiveSendData(true);
    setTimeout(() => {
      props.setMueve(true);
    }, 80);
  };

  return (
    <Paper elevation={2}>
      <BackdropComponent loading={props.loadignDoc} />
      <Box
        sx={{
          display: "flex",
          position: "sticky",
          zIndex: 1,
          width: imageSeleted?.PointWidth,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 500, ml: 2 }}>
            {props.templateData.item.Name}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box>
            <IconButton
              disabled={imageSeleted?.NumberPage === 1}
              sx={{ p: 0 }}
              onClick={() => prevImage(true)}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              disabled={imageSeleted?.NumberPage === 1}
              sx={{ p: 0 }}
              onClick={() => prevImage()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Box>
          <Box sx={{ fontWeight: 550 }}>{imageSeleted?.NumberPage}</Box>
          <Box>
            <IconButton
              disabled={
                imageSeleted?.NumberPage ===
                props?.imagesDocument?.Properties.length
              }
              sx={{ p: 0 }}
              onClick={() => nextImage()}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              disabled={
                imageSeleted?.NumberPage ===
                props?.imagesDocument?.Properties.length
              }
              sx={{ p: 0 }}
              onClick={() => nextImage(true)}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex" }}>
            de{" "}
            <Typography sx={{ fontWeight: 550, ml: 0.5 }}>
              {props?.imagesDocument?.Properties?.length}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <div
          style={{
            height: "800px",
            width: "auto",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
          onClick={() => newParticipant()}
        >
          <div style={{ height: "auto", width: "auto", padding: "10px" }}>
            {participants?.map((use, index) => {
              return (
                <Participantes
                  key={index + 1}
                  eliminarDato={eliminarDato}
                  item={use}
                  NameUser={use.Role}
                  index={index}
                  setMueve={props.setMueve}
                  mueve={props.mueve}
                  coordenada={use}
                  setCoordenadasUser={setCoordenadasUser}
                />
              );
            })}
            <Box>
              <Box sx={{ mt: 1 }}>
                {imageSeleted ? (
                  <Paper elevation={4} sx={{ width: imageSeleted?.PointWidth }}>
                    <img
                      src={`data:image/jpeg;base64,${imageSeleted.Content}`}
                      alt="imagen de contrato"
                      style={{
                        width: imageSeleted?.PointWidth,
                        height: imageSeleted?.PointHeight,
                        marginTop: "60px",
                        border: `1.5px solid #ECECEC`,
                      }}
                    />
                  </Paper>
                ) : (
                  <Spinner />
                )}
              </Box>
            </Box>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

const Participantes = ({
  item,
  NameUser,
  setCoordenadasUser,
  setMueve,
  mueve,
  coordenada,
  index,
  eliminarDato,
}) => {
  const [newCoordenada, setNewCoordenada] = useState({});

  const onDrag = (e, data) => {
    if (data.lastY - 72 <= 0) {
      return;
    }
    setMueve(true);
    setCoordenadasUser({
      TempId: item?.TempId,
      PointX: data.lastX,
      Role: item.Role,
      PointY: data.lastY - 72,
      Color: item.Color,
      TipoFirma: "",
      Width: item.Width,
      Height: item.Height,
    });
  };

  useEffect(() => {
    setNewCoordenada({
      PointX: coordenada.PointX,
      PointY: coordenada.PointY,
    });
  }, [coordenada, index]);

  return (
    <Draggable
      onDrag={onDrag}
      bounds="parent"
      zIndex={index + 1}
      position={
        mueve === true
          ? false
          : {
              x: newCoordenada.PointX === 0 ? 0 : newCoordenada.PointX,
              y: newCoordenada.PointY === 0 ? 0 : newCoordenada.PointY + 72,
            }
      }
    >
      <div
        style={{
          cursor: "move",
          float: "left",
          padding: 2,
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: index + 1,
        }}
      >
        <div
          style={{
            width: 120,
            display: "flex",
            justifyContent: "center",
          }}
          className={"draggable"}
        >
          <div
            style={{
              height: 55,
              width: 120,
              background: `${item.Color}`,
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 3,
                  marginTop: ".2em",
                }}
              >
                <UseImage
                  type="Image"
                  width={22}
                  height={22}
                  src={"FirmaGeneral.png"}
                />
              </Box>
              <Box>
                <IconButton size="small" onClick={() => eliminarDato(item)}>
                  <spam style={{ fontSize: 14, fontWeight: 600 }}>x</spam>
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: 118, whiteSpace: "nowrap" }}>
                <Box
                  component="div"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "8px!important",
                      fontWeight: 500,
                      color: "white",
                    }}
                  >
                    {NameUser}
                  </Typography>
                </Box>
              </div>
            </Box>
            <Box
              component="div"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "8px!important",
                  fontWeight: 450,
                }}
              >
                Fecha y hora
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
const ButtonDocuments = ({ templateData }) => {
  return (
    <Paper elevation={2}  style={{ width: "83%", mt: 2 }}>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Documentos
        </Typography>
      </Box>
      <Box sx={{ width: "90%", p: 1, maxHeight: "90%", overflow: "auto" }}>
        <Box>
          <WhiteTooltip placement="top" title={templateData.item.Name}>
            <ButtonDocLIst
              sx={{
                color: "#C20E30",
                fontWeight: 600,
                borderColor: "#C20E30",
                background: "#C20E3026",
              }}
              key={templateData.item.DocumentTemplate_Id}
              variant="outlined"
              id={templateData.item.DocumentTemplate_Id}
            >
              <Typography sx={{ mb: 0.5 }}>{templateData.item.Name}</Typography>
            </ButtonDocLIst>
          </WhiteTooltip>
        </Box>
      </Box>
    </Paper>
  );
};

const ButtonParticipant = ({
  ParticipantList,
  Title,
  usersSigners,
  setUsersSigners,
  setParticipant,
  activeUser,
}) => {
  return (
    <Paper elevation={2} sx={{ width: "83%", mt: 2 }}>
      <Box sx={{ display: "flex", p: 1 }}>
        <Box sx={{ p: 0.5 }}>
          <AddNewRole
            usersSigners={usersSigners}
            setUsersSigners={setUsersSigners}
          />
        </Box>
        <Box sx={{ flexGrow: 1, p: 0.5 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {Title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "93%", p: 1, maxHeight: "290px", overflow: "auto" }}>
        {ParticipantList?.map((item, index) => (
          <Box
            key={index + 1}
            border={activeUser(item) === true ? 1.9 : null}
            borderColor={activeUser(item) === true ? "#494949" : null}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: 0.8,
              mt: 1,
              borderRadius: "13px",
            }}
            onClick={() => {
              setParticipant(item);
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Avatar sx={{ background: item.Color }} />
            </Box>
            <Box sx={{ ml: 1, width: 200 }}>
              <Typography>{item.Role}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const AddNewRole = (props) => {
  const { control, register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
  });
  const valRandom = GenerateRandom();
  const [openDialog, setOpenDialog] = useState(false);
  const generateUniqueId = () => {
    const newUniqueId = Math.floor(valRandom * 1);
    return newUniqueId.toString();
  };

  const addNewRolle = (data) => {
    props.setUsersSigners([
      ...props.usersSigners,
      {
        Role: data.NewSigner.toUpperCase(),
        Color: Color[generateUniqueId()[1]].Color,
      },
    ]);

    setOpenDialog(!openDialog);
    setValue("NewSigner", "");
  };

  return (
    <>
      <Box>
        <Icon
          icon="gg:add"
          fontSize={28}
          color={"#C20E30"}
          onClick={() => setOpenDialog(!openDialog)}
        />
      </Box>
      <Dialog fullWidth={true} maxWidth={"sm"} open={openDialog}>
        <Box sx={{ p: 2 }}>
          <Box sx={{ p: 1 }}>
            <Typography variant="h6">Rol del nuevo firmante</Typography>
          </Box>
          <CustomInputField
            inputType="text"
            control={control}
            register={register}
            name={"NewSigner"}
            label={""}
            validations={{ required: true }}
          />
          <Box
            sx={{
              p: 1,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ m: 0.5 }}>
              <Button
                sx={{ height: 40, minWidth: 210 }}
                variant="outlined"
                onClick={() => {
                  setOpenDialog(!openDialog);
                  setValue("NewSigner", "");
                }}
                color="primary"
              >
                Cancelar
              </Button>
            </Box>
            <Box sx={{ m: 0.5 }}>
              <Button
                sx={{ height: 40, minWidth: 210 }}
                variant="contained"
                onClick={handleSubmit(addNewRolle)}
                color="primary"
              >
                Agregar firmante
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};
