import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  Typography,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { useState } from "react";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { Close } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { FileService } from "../../services/FileService";
import BackdropComponent from "../../components/BackDrop";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: `1px solid ${theme.palette.table.secondary} !important`,
    color: theme.palette.primary.text,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return wordUrl;
};

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  let atob = require("atob");
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const DocumentListTablePreview = () => {
  const [docSelected, setdocSelected] = useState(null);
  const [docSelectedComplete, setDocSelectedComplete] = useState();
  const [loading, setLoading] = useState(false);

  const documentList = useSelector((state) => state.File?.item?.Documents);

  const seletedDocument = async (item) => {
    console.log('ITEMITEM', item)
    setDocSelectedComplete(item)
    setLoading(true);
    const fileService = FileService.getInstance();
    const comentData = await fileService.getDownloadDocument({
      document_Id: item.DocumentData.Id,
      ConvertSfdt: false,
    });

    let extension =
      comentData.Body.FileName.split(".").length > 1
        ? comentData.Body.FileName.split(".")[1]
        : "pdf";
    if (extension === "docx") {
      const docConvertido = convertWordB64ToPdfB64(
        comentData?.Body?.B64Content
      );
      docConvertido.then((value) => {
        setdocSelected(value);
      });
      setLoading(false);
    } else {
      const blob = B64toBlob(comentData?.Body?.B64Content, "application/pdf");
      setdocSelected(URL.createObjectURL(blob));
      setLoading(false);
    }
  };

  return (
    <>
      <BackdropComponent loading={loading} />
      <TableContainer sx={{ textAling: "center" }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nombre del documento</StyledTableCell>
              <StyledTableCell align="center">
                Proceso del documento
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentList?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      icon="system-uicons:document-stack"
                      style={{
                        color: "#C20E30",
                        fontSize: 26,
                        marginRight: 5,
                      }}
                    />
                    {row?.FileName}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.Check === true
                    ? "Edición y firmado"
                    : "Lectura y firmado"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() => seletedDocument(row)}>
                    <RemoveRedEyeOutlinedIcon
                      sx={{ fontSize: 30, color: "black" }}
                    />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {docSelected && (
        <Dialog
          open={!!docSelected}
          onClose={() => setdocSelected(null)}
          fullWidth
          maxWidth="lg"
        >
          <Box sx={{ p: 2, position: "relative" }}>
            <Box>
              <Typography variant="subtitle">
              {docSelectedComplete?.FileName}
              </Typography>
            </Box>
            <Box >
              <IconButton
                size="large"
                sx={{ position: "absolute", right: 8, top: 2 }}
                onClick={() => setdocSelected(null)}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
            <VisorPDF pdfUrl={docSelected} pdfName={""} />
          </Box>
        </Dialog>
      )}
    </>
  );
};
