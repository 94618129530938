export const stripeConstants = {
    GET_STRIPE_KEY_REQUEST: "GET_STRIPE_KEY_REQUEST",
    GET_STRIPE_KEY_SUCCESS: "GET_STRIPE_KEY_SUCCESS",
    GET_STRIPE_KEY_FAILURE: "GET_STRIPE_KEY_FAILURE",
    RESET_STRIPE_KEY: "RESET_STRIPE_KEY",

    REGISTER_PROJECT_CLIENT_PLAN_REQUEST: "REGISTER_PROJECT_CLIENT_PLAN_REQUEST",
    REGISTER_PROJECT_CLIENT_PLAN_SUCCESS: "REGISTER_PROJECT_CLIENT_PLAN_SUCCESS",
    REGISTER_PROJECT_CLIENT_PLAN_FAILURE: "REGISTER_PROJECT_CLIENT_PLAN_FAILURE",
    RESET_PROJECT_CLIENT_PLAN: "RESET_PROJECT_CLIENT_PLAN",

};