import { Box, Typography, Alert as AlertMUI, } from "@mui/material";
import React, { useEffect } from "react";
import Localizacion from "../../../../assets/icons/LocationIcon.gif";
import SinGeolocalizacion from "../../../../assets/SinGeolocalizacion.gif";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../../components/InputField";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { FileFlowService } from "../../../../services/FileFlowService";
import { Alert } from "../../../../components/Alert";
import { getFile } from "../../../../store/actions";
import { makeStyles } from "@mui/styles";
import { CustomInputField } from "../../../../components/CustomInputField";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  paperResponsive: {
    display: "flex",
    padding: 7,
    flexWrap: "wrap",
    borderLeft: `1.8px solid ${theme.palette.secondary.main60}`,
    alignContent: "space-between",
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      borderLeft: `0px solid ${theme.palette.secondary.main60}`,
      padding: 7,
      minHeight: "auto",
    },
  },
  botonReponsive: {
    minWidth: '70%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
}));

export default function DocumentosFirmar({ isActiveMassiveSignature }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activeAllChecks = true;
  const { control, handleSubmit, watch, setValue } = useForm();
  const [loadingSignature, setloadingSignature] = useState(false);
  const stageFile = useSelector((state) => state.File?.item);
  const [checkStates, setCheckStates] = useState(stageFile?.Documents);
  const checkAlls = watch('CheckAll')

  const onError = (errors, e) => {
    if (errors) {
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: `Es necesario seleccionar todos los documentos para continuar con el proceso`,
      });
    }
  };

  const handleSignature = async () => {
    try {
      const fileFlowService = FileFlowService.getInstance();
      const response = await fileFlowService.registerStatusStage({
        File_id: stageFile.FileData.Id,
        Status: "Firma Documentos",
        Result: null,
        IsMasive: isActiveMassiveSignature,
      });
      if (response.Body) {
        dispatch(getFile({ FileId: stageFile.FileData.Id }));
      } else {
        Alert({
          icon: "error",
          text: "Ha ocurrido un problema, intentalo mas tarde",
          okbtntext: "Aceptar",
        });
      }
    } catch (error) {
      Alert({
        icon: "error",
        text: "Ha ocurrido un problema, intentalo mas tarde",
        okbtntext: "Aceptar",
      });
    }
  };

  useEffect(() => {
    SelectedAllChecks()
  }, [checkAlls])

  const SelectedAllChecks = () => {
    console.log('stageFile.Documents', stageFile)
    if (checkAlls === true) {
      console.log('AQUI 0')
      stageFile.Documents.map((data) =>
        data.Sign === true ? setValue(data.FileName.replace(/\./g, ''), true) : null
      );
    } else {
      console.log('AQUI 1')
      stageFile.Documents.map((data) =>
        data.Sign === true ? setValue(data.FileName.replace(/\./g, ''), false) : null
      );
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box className={classes.paperResponsive}>
        <Box sx={{ mt: 1, ml: 2 }}>
          <Typography variant="titulo">
            Consentimiento de firma
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ height: '20vh' }} alt="Gif de Localizacion" src={stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined ? Localizacion : SinGeolocalizacion} />
          </Box>
          <Box textAlign={'left'} sx={{ mt: 2 }}>
            <Typography variant="subtitle">
              {stageFile?.CheckGPS === true || stageFile?.CheckGPS == null || stageFile?.CheckGPS == undefined  ?
                "Estamos recolectando tu geolocalización para obtener tu ubicación en tiempo real" :
                "Detectamos que desactivaste la geolocalización, por lo tanto, no se está recolectando en el proceso."}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Documentos a firmar</Typography>
            <Box sx={{ mt: 0.5 }}>
              <AlertMUI
                iconMapping={{
                  warning: (
                    <Icon
                      icon="mdi:information-variant-circle"
                      fontSize={30}
                      style={{ color: "#FBBF28" }}
                    />
                  ),
                }}
                severity="warning"
                sx={{
                  fontSize: '15px!important',
                  mt: 1,
                  fontWeight: 500,
                  backgroundColor: "secondary.warningAlert",
                  ".MuiAlert-icon": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                Para continuar el proceso, es necesario que selecciones los siguientes documentos:
              </AlertMUI>
            </Box>
            <Box sx={{ maxHeight: 400, pt: 2, pl: .3 }} overflow={"auto"}>
              {activeAllChecks === true ?
                <InputField
                  name={"CheckAll"}
                  label={`<span style="fontWeight: 550"}}>Seleccionar todo</span> `}
                  control={control}
                  inputType="check"
                  fullWidth
                  sx={{ borderRadius: "12px" }}
                />
                : null}
              {checkStates?.map((doc, index) =>
                doc.Sign === true ? (
                  <Box key={index + 1}>
                    <CustomInputField
                      name={doc.FileName.replace(/\./g, '')}
                      label={
                        ` <div style="display:"flex""><span style="fontWeight: 550"}}>Documento - ${doc?.FileName} </span> <span style="color: #08905C"}}> <i>${doc?.NumberSigns}</i></span></div> `}
                      control={control}
                      inputType="check"
                      fullWidth
                      sx={{ borderRadius: "12px" }}
                      validations={{ required: true }}
                      defaultValue={false}
                    />
                  </Box>
                ) : null
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box className={classes.botonReponsive}>
            <Box sx={{ p: 1 }}>
              <LoadingButton
                fullWidth
                sx={{ height: 45 }}
                variant="contained"
                size="medium"
                onClick={handleSubmit(handleSignature, onError)}
                loading={loadingSignature}
              >
                <Typography sx={{ letterSpacing: 1.2 }}>Aceptar</Typography>
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
