import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';


const useStyles = makeStyles((theme) => ({
    inputButton: {
        borderRadius: "50%",
        border: '2px solid #898B8D',
        background: "#F4F4F4",
        fontSize: "33px",
        height: 55,
        width: 55,
        padding: "0px 15px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 33,
            height: 47,
            width: 47,
            borderRadius: "50%",
            padding: "0px 12px",
        },
    },
}));

export default function MaskCodigo(props) {
    const classes = useStyles();
    const [valores, setValores] = useState()
    const red = useRef(null);
    const red2 = useRef(null);
    const red3 = useRef(null);
    const red4 = useRef(null);
    const red5 = useRef(null);

    function isNumeric(evt) {
        let theEvent = evt || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    };

    const onSubmit = async (valores) => {
        let objeto = [];
        for (let clave in valores) {
            objeto.push(valores[clave])
        };
        props.funcionClick(objeto.join(''))
    };

    useEffect(() => {
        if (props.valuePrevious) {
            setValores({
                value1: props.valuePrevious[0],
                value2: props.valuePrevious[1],
                value3: props.valuePrevious[2],
                value4: props.valuePrevious[3],
                value5: props.valuePrevious[4]
            })
        }
    }, [props.valuePrevious])

    return (
        <>
            <Box>
                <Typography variant='caption1' sx={{ ml: 4 }}>
                    Contraseña de un solo uso:
                </Typography>
            </Box>
            <Box sx={{ display: "flex", p: 2, alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ p: .5, ml: 1 }}>
                    <input
                        className={classes.inputButton}
                        autocomplete="off"
                        id={'value1'}
                        value={valores ? valores.value1 : ''}
                        onChange={(e) => {
                            setValores({ ...valores, 'value1': e.target.value })
                            if (e.target.value !== '') {
                                red2.current.focus()
                            } else {
                            }
                        }}
                        ref={red}
                        name="value1"
                        inputMode="numeric"
                        maxLength={1}
                        onKeyPress={(event) => {
                            isNumeric(event)
                        }}
                    />
                </Box>
                <Box sx={{ p: .5, ml: 1 }}>
                    <input
                        className={classes.inputButton}
                        autocomplete="off"
                        id={'value2'}
                        value={valores ? valores.value2 : ''}
                        onChange={(e) => {
                            setValores({ ...valores, 'value2': e.target.value })
                            if (e.target.value !== '') {
                                red3.current.focus()
                            } else {
                                red.current.focus()
                            }
                        }}
                        ref={red2}
                        name="value2"
                        inputMode="numeric"
                        maxLength={1}
                        onKeyPress={(event) => {
                            isNumeric(event)
                        }}
                    />
                </Box>
                <Box sx={{ p: .5, ml: 1 }}>
                    <input
                        className={classes.inputButton}
                        autocomplete="off"
                        id={'value3'}
                        value={valores ? valores.value3 : ''}
                        onChange={(e) => {
                            setValores({ ...valores, 'value3': e.target.value })
                            if (e.target.value !== '') {
                                red4.current.focus()
                            } else {
                                red2.current.focus()
                            }
                        }}
                        ref={red3}
                        name="value3"
                        inputMode="numeric"
                        maxLength={1}
                        onKeyPress={(event) => {
                            isNumeric(event)
                        }}
                    />
                </Box>
                <Box sx={{ p: .5, ml: 1 }}>
                    <input
                        className={classes.inputButton}
                        autocomplete="off"
                        id={'value4'}
                        value={valores ? valores.value4 : ''}
                        onChange={(e) => {
                            setValores({ ...valores, 'value4': e.target.value })
                            if (e.target.value !== '') {
                                red5.current.focus()
                            } else {
                                red3.current.focus()
                            }
                        }}
                        ref={red4}
                        name="value4"
                        inputMode="numeric"
                        maxLength={1}
                        onKeyPress={(event) => {
                            isNumeric(event)
                        }}
                    />
                </Box>
                <Box sx={{ p: .5, ml: 1 }}>
                    <input
                        className={classes.inputButton}
                        autocomplete="off"
                        id={'value5'}
                        value={valores ? valores.value5 : ''}
                        onChange={(e) => {
                            setValores({ ...valores, 'value5': e.target.value })
                            if (e.target.value !== '') {
                            } else {
                                red4.current.focus()
                            }
                        }}
                        ref={red5}
                        name="value5"
                        inputMode="numeric"
                        maxLength={1}
                        onKeyPress={(event) => {
                            isNumeric(event)
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <LoadingButton
                    loading={props.reloadSing ? props.reloadSing : null}
                    fullWidth
                    sx={{ height: 40, width: '88%' }} 
                    variant="contained"
                    size="medium"
                    onClick={() => onSubmit(valores)}
                >
                    <Typography sx={{ letterSpacing: 1.2 }}>Confirmar</Typography>
                </LoadingButton>
            </Box>
        </>
    )
};
