import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
} from "@mui/material";
import { List } from "react-movable";
import { Icon } from "@iconify/react";
import { InputField } from "../../components/InputField";
import { useForm } from "react-hook-form";
import { FileService } from "../../services/FileService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setlistCheckers,
  setlistSigners,
  ReorderParticipant,
} from "../../store/actions";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import iconoMenu from "../../assets/icons/Dragable.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { UTCtoLocal } from "../../utils";
import { ToastNotification } from "../../components/toastNotification";
import IconToolTip from "../../components/IconToolTip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  pointerEvents: "none",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.TableHeader,
    color: theme.palette.primary.mainLyrics,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.lightContrast,
  },
  "&:last-child td, &:last-child`  th": {
    border: 0,
  },
}));

export const ParticipantDraggableTable = (propsComponent) => {
  const [editing, setediting] = useState(null);
  const listSigners = useSelector((state) => state.NewFile.participants.sign);
  const [listTypeUpdateOrder, setListTypeUpdateOrder] = useState("");
  const [userIdOrder, setUserIdOrder] = useState(null);
  const [newOrderCurent, setNewOrderCurent] = useState(null);
  const [loadUpdateOrder, setLoadUpdateOrder] = useState(false);
  const [templateActiveData, setTemplateActiveData] = useState(false);

  const { control, watch, setValue } = useForm();
  const watchDateEdit = watch("dateEdit");

  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  const typoSing = useSelector(
    (state) =>
      state.NewFile?.file?.fileForm?.FileConfiguration?.SignatureOptions
  );
  const typoSingEdit = useSelector(
    (state) => state.File.item?.SignatureOptions
  );

  const reorderPartData = useSelector((state) => state.ReorderParticipantData);
  const dispatch = useDispatch();

  console.log("propsComponent.data", propsComponent.data);

  useEffect(() => {
    if (propsComponent.data.length > 0) {
      const statusTemplate = propsComponent.data.some(
        (objeto) =>
          objeto.Role !== "" ||
          objeto.Role !== null ||
          objeto.Role !== undefined
      );
      console.log("statusTemplate", statusTemplate);
      setTemplateActiveData(statusTemplate);
    }
  }, [propsComponent.data]);

  useEffect(() => {
    // console.log("listTypeUpdateOrder,userIdOrder,newOrderCurent: ", listTypeUpdateOrder, userIdOrder, newOrderCurent)
    if (
      listTypeUpdateOrder !== null &&
      listTypeUpdateOrder !== "" &&
      userIdOrder !== null &&
      userIdOrder !== "" &&
      newOrderCurent !== null &&
      newOrderCurent !== ""
    ) {
      // console.log("newFileState.file.Id: ", newFileState.file.Id)

      let ordType = listTypeUpdateOrder === "checkers" ? "check" : "sign";
      console.log(
        "Entro a la peticion dispatch:listTypeUpdateOrder,userIdOrder,newOrderCurent: ",
        ordType,
        listTypeUpdateOrder,
        userIdOrder,
        newOrderCurent,
        newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id
      );
      const request = {
        File_Id: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        User_Id: userIdOrder,
        OrderType: ordType,
        NewOrder: newOrderCurent,
        CleanDate: false,
      };
      console.log(".:ReorderParticipant:. request: ", request);
      dispatch(ReorderParticipant(request));
      // console.log(".:ReorderParticipant:. despues de la peticion")
      setLoadUpdateOrder(true);
      setUserIdOrder(null);
      setNewOrderCurent(null);
    }
  }, [listTypeUpdateOrder, userIdOrder, newOrderCurent]);

  useEffect(() => {
    if (
      loadUpdateOrder === true &&
      reorderPartData.items !== undefined &&
      reorderPartData.items !== null
    ) {
      setLoadUpdateOrder(false);
      console.log("reorderPartData: ", reorderPartData);
      // propsComponent.setData(newData)
      console.log("listTypeUpdateOrder******: ", listTypeUpdateOrder);
      if (listTypeUpdateOrder === "checkers") {
        let newData = reorderPartData.items
          ?.filter((item) => item.Check !== null)
          ?.map((data) => {
            return {
              Check: data.Check,
              CheckDeadline: data.CheckDeadline,
              CheckOrder: data.CheckOrder,
              FullName: data.FullName,
              UserId: data.UserId,
              UserEmail: data.Email,
            };
          });
        console.log("newData: checkers", newData);
        dispatch(setlistCheckers(newData));
        setListTypeUpdateOrder("");
      } else if (listTypeUpdateOrder === "signers") {
        let newData1 = reorderPartData.items
          ?.filter((item) => item.Sign !== null)
          ?.map((data) => {
            return {
              ApplyNOM151: true,
              Sign: data.Sign,
              FullName: data.FullName,
              SignDeadline: data.SignDeadline,
              SignOrder: data.SignOrder,
              UserEmail: data.Email,
              UserId: data.UserId,
              Role: data.Role,
              SignatureOptions: data.SignatureOptions,
            };
          });
        console.log("newData1: signers", newData1);
        dispatch(setlistSigners(newData1));
        setListTypeUpdateOrder("");
      }
    }
  }, [reorderPartData]);

  const renderDate = (value, type) => {
    if (value.Check && value.CheckDeadline && type === "checkers") {
      return UTCtoLocal(value.CheckDeadline, false);
    }
    if (value.Sign && value.SignDeadline && type === "signers") {
      return UTCtoLocal(value.SignDeadline, false);
    }
    return "-";
  };

  const handleEdit = (value) => {
    if (value?.Check === true && value?.CheckDeadline !== null) {
      const limDate = new Date(value?.CheckDeadline);
      let lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", new Date(lmd2));
    } else if (value?.Sign === true && value?.SignDeadline !== null) {
      const limDate = new Date(value?.SignDeadline);
      let lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", new Date(lmd2));
    }
    setediting(value);
  };

  const cancelEdit = () => {
    setediting(null);
  };

  const updatedate = async (newdate) => {
    if (newdate === undefined || newdate === null || newdate === "") {
      return;
    }
    try {
      const fileService = FileService.getInstance();
      const participant = {
        ...editing,
        ...(editing && {
          SignDeadline: newdate.toISOString(),
        }),
      };

      await fileService.registerParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        Participant: participant,
      });
      if (editing) {
        if (editing.Check) {
          const newCheckList = newFileState.participants.check.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistCheckers(newCheckList));
        }
        if (editing.Sign) {
          const newSignList = newFileState.participants.sign.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistSigners(newSignList));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setediting(null);
    }
  };

  const handleValidateDate = (typeDate, key) => {
    try {
      const listchecks =
        propsComponent.listType === "checkers"
          ? propsComponent.data?.filter((l) => l.CheckDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.CheckDeadline);
      const listsigns =
        propsComponent.listType === "signers"
          ? propsComponent.data?.filter((l) => l.SignDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.SignDeadline);

      const lclength = listchecks.length;
      const lslength = listsigns.length;
      if (typeDate === "checkers") {
        if (lclength === 0) {
          return new Date();
        }

        let lastdatecheck =
          key <= 0
            ? new Date()
            : lclength > 0
            ? listchecks[key - 1]?.CheckDeadline
            : new Date();
        const limDate = new Date(lastdatecheck);
        let dateCalcule = key <= 0 ? limDate.getDate() : limDate.getDate() + 1;
        return limDate.setDate(dateCalcule);
      }
      if (typeDate === "signers") {
        if (lclength === 0) {
          if (lslength === 0) {
            const limDate = new Date(watchDateEdit);
            return limDate.setDate(limDate.getDate() + 1);
          }
          return new Date();
        }
        if (lslength === 0) {
          const lastdatecheck = listchecks[lclength - 1].CheckDeadline;
          const limDate = new Date(lastdatecheck);
          return limDate.setDate(limDate.getDate());
        }

        let lastdatesign =
          key <= 0
            ? lclength > 0
              ? listchecks[lclength - 1].CheckDeadline
              : new Date()
            : lslength > 0
            ? listsigns[key - 1].SignDeadline
            : new Date();
        const limDate = new Date(lastdatesign);
        let dateCalcule = limDate.getDate();
        return limDate.setDate(dateCalcule);
      }
    } catch (ex) {
      console.log(".:handleValidateDate:. Error: ", ex);
    }
  };

  const handleValidateDateMax = (typeDate, key) => {
    try {
      const ValidityDate =
        newFileState.file?.fileForm?.FileConfiguration?.Validity;
      const listsigns =
        propsComponent.listType === "signers"
          ? propsComponent.data?.filter((l) => l.SignDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.SignDeadline);
      const listchecks =
        propsComponent.listType === "checkers"
          ? propsComponent.data?.filter((l) => l.CheckDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.CheckDeadline);

      if (typeDate === "checkers" && listsigns?.length > 0) {
        let lastdatesign =
          key >= 0 && listchecks?.length - 1 > key
            ? listchecks[key + 1].CheckDeadline
            : listsigns[0].SignDeadline;
        const limDate = new Date(lastdatesign);
        return limDate.setDate(limDate.getDate() - 1);
      }

      if (typeDate === "signers" && listsigns?.length > 0) {
        let lastdatesign =
          key >= 0 && listsigns?.length - 1 > key
            ? listsigns[key + 1].SignDeadline
            : new Date(ValidityDate);

        const limDate = new Date(lastdatesign);
        let dateCalculate =
          listsigns?.length - 1 === key ? limDate.getDate() : null;

        return limDate.setDate(dateCalculate);
      }
      const limDate = new Date(ValidityDate);

      return limDate.setDate(limDate.getDate());
    } catch (ex) {
      console.log(".::. Error: ", ex);
    }
  };

  const handleDelete = async (user, list) => {
    const fileService = FileService.getInstance();
    try {
      await fileService.removeParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        UserId: user.UserId,
        Action: "sign",
      });
      ToastNotification({
        icon: "success",
        text: "Participante eliminado",
      });

      if (list === "signers") {
        const newList = listSigners.filter((el) => {
          return el.UserId !== user.UserId;
        });
        dispatch(setlistSigners(newList));
      }
    } catch (err) {
      console.log(err);
      ToastNotification({
        icon: "error",
        text: "Inténtalo de nuevo",
      });
    }
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <List
        values={propsComponent.data}
        onChange={({ oldIndex, newIndex }) => {
          let varUserId = propsComponent.data[oldIndex]?.UserId;
          setUserIdOrder(varUserId);
          setNewOrderCurent(newIndex + 1);
          setListTypeUpdateOrder(propsComponent.listType);
        }}
        renderList={({ children, props }) => (
          <Table size="small" aria-label="a dense table">
            <TableHead sx={{ color: "primary.main80" }}>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                {propsComponent.listType === "checkers" ? (
                  <StyledTableCell align="center"></StyledTableCell>
                ) : (
                  <StyledTableCell align="center">Orden</StyledTableCell>
                )}
                <StyledTableCell>Correo electrónico</StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                  Fecha límite
                </StyledTableCell>
                {templateActiveData === true ? (
                  <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                    Rol
                  </StyledTableCell>
                ) : null}
                <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                  Funciones
                </StyledTableCell>
                <StyledTableCell align="center">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody {...props}>{children}</TableBody>
          </Table>
        )}
        renderItem={({ value, props, isDragged }) => {
          const rowcontent = (
            <>
              {propsComponent.listType === "checkers" ? (
                <>
                  <StyledTableCell
                    sx={{ pointerEvents: "auto" }}
                  ></StyledTableCell>
                  <StyledTableCell
                    sx={{ pointerEvents: "auto" }}
                  ></StyledTableCell>
                </>
              ) : (
                <>
                  <StyledTableCell sx={{ pointerEvents: "auto" }}>
                    <img src={iconoMenu} alt="imagen" />
                    {/* <Menu sx={{ mt: "7px" }} /> */}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    <span>{isNaN(props.key + 1) ? "x" : props.key + 1}</span>
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell>
                <span>{value.UserEmail || value.Email}</span>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ pointerEvents: "auto" }}>
                {value.UserId === editing?.UserId ? (
                  <InputField
                    inputType="date"
                    control={control}
                    name="dateEdit"
                    sxInput={{ maxWidth: 200 }}
                    onSelect={updatedate}
                    minDate={handleValidateDate(
                      propsComponent.listType,
                      props.key
                    )}
                    maxDate={handleValidateDateMax(
                      propsComponent.listType,
                      props.key
                    )}
                  />
                ) : (
                  <span>{renderDate(value, propsComponent.listType)}</span>
                )}
              </StyledTableCell>
              {templateActiveData === true ? (
                <StyledTableCell align="center">{value.Role}</StyledTableCell>
              ) : null}
              <StyledTableCell align="center">
                {value.SignatureOptions ? (
                  value?.SignatureOptions?.SignatureType === "Simple" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {console.log("AQUI 3")}

                      <IconToolTip
                        icon={true}
                        src={"teenyicons:otp-outline"}
                        title={"Contraseña de un solo uso."}
                        width={19}
                      />
                      <IconToolTip
                        src={"SignSimple.png"}
                        title={"Firma Autógrafa en Mensaje de Datos."}
                        width={24}
                      />
                      <IconToolTip
                        src={
                          value?.SignatureOptions?.VideoRecording
                            ? "VideoIcon.svg"
                            : "VideoIconDisabled.svg"
                        }
                        title={"Videograbación de consentimiento."}
                        width={24}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {console.log("AQUI 4")}
                      <IconToolTip
                        icon={true}
                        iconColor={
                          value?.SignatureOptions?.OTP ? "#D65E74" : "#979797"
                        }
                        src={"teenyicons:otp-outline"}
                        title={"Contraseña de un solo uso."}
                        width={19}
                      />
                      <IconToolTip
                        src={"SignAvanzada.png"}
                        title={"Firma Electrónica Avanzada."}
                        width={22}
                      />
                      <IconToolTip
                        src={
                          value?.SignatureOptions?.VideoRecording
                            ? "VideoIcon.svg"
                            : "VideoIconDisabled.svg"
                        }
                        title={"Videograbación de consentimiento."}
                        width={24}
                      />
                    </Box>
                  )
                ) : typoSing?.SignatureType === "Simple" ||
                  typoSingEdit?.SignatureType === "Simple" ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconToolTip
                      icon={true}
                      src={"teenyicons:otp-outline"}
                      title={"Contraseña de un solo uso."}
                      width={19}
                    />
                    <IconToolTip
                      src={"SignSimple.png"}
                      title={"Firma Autógrafa en Mensaje de Datos."}
                      width={24}
                    />
                    <IconToolTip
                      src={
                        typoSingEdit?.VideoRecording || typoSing?.VideoRecording
                          ? "VideoIcon.svg"
                          : "VideoIconDisabled.svg"
                      }
                      title={"Videograbación de consentimiento."}
                      width={24}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {console.log("AQUI 2")}
                    <IconToolTip
                      icon={true}
                      src={"teenyicons:otp-outline"}
                      title={"Contraseña de un solo uso."}
                      width={19}
                      iconColor={
                        typoSingEdit?.OTP || typoSing?.OTP
                          ? "#D65E74"
                          : "#979797"
                      }
                    />
                    <IconToolTip
                      src={"SignAvanzada.png"}
                      title={"Firma Electrónica Avanzada."}
                      width={24}
                    />
                    <IconToolTip
                      src={
                        typoSingEdit?.VideoRecording || typoSing?.VideoRecording
                          ? "VideoIcon.svg"
                          : "VideoIconDisabled.svg"
                      }
                      title={"Videograbación de consentimiento."}
                      width={24}
                    />
                  </Box>
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{ pointerEvents: "auto" }}
                    size="small"
                    color="secondary"
                    onClick={
                      (e) =>
                        value.UserId === editing?.UserId
                          ? cancelEdit()
                          : handleEdit(value)
                      /*  propsComponent.handleEdit(value, propsComponent.listType) */
                    }
                  >
                    {value.UserId === editing?.UserId ? (
                      <ClearIcon />
                    ) : (
                      <ModeEditOutlinedIcon />
                    )}
                  </Button>
                  <Button
                    sx={{ pointerEvents: "auto" }}
                    size="small"
                    color="secondary"
                    onClick={(e) =>
                      handleDelete(value, propsComponent.listType)
                    }
                  >
                    <Icon icon="ph:trash-light" fontSize={19} />
                  </Button>
                </Box>
              </StyledTableCell>
            </>
          );
          if (isDragged) {
            return (
              <Box {...props} sx={{ cursor: "grabbing" }}>
                <Table>
                  <TableBody>
                    <StyledTableRow>{rowcontent}</StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            );
          }
          return (
            <StyledTableRow {...props} sx={{ cursor: "grab" }}>
              {rowcontent}
            </StyledTableRow>
          );
        }}
      />
    </Box>
  );
};
