import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  styled,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import noresult from "../../assets/noresults.svg";
import { getFile } from "../../store/actions";
import BackdropComponent from "../../components/BackDrop";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { FileService } from "../../services/FileService.js";
import { B64toBlob, b64toUrl, downloadFile } from "../../utils/Tools";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import XMLViewer from "react-xml-viewer";

const RootDocumentContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "grid !important",
    gridTemplateColumns: "25% 75%",
    gridAutoRows: "100vh !important",
    overflowY: "hidden !important",
    "&::-webkit-scrollbar": {
      width: "0",
      background: "transparent",
    },
    overflowX: "hidden",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
    // border: '1px solid blue'
  },
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: 200,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: 130,
  },
}));

const ButtonDocLIst = styled(Button)(({ Button }) => ({
  width: "100%",
  color: " black",
  fontSize: "15px",
  fontWeight: 500,
  minHeight: "44px",
  background: "#F7F8FA",
  // border: "2px solid #848484",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "flex-start",
  padding: "0.7em",
  marginTop: "5px",
}));

export const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return wordUrl;
};

export const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

export const FileDocumentTab = () => {
  const dispatch = useDispatch();
  const [blobUrl, setBlobUrl] = useState("");
  const [currentNameDocument, setCurrentNameDocument] = useState("");
  const [urlXml, setUrlXml] = useState(null);
  const [b64Xml, setB64Xml] = useState(null);
  const [documentSelect, setDocumetSelect] = useState(null);
  const [documentInProgress, setDocumentInProgress] = useState(null);
  const [xmlActive, setXmlActive] = useState(null);

  const [documentsList, setDocumentsList] = useState([]);
  const documentIdType = useSelector(
    (state) => state.File?.item?.EnviromentVars.DocumentTypeExpediente
  );

  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.Email
  );

  const userDataRol = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        .Code
  );

  const userAdmin = useSelector(
    (state) => state.File?.item?.FileData?.CreationUser
  );
  const newFileInfo = useSelector((state) => state.NewFile.file);
  const stateFile = useSelector((state) => state.File);
  const listPrerequisitesDocumentList =
    stateFile?.item?.Prerequisites?.ApprovalPrerrequisitesStatusDocument;

  const listDigitalDocument = useSelector((state) =>
    stateFile?.item?.FileData?.Actors[0]?.DocumentalGroups?.find(
      (item) => item.Code === documentIdType?.DocumentalGroup?.Code
    )?.Documents.find((item) => item.DocumentType_Id === documentIdType?.Id)
  );

  const listDocuments = stateFile?.item?.Documents;

  const participantCreation =
    userData === stateFile?.item?.FileData?.CreationUser;

  useEffect(() => {
    if (newFileInfo !== undefined && newFileInfo !== null) {
      dispatch(getFile({ FileId: newFileInfo.Id }));
    }
  }, [newFileInfo]);

  console.log("listDigitalDocument", listDigitalDocument);

  const fileDocuments = async (extension) => {
    const documentsSelect =
      stateFile?.item?.FileData?.Actors[0]?.DocumentalGroups.filter(
        (doc) => doc.Code === "Formato autogenerado"
      );
    const documentacion =
      stateFile?.item?.FileData?.Actors[0]?.DocumentalGroups.filter(
        (doc) => doc.Code === "Documentación"
      );
    if (extension === "xml") {
      let documentXml = documentacion[0].Documents.find(
        (doc) => doc.Extension == ".xml"
      );
      setDocumetSelect(documentXml.Id);
      setDocumentInProgress(documentXml.DocumentTypeName);
      downloadDocument(documentXml.Id, "XML");
      setCurrentNameDocument(documentXml.Name);
    } else if (extension === "NOM") {
      let documentNom = documentsSelect[0].Documents.find(
        (doc) => doc.DocumentTypeName == "Expediente Digital - Nom151"
      );
      setDocumentInProgress(documentNom.DocumentTypeName);
      setDocumetSelect(documentNom.Id);
      downloadDocument(documentNom.Id);
      setCurrentNameDocument(documentNom.Name);
    }
  };

  useEffect(() => {
    if (listPrerequisitesDocumentList?.length > 0) {
      setDocumetSelect(listPrerequisitesDocumentList[0].Document_Id);
      downloadDocument(listPrerequisitesDocumentList[0].Document_Id);
      setCurrentNameDocument(listPrerequisitesDocumentList[0].DocumentName);
    } else if (
      listDigitalDocument !== undefined &&
      listDigitalDocument?.Id !== null
    ) {
      const documentacion =
        stateFile?.item?.FileData?.Actors[0]?.DocumentalGroups.filter(
          (doc) => doc.Code === "Documentación"
        );
      let documentXml = documentacion[0].Documents.find(
        (doc) => doc.Extension == ".xml"
      );

      console.log("documentacion", documentacion);

      setXmlActive(documentXml == undefined);
      setDocumetSelect(listDigitalDocument.Id);
      downloadDocument(listDigitalDocument.Id);
      setCurrentNameDocument(listPrerequisitesDocumentList[0]?.DocumentName);
    } else if (listDocuments.length > 0) {
      setDocumentsList(listDocuments);
      setDocumetSelect(listDocuments[0].DocumentData?.Id);
      downloadDocument(listDocuments[0].DocumentData?.Id);
      setCurrentNameDocument(listDocuments[0]?.DocumentData?.DocumentName);
    }
  }, [listPrerequisitesDocumentList, listDigitalDocument]);

  const downloadDocument = async (idDocument, extension) => {
    const fileService = FileService.getInstance();
    const comentData = await fileService.getDownloadDocument({
      document_Id: idDocument,
      ConvertSfdt: false,
    });

    traerDocumentos(comentData, extension);
  };

  const traerDocumentos = (comentData, extension) => {
    console.log("comentData", comentData);
    if (extension === "XML") {
      setB64Xml(comentData.Body.B64Content);
      let b = Buffer.from(comentData.Body.B64Content, "base64");
      let s = b.toString();
      setUrlXml(s);
      setCurrentNameDocument(comentData.Body.FileName);
      setBlobUrl(null);
    } else {
      if (comentData.Body) {
        let extension =
          comentData.Body.FileName.split(".").length > 1
            ? comentData.Body.FileName.split(".")[1]
            : "pdf";
        if (extension === "docx") {
          const docConvertido = convertWordB64ToPdfB64(
            comentData?.Body?.B64Content
          );
          docConvertido.then((value) => {
            // dispatch(setUrlDocument64(value));
            setBlobUrl(value);
          });
        } else {
          const blob = B64toBlob(
            comentData?.Body?.B64Content,
            "application/pdf"
          );
          setBlobUrl(URL.createObjectURL(blob));
        }
      }
    }
  };

  let handleSelectDocument = async (event) => {
    const fileService = FileService.getInstance();
    const comentData = await fileService.getDownloadDocument({
      document_Id: event.target.id,
      ConvertSfdt: false,
    });
    traerDocumentos(comentData);
    setCurrentNameDocument(event.target.innerText);
  };

  const active = (item) => {
    return documentSelect * 1 === item;
  };

  const activeDoc = (item) => {
    return documentInProgress === item;
  };

  if (
    listPrerequisitesDocumentList.length == 0 &&
    stateFile.item.NotificationOptions.NotifySignedDocuments == false &&
    participantCreation == false
  ) {
    return (
      <Box
        sx={{
          mr: 2,
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Box>
            <Image alt="lupa" src={noresult} />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="titulo">
              No hay documentos que mostrar
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  console.log("xmlActive", xmlActive);

  return (
    <Fragment>
      <BackdropComponent loading={stateFile.loading} />
      <RootDocumentContent>
        <Box sx={{ mr: 2, mt: 2 }}>
          {listPrerequisitesDocumentList?.length > 0 ? (
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Requisitos Previos
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {listPrerequisitesDocumentList?.map((document) => (
                  <ButtonDocLIst
                    sx={(theme) => ({
                      background: active(document.Document_Id)
                        ? theme.palette.primary.main10
                        : "#F7F8FA",
                      border: active(document.Document_Id)
                        ? "2px solid #C20E30"
                        : "2px solid #848484",
                    })}
                    variant="outlined"
                    id={document.Document_Id}
                    onClick={(e) => {
                      setDocumetSelect(e.target.id);
                      handleSelectDocument(e);
                      setDocumentInProgress(null);
                    }}
                  >
                    {document.DocumentName}
                  </ButtonDocLIst>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {stateFile.item.NotificationOptions.NotifySignedDocuments == false &&
          participantCreation == false ? null : (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {listDigitalDocument != undefined
                    ? "Documento Firmado"
                    : "Documentos Solicitud"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {listDigitalDocument != undefined ? (
                  <div>
                    <ButtonDocLIst
                      sx={(theme) => ({
                        background: active(listDigitalDocument.Id)
                          ? theme.palette.primary.main10
                          : "#F7F8FA",
                        border: active(listDigitalDocument.Id)
                          ? "2px solid #C20E30"
                          : "2px solid #848484",
                      })}
                      variant="outlined"
                      id={listDigitalDocument.Id}
                      onClick={(e) => {
                        setDocumetSelect(e.target.id);
                        handleSelectDocument(e);
                        setDocumentInProgress(null);
                      }}
                    >
                      {listDigitalDocument.Name}
                    </ButtonDocLIst>
                    {xmlActive == false ? (
                      <div>
                        <ButtonDocLIst
                          onClick={() => {
                            fileDocuments("xml");
                            active(null);
                          }}
                          sx={(theme) => ({
                            background: activeDoc("Xml Valid Firma-Electrónica")
                              ? theme.palette.primary.main10
                              : "#F7F8FA",
                            border: activeDoc("Xml Valid Firma-Electrónica")
                              ? "2px solid #C20E30"
                              : "2px solid #848484",
                          })}
                        >
                          <Box
                            sx={{
                              mr: 2,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ ml: 1, color: "black" }}
                              variant="body1"
                            >
                              XML
                            </Typography>
                          </Box>
                        </ButtonDocLIst>

                        <ButtonDocLIst
                          onClick={() => {
                            fileDocuments("NOM");
                            active(null);
                          }}
                          sx={(theme) => ({
                            background: activeDoc("Expediente Digital - Nom151")
                              ? theme.palette.primary.main10
                              : "#F7F8FA",
                            border: activeDoc("Expediente Digital - Nom151")
                              ? "2px solid #C20E30"
                              : "2px solid #848484",
                          })}
                        >
                          <Box
                            sx={{
                              mr: 2,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ ml: 1, color: "black" }}
                              variant="body1"
                            >
                              NOM 151
                            </Typography>
                          </Box>
                        </ButtonDocLIst>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {documentsList?.map((item) => (
                      <ButtonDocLIst
                        sx={(theme) => ({
                          background: active(item.DocumentData.Id)
                            ? theme.palette.primary.main10
                            : "#F7F8FA",
                          border: active(item.DocumentData.Id)
                            ? "2px solid #C20E30"
                            : "2px solid #848484",
                        })}
                        variant="outlined"
                        id={item.DocumentData.Id}
                        onClick={(e) => {
                          setDocumetSelect(e.target.id);
                          handleSelectDocument(e);
                          setDocumentInProgress(null);
                        }}
                      >
                        {item.DocumentData.Name}
                      </ButtonDocLIst>
                    ))}
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <Box>
          {(urlXml !== undefined || urlXml !== null) && blobUrl == null ? (
            <Box sx={{ width: "100%", mt: 1 }}>
              <Box sx={{ display: "flex", mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                    wordWrap: "break-word",
                  }}
                >
                  <Typography variant="body2">
                    <b>{currentNameDocument ?? ""}</b>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {userAdmin === userData ||
                  userDataRol == "Super usuario" ||
                  userDataRol == "Consulta documentos" ? (
                    <IconButton
                      onClick={() =>
                        downloadFile(b64Xml, "xml", currentNameDocument)
                      }
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </Box>
              <Box
                overflow={"auto"}
                sx={{ wordWrap: "break-word", width: "100%", height: 690 }}
              >
                <XMLViewer height={500} xml={urlXml} />
              </Box>
            </Box>
          ) : blobUrl ? (
            <VisorPDF
              downloadActive={
                userAdmin === userData ||
                userDataRol == "Super usuario" ||
                userDataRol == "Consulta documentos"
                  ? true
                  : null
              }
              pdfUrl={blobUrl ?? ""}
              pdfName={currentNameDocument ?? ""}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </RootDocumentContent>
    </Fragment>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};
