import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, styled, Typography } from "@mui/material";
import { HeaderAppBar } from "../../components/HeaderAppBar.js";
import { Footer } from "../../components/Footer";
import { useSelector } from "react-redux";
import { Drawer } from "../../components/Drawer.jsx";
import HelpPages from "../../components/HelpPages.jsx";

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#F7F8FA",
  width: "100%",
  minHeight: "calc(100vh - 120px)",
  /* minHeight: "calc(100vh - 150px)", */
  // maxWidth: 1400,
  margin: "0px auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const OutletWrapper = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 18px 53px rgba(0, 0, 0, 0.14)",
  borderRadius: "10px",
  margin: "10px",
  // border: '1px solid blue',
  padding: "1.5em",
  // backgroundColor: "#CACCCE",
  [theme.breakpoints.up("lg")]: {
    margin: "10px 15px 5px 20px",
    height: "100vh",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "10px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "10px 10px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "10px 0px",
    // margin: "0px",
    padding: "0.6em",
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "none",
    marginBottom: 0,
    minHeight: "70vh",
  },
  overflowY: "auto !important",
}));

const MarginPrincipal = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    margin: "0px 45px 0px 0px",
    display: "grid !important",
    gridTemplateColumns: "17% 83%",
    gridAutoRows: "100vh !important",
    overflowY: "hidden !important",
    "&::-webkit-scrollbar": {
      width: "0",
      background: "transparent",
    },
    overflowX: "hidden",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
}));

const WrapperTittle = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  // borderRadius: "10px",
  // margin: "20px 15px 10px 20px",
  // border: '1px solid blue',
  backgroundColor: "transparent !important",
  overflowY: "hidden !important",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "130%",
  height: 65,
  // maxHeight: "65px",
  [theme.breakpoints.up("lg")]: {
    margin: "0px 0px 0px 20px",
    height: 65,
  },
  [theme.breakpoints.down("lg")]: {
    margin: "10px 0px 0px 20px",
    fontSize: "20px",
    height: 65,
  },
  [theme.breakpoints.down("md")]: {
    margin: "0px 0px 0px 20px",
    fontSize: "17px",
    minHeight: "28px",
    height: 15,
  },
  [theme.breakpoints.down("sm")]: {
    margin: "5px 10px",
    fontSize: "17px",
    minHeight: "30px",
    display: "none",
    height: 15,
  },
}));

export const Home = () => {
  const stateRedux = useSelector((state) => state);
  const fileState = useSelector((state) => state.File);
  const newFileState = useSelector((state) => state.NewFile);
  const viewTitulos = sessionStorage.getItem("viewDocument");
  const location = useLocation();
  const [drawer, setdrawer] = useState(false);
  const openDrawer = () => setdrawer(true);
  const closeDrawer = () => setdrawer(false);
  const [viewDocs, setViewDocs] = useState(null);

  const titule = (loc) => {
    if (
      loc.includes("/inbox/profileUser") ||
      loc.includes("/inbox/securityCode")
    ) {
      return null;
    } else {
      return (
        <WrapperTittle
          style={{
            height: loc.includes("/inbox/files") ? 70 : null,
          }}
        >
          <Box>{stateRedux?.GenericData?.title}</Box>
        </WrapperTittle>
      );
    }
  };

  useEffect(() => {
    setViewDocs(viewTitulos);
  }, [viewTitulos]);
  
  console.log("redux", stateRedux);

  return (
    <>
      <MarginPrincipal>
        <HeaderAppBar open={drawer} openDrawer={openDrawer} />
        <RootStyle>
          {viewTitulos === "true" || viewDocs === "true" ? null : (
            <>
              {titule(location.pathname)}
              {location.pathname.includes("/inbox/file-information") ||
              location.pathname.includes("/inbox/file-information-search") ? (
                <Typography sx={{ ml: 3, mt: 1 }} variant="titulo">
                  Folio: {fileState?.item?.FileData.Id} -{" "}
                  {fileState?.item?.Name}
                </Typography>
              ) : null}
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {location.pathname.includes("/inbox/new-file") ? (
                  newFileState?.editStack === true ? (
                    <Box sx={{ mt: 0 }}>
                      <Typography sx={{ ml: 3 }} variant="titulo">
                        Folio: {fileState?.item?.FileData.Id} -{" "}
                        {fileState?.item?.Name}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ mt: 0 }}>
                      <Typography sx={{ ml: 3 }} variant="titulo">
                        Folio:{" "}
                        {newFileState?.file?.Id
                          ? `${newFileState?.file?.Id} - `
                          : "Nuevo"}{" "}
                        {newFileState?.file?.Reference}
                      </Typography>
                    </Box>
                  )
                ) : null}
              </Box>
            </>
          )}
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
          <Footer />
        </RootStyle>
        <Drawer open={drawer} onClose={closeDrawer} />
      </MarginPrincipal>
    </>
  );
};
