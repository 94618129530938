import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class DocumentService {
  static instance;

  constructor() {
    this.baseUrl = "document";
  }

  static getInstance() {
    if (!DocumentService.instance) {
      DocumentService.instance = new DocumentService();
    }
    return DocumentService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (respObject.ResponseCode !== 0) {
      const responseObject = {
        message: `${respObject.Messages ?? respObject.Message} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject?.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject?.Body === undefined || respObject?.Body === null ? {
      Body:
        respObject?.Response !== undefined && respObject?.Response !== null ?
          respObject.Response
          : respObject
    } : respObject;
  }

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (respObject.ResponseCode !== 0) {
      let responseObject = {
        message: `${respObject.Messages ?? respObject.Message} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      // console.log('BodySecurityResponseForceEncripted respObject IsOK responseObject ', responseObject)
      const error = respObject?.Body || responseObject;
      return Promise.reject(error);
    }

    return respObject?.Body === undefined || respObject?.Body === null ? { Body: respObject } : respObject;
  }

  async handleDocument(response, extension) {
    let respObj = response.data;
    //Create a Blob from the PDF Stream
    const blob = new Blob([respObj.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  async InheritTraceSignDocuments(data) {
    console.log('DATA', data )
    const response = await GenericService.get({
      endpoint: `${this.baseUrl}/InheritTraceSignDocuments?file_Id=${data}`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    console.log('responseSigned', response)
    return response.data
  }

  async VerifyTraceSignConfiguration(data) {
    const response = await GenericService.get({
      endpoint: `${this.baseUrl}/VerifyTraceSignConfiguration?file_Id=${data}`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    return response.data
  }

  async VerifyTraceSignConfigurationMasive(data) {
    const response = await GenericService.get({
      endpoint: `${this.baseUrl}/VerifyTraceSignConfigurationMasive?file_Id=${data}`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    return response.data
  }

  async RegisterDocumentFieldData(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/RegisterDocumentFieldData`,
      data: data,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponse(response);
    return dataRes;
  }

  async AddTraceSignDocumentMasive(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/AddTraceSignDocumentMasive`,
      data: data,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponse(response);
    return dataRes;
  }

  async DeleteTraceSignDocumentMasive(data) {
    const response = await GenericService.get({
      endpoint: `${this.baseUrl}/DeleteTraceSignDocumentMasive?file_Id=${data}`,
      data: data,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponse(response);
    return dataRes;
  }

  async DeleteTraceSignDocument(data) {
    const response = await GenericService.get({
      endpoint: `${this.baseUrl}/DeleteTraceSignDocument?file_Id=${data}`,
      config: Config.configAuth(),
    });
    return response;
  };

  async GetDocumentToSignContent(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetDocumentToSignContent`,
      data: data,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponse(response);
    return dataRes;
  }

  async UploadTemplate(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/UploadTemplate2`,
      data: data,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponse(response);
    return dataRes;
  };

  async UploadTemplate2(data) {
    var formData = new FormData();
    formData.append('FileName', data.FileName);
    formData.append('FileContent', data.FileContent);
    formData.append('Extension', data.Extension);
    formData.append('Category_Id', data.CategoryId);
    formData.append('CategoryName', data.CategoryName);
    formData.append('Estatus', data.Estatus);

    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/UploadTemplate`,
      data: formData,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponse(response);
    return dataRes;
  };

  async ReOrderDocument(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/OrderDocument`,
      data: data,
      config: Config.configAuth(),
    });
    return response;
  };


}