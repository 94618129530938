import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Loader } from "../../components";
import {
  Box,
  Button,
  Divider,
  Grid,
  Alert as AlertMUI,
  Typography,
} from "@mui/material";
import { GenericTable } from "../../components/GenericTable";
import { LoadingButton } from "@mui/lab";
import { GetQuestionTypes, GetRolesUsers } from "../../store/actions";
import { TemplatesServices } from "../../services/TemplatesServices";
import BackdropComponent from "../../components/BackDrop";
import Colors from "./Color.json";
import { useLocation, useNavigate } from "react-router";
import moment from "moment/moment";
import { Icon } from "@iconify/react";

export default function DynamicInformationTemplate(props) {
  const [loading, setLoading] = useState(false);

  return (
    <Box
      sx={{
        mt: 3,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      <BackdropComponent loading={loading} />
      <Grid item lg={12} sx={{ p: 2 }}>
        <Box>
          <Typography variant="titulo">Configuración de plantilla</Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">
            Los siguientes campos serán solicitados a los firmantes para su
            captura, la información capturada será insertada a los documentos.
          </Typography>
        </Box>
      </Grid>
      <AlertMUI
        iconMapping={{
          warning: (
            <Icon
              icon="mdi:information-variant-circle"
              fontSize={30}
              style={{ color: "#FBBF28" }}
            />
          ),
        }}
        severity="warning"
        sx={{
          mt: 1,
          backgroundColor: "secondary.warningAlert",
          ".MuiAlert-icon": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        Ingresa el rol encargado de capturar la información, si deseas que la
        información sea capturada por más roles, debes definirlos e ingresarlos
        en el campo de captura correspondiente, los roles pueden ser modificados
        en esta sección o al momento de crear la solicitud.
      </AlertMUI>

      <InformationFillingRequestComponent
        BackItem={props.backItem}
        SetBackItem={props.setBackItem}
        setLoading={setLoading}
        handleBackStep={props.handleBackStep}
        handleNextStep={() => {
          props.handleNextStep();
        }}
      />
    </Box>
  );
}

const InformationFillingRequestComponent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formatDefaultDate = new Date();
  const [templateMetadataList, setTemplateMetadataList] = useState([]);
  const [sendDataActive, setSendDataActive] = useState(false);
  const [listOptionsTypes, setListOptionsTypes] = useState([]);

  const listDocStacDataState = useSelector(
    (state) => state.TemplatesData?.item
  );

  const dispatch = useDispatch();
  const QuestionsTypesState = useSelector((state) => state.QuestionsTypesData);

  useEffect(() => {
    dispatch(GetQuestionTypes(""));
  }, []);

  useEffect(() => {
    if (props.BackItem === false) {
      let listFilterDocs =
        listDocStacDataState.TypeProcess === "DOCX con campos" ||
        listDocStacDataState.TypeProcess === "PDF editable";
      if (
        listFilterDocs === true ||
        listFilterDocs !== null ||
        listFilterDocs !== undefined
      ) {
        let listAddFields = [];

        listDocStacDataState?.documentTemplateFields?.forEach(
          (itemFields, index) => {
            console.log("itemFields", itemFields.Name);
            let newObject = {
              Id: index + 1,
              NombreCampo: itemFields.Code,
              NombreEtiqueta: itemFields.Name.replace(/_|#|-|@|<>/g, (match) => match === '_' ? ' ' : ''),
              TipoDato: itemFields.DataType, /// Indica el tipo de dato default que quiere mosttarse en el control a pintar.
              Required: itemFields.Required,
              Role: itemFields.Role.trim(),
            };
            let objectExists = listAddFields.some(
              (item) => item.NombreCampo === newObject.NombreCampo
            );
            if (!objectExists) {
              listAddFields.push(newObject);
            }
          }
        );
        setTemplateMetadataList(listAddFields);
      }
    }
  }, [listDocStacDataState]);

  useEffect(() => {
    async function fetchData() {
      if (props.BackItem === true) {
        const templatesServices = TemplatesServices.getInstance();
        const responseUMD = await templatesServices.GetDocumentTemplateFields(
          listDocStacDataState.DocumentTemplate_Id
        );
        const objetosAgrupados = {};
        responseUMD.Body.forEach((obj) => {
          const code = obj.Code;
          if (!objetosAgrupados[code]) {
            objetosAgrupados[code] = obj;
          }
        });
        const resultado = Object.values(objetosAgrupados);

        let listAddFields = [];

        resultado?.forEach((itemFields, index) => {
          let newObject = {
            Id: index + 1,
            NombreCampo: itemFields.Code,
            NombreEtiqueta: itemFields.Name.replace(/_|#|-|@|<>/g, (match) => match === '_' ? ' ' : ''),
            TipoDato: itemFields.DataType, /// Indica el tipo de dato default que quiere mosttarse en el control a pintar.
            Required: itemFields.Required,
            Role: itemFields.Role.trim(),
          };
          let objectExists = listAddFields.some(
            (item) => item.NombreCampo === newObject.NombreCampo
          );
          if (!objectExists) {
            listAddFields.push(newObject);
          }
        });
        setTemplateMetadataList(listAddFields);
      }
    }

    fetchData(); // Llama a la función async desde el useEffect
  }, [props.BackItem]);

  useEffect(() => {
    if (
      QuestionsTypesState.items?.Response?.QuestionTypes !== undefined &&
      QuestionsTypesState.items?.Response?.QuestionTypes?.length > 0
    ) {
      let listUpdate = QuestionsTypesState.items?.Response?.QuestionTypes?.map(
        (item) =>
          item.Value === undefined ? { ...item, Value: item.Code } : item
      );
      setListOptionsTypes(listUpdate);
    }
  }, [QuestionsTypesState]);

  const columnsTable = [
    {
      Header: "Nombre de campo",
      accessor: "NombreCampo",
      Toltip: "Identificador que aparece en tu documento como metadato.",
    },
    {
      Header: "Etiqueta captura",
      accessor: "NombreEtiqueta",
      type: "Editable",
      Toltip: "Nombre que le aparecerá al usuario en su formulario.",
    },
    {
      Header: "Tipo de dato",
      accessor: "TipoDato",
      type: "Option",
      listOption: listOptionsTypes,
      Toltip: "Dato que deseas que sea solicitado al firmante.",
    },
    { Header: "Obligatorio", accessor: "select", type: "check" },
    {
      Header: "Rol de captura",
      accessor: "Role",
      type: "EditableRole",
      Toltip: "Persona que va a capturar la información.",
    },
  ];

  const handleSendData = async (data) => {
    props.setLoading(false);

    const DataFormat = templateMetadataList?.map((item) => {
      return {
        Code: item.NombreCampo,
        Name: data[`EtiquetaCaptura_${item.NombreEtiqueta}`],
        DataType: data[`TipoDato_${item.NombreEtiqueta}`],
        Required: data[`CheckCaptura_${item.NombreEtiqueta}`],
        Role: data[`RoleUser_${item.NombreEtiqueta}`].toUpperCase().trim(),
      };
    });

    const rolesUnicos = new Set();

    DataFormat.forEach((usuario) => {
      rolesUnicos.add(usuario.Role);
    });
    const rolesUnicosArray = Array.from(rolesUnicos);
    const newArrayDeRoles = rolesUnicosArray.map((role, index) => ({
      Role: role,
      Color: Colors[index].Color,
    }));
    dispatch(GetRolesUsers(newArrayDeRoles));

    let request = {
      DocumentTemplate_Id: listDocStacDataState.DocumentTemplate_Id,
      UploadMetadata: true,
      Questions: DataFormat,
    };

    console.log("request", request);

    const templatesServices = TemplatesServices.getInstance();
    const responseUMD =
      await templatesServices.UpdateDocumentTemplateFieldsCustom(request);

    if (responseUMD?.ResponseCode == 0) {
      props.setLoading(false);
      props.handleNextStep();
    } else {
      props.setLoading(false);
      Alert({
        icon: "error",
        text: `Ocurrio un error al intentar registrar los metadatos, intente nuevamente. ${responseUMD?.Message}`,
        okbtntext: "Aceptar",
      });
    }
  };

  const BackStepData = () => {
    if (location.state !== null && location.state !== undefined) {
      props.handleBackStep();
    } else if (
      listDocStacDataState !== null &&
      listDocStacDataState !== undefined
    ) {
      props.handleBackStep();
      navigate("/inbox/TemplateUpload", {
        state: {
          TemplateId: listDocStacDataState.DocumentTemplate_Id,
          TemplateName: listDocStacDataState.Name,
          CategoryName: listDocStacDataState.CategoryName,
          CreationDate: moment(
            listDocStacDataState.CreationDate ?? formatDefaultDate
          ).format("DD-MM-YYYY hh:mm:ss A"),
          Extension: listDocStacDataState.Extension,
          CategoryId: listDocStacDataState.Category_DocumentsTemplate_Id,
          Active: listDocStacDataState.Active ? "Activo" : "Inactivo",
          LastModification: moment(
            listDocStacDataState.LastModification ?? formatDefaultDate
          ).format("DD-MM-YYYY hh:mm:ss A"),
        },
      });
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      {QuestionsTypesState.loading ? (
        <Loader>Cargando datos...</Loader>
      ) : columnsTable?.length > 0 &&
        templateMetadataList?.length > 0 &&
        listOptionsTypes?.length > 0 &&
        (!sendDataActive || sendDataActive) ? (
        <GenericTable
          Columns={columnsTable}
          Data={templateMetadataList}
          onClickRow={() => {}}
          ShowPagination={false}
          sendDataActive={sendDataActive}
          HandleSendData={handleSendData}
          setSendDataActive={setSendDataActive}
        />
      ) : null}

      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        <Divider />
        <Grid item xs={12} sm={4}>
          <LoadingButton
            onClick={() => BackStepData()}
            fullWidth
            variant="outlined"
            sx={{ height: 45, px: 10 }}
          >
            Atras
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 45, px: 10 }}
            onClick={() => setSendDataActive(true)}
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
