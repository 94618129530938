import { documentConstants } from "../constants";
import { DocumentService } from "../../services/DocumentService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

const emptyAction = (actionType) => {
  return {
    type: actionType,
  };
};

export const RegisterDocumentFieldData = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(documentConstants.REGISTER_DOCUMENT_FIELDATA_EMPTY));
    } else {
      dispatch(request(documentConstants.REGISTER_DOCUMENT_FIELDATA_REQUEST));
      try {
        const documentService = DocumentService.getInstance();
        const responseData = await documentService.RegisterDocumentFieldData(data);
        dispatch(success(responseData.Body, documentConstants.REGISTER_DOCUMENT_FIELDATA_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, documentConstants.REGISTER_DOCUMENT_FIELDATA_FAILURE));
      }
    }
  };
};

export const UploadTemplateManager = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(documentConstants.UPLOAD_TEMPLATE_MANAGER_EMPTY));
    } else {
      dispatch(request(documentConstants.UPLOAD_TEMPLATE_MANAGER_REQUEST));
      try {
        const documentService = DocumentService.getInstance();
        const responseData = await documentService.UploadTemplate(data);
        dispatch(success(responseData.Body, documentConstants.UPLOAD_TEMPLATE_MANAGER_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, documentConstants.UPLOAD_TEMPLATE_MANAGER_FAILURE));
      }
    }
  };
};

export const InheritTraceSignDocuments = (data, isEmpty = false) => {
  console.log('responseSigned', data)
  return async (dispatch) => {
    if (isEmpty) {
      console.log('responseSigned 0', data)
      dispatch(emptyAction(documentConstants.INHERIT_TRACE_SIGN_DOCUMENTS_EMPTY));
    } else {
      dispatch(request(documentConstants.INHERIT_TRACE_SIGN_DOCUMENTS_REQUEST));
      try {
        console.log('responseSigned 1', data)
        const documentService = DocumentService.getInstance();
        const responseData = await documentService.InheritTraceSignDocuments(data);
        console.log('responseSigned', responseData)
        dispatch(success(responseData.data, documentConstants.INHERIT_TRACE_SIGN_DOCUMENTS_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, documentConstants.INHERIT_TRACE_SIGN_DOCUMENTS_FAILURE));
      }
    }
  };
};

