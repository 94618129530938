import axios from "axios";

export class GenericService {
  static baseUrl = process.env.REACT_APP_DSONBOARDING_API;
  static baseUrlPayment = process.env.REACT_APP_PAYMENTS_API

  /* static baseUrl = "https://techhub.docsolutions.com/OBComercialDev/WebApi/api/" */

  static async get({ endpoint, config }) {
    const response = await axios.get(`${this.baseUrl}${endpoint}`, config);
    return {
      data: response,
      status: response.status,
    };
  }

  static async getDoc({ endpoint, config }) {
    const response = await axios.get(`${this.baseUrl}${endpoint}`, config);
    return {
      data: response,
      status: response.status,
    };
  }

  static async post({ endpoint, data, config }) {
    const response = await axios.post(
      `${this.baseUrl}${endpoint}`,
      data,
      config
    );
    return {
      data: response,
      status: response.status,
    };
  }

  static async contentVideo({ endpoint, data, token }) {
    return axios({
      method: 'post',
      url: endpoint,
      data: data,
      headers: new Headers({
        'Authorization': `Basic ${token}`,
        'Accept': "*",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
        // 'Access-Control-Allow-Origin': '*'
      })
    })
  }
}


export class GenericServicePayment {

  static baseUrlPayment = process.env.REACT_APP_PAYMENTS_API

  static async get({ endpoint, config }) {
    const response = await axios.get(`${this.baseUrlPayment}${endpoint}`, config);
    return {
      data: response,
      status: response.status,
    };
  }

  static async getDoc({ endpoint, config }) {
    const response = await axios.get(`${this.baseUrlPayment}${endpoint}`, config);
    return {
      data: response,
      status: response.status,
    };
  }

  static async post({ endpoint, data, config }) {
    console.log('RESPONSEDAT', endpoint, data, config)
    console.log('RESPONSEDAT', this.baseUrlPayment)

    const response = await axios.post(
      `${this.baseUrlPayment}${endpoint}`,
      data,
      config,
    );
    console.log('responsas', response)
    return {
      data: response,
      status: response.status,
    };
  }

  static async contentVideo({ endpoint, data, token }) {
    console.log(endpoint, data, token);
    return axios({
      method: 'post',
      url: endpoint,
      data: data,
      headers: new Headers({
        'Authorization': `Basic ${token}`,
        'Accept': "*",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
        'Access-Control-Allow-Origin': '*'
      })
    })
  }
}
