import { authenticationConstants } from "../constants";

export const Authentication = (state = {}, action) => {
  switch (action.type) {
    case authenticationConstants.AUTHENTICATION_RESET:
      return {
        loading: false,
        items: undefined,
        error: undefined,
      };
    case authenticationConstants.AUTHENTICATION_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      }; //Mostrar gif loader mientras ejecuta request

    case authenticationConstants.AUTHENTICATION_SUCCESS:
      return {
        loading: false,
        items: action.payload, //Obtener respuesta correcta del API
        /* usuario: action.authenticationData.Body.UserLoginData.Username, */
        error: undefined,
      };

    case authenticationConstants.AUTHENTICATION_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error, //Mostrar error del API
      };
    case authenticationConstants.RESSET_PASSWORD_SUCCES:
      return {
        loading: false,
        items: action.payload,
        error: undefined, //Mostrar error del API
      };

    case authenticationConstants.SET_USER_NEW_PASS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: undefined, //Mostrar error del API
      };
    case authenticationConstants.VALIDATE_PIN:
      return {
        loading: false,
        items: action.payload,
        error: undefined, //Mostrar error del API
      };
    default:
      return state; //Retornar state vacío o actual
  }
};
//Siguiente paso => Volver a screens con respuesta
