
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetFilesByStatus, GetFilesChartCurrentMonth, GetGraphicsByApplicant, GetGraphicsByLastMonth, GetGraphicsByStatusDeadLine, GetGraphicsByTypeSign } from '../../store/actions/GraphicsActions';
import ChartBarras from './ChartBarras';
import ChartPieRadius from './ChartPieRadius';
import ChartDonout from './ChartDonout';

const Dashboards = () => {
    const dispatch = useDispatch();
    const dataChartLastMonth = useSelector((state) => state.GetGraphicsByLastMonth)
    const dataChartApplicant = useSelector((state) => state.GetGraphicsByApplicant)
    const dataChartTypeSign = useSelector((state) => state.GetGraphicsByTypeSign)
    const dataChartStatusDeadLine = useSelector((state) => state.GetGraphicsByStatusDeadLine)
    const dataChartStatusCurrentMonth = useSelector((state) => state.GetFilesChartCurrentMonth)
    const dataChartStatus = useSelector((state) => state.GetFilesByStatus)

    const [charLastMonth, setCharLastMont] = useState([])
    const [charApplicant, setCharApplicant] = useState([])
    const [charTypeSign, setCharTypeSign] = useState([])
    const [charStatusDeadLine, setCharStatusDeadLine] = useState([])
    const [charStatus, setCharStatus] = useState([])
    const [charCurrentMonth, setCharCurrentMonth] = useState([])

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                dispatch(GetGraphicsByLastMonth('aa'))
                dispatch(GetGraphicsByApplicant('aa'))
                dispatch(GetGraphicsByTypeSign('aa'))
                dispatch(GetGraphicsByStatusDeadLine('aa'))
                dispatch(GetFilesChartCurrentMonth('aa'))
                dispatch(GetFilesByStatus({
                    "anio": 0,
                    "mes": 0
                }))
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchAllData();
    }, [])

    useEffect(() => {
        if (dataChartLastMonth.item !== null &&
            dataChartApplicant.item !== null &&
            dataChartTypeSign.item !== null &&
            dataChartStatusCurrentMonth.item !== null &&
            dataChartTypeSign.item !== null &&
            dataChartStatus.item !== null) {
            setCharLastMont(dataChartLastMonth.item.Response)
            setCharApplicant(dataChartApplicant.item.Response)
            setCharTypeSign(dataChartTypeSign.item.Response)
            setCharStatusDeadLine(dataChartStatusDeadLine.item.Response)
            setCharStatus(dataChartStatus.item.Body)
            setCharCurrentMonth(dataChartStatusCurrentMonth.item.Response)
        }
    }, [dataChartLastMonth, dataChartApplicant, dataChartTypeSign, dataChartStatusCurrentMonth, dataChartStatus])

    return (
        <Box>
            <Grid container items alignContent={"center"} justifyContent={'center'} justifyItems={"center"}>
                <Grid item lg={6} xs={12}>
                    <ChartBarras data={charLastMonth} name={"Solicitudes por Fecha"} idData={"dataTableFecha"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <ChartPieRadius data={charStatus.EnProcesoFinalizado} name={"Solicitudes por Estatus"} idData={"dataTableStatus"} />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <ChartBarras data={charApplicant} name={"Solicitudes por Solicitante"} idData={"dataTableSolicitante"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <ChartPieRadius data={charTypeSign} name={"Solicitudes por Tipo de Firma"} idData={"dataTableFirma"} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <ChartDonout data={charStatusDeadLine} name={"Solicitudes por Estatus en Tiempo Límite"} idData={"dataTableLimit"} />
                </Grid>
                <Grid item lg={12} xs={12}>
                    <ChartBarras data={charStatus.SolicitudesEnProcesoDetalle} name={"Solicitudes por Etapas"} idData={"dataTableEtapa"} />
                </Grid>
            </Grid>
        </Box >
    );
};
export default Dashboards;