import { userConstants } from "../constants";
import { UsersService } from "../../services/UsersService";

const request = (actionType) => {
    return {
        type: actionType,
    };
};

const success = (filesData, actionType) => {
    return {
        type: actionType,
        payload: filesData,
    };
};

const failure = (error, actionType) => {
    return {
        type: actionType,
        payload: error,
    };
};

export const getUsersData = (filesData) => {
    return async (dispatch) => {
        dispatch(request(userConstants.GET_USERS_DATA_REQUEST));
        try {
            const usersService = UsersService.getInstance();
            const fileData = await usersService.GetUsersData(filesData);
            dispatch(success(fileData.Body, userConstants.GET_USERS_DATA_SUCCESS));
        } catch (error) {
            dispatch(failure(error.message, userConstants.GET_USERS_DATA_FAILURE));
        }
    };
};
