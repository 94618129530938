import React, { useState } from "react";
import { styled, Box, Typography, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FileService } from "../../services/FileService";
import { useSelector } from "react-redux";
import { ToastNotification } from "../../components/toastNotification";
import { Alert } from "../../components/Alert";
import { useNavigate } from "react-router-dom";
import { CustomModalAlert } from "../../components/CustomModalAlert";

const useStyles = makeStyles((theme) => ({
  containerPrincipal: {
    border: ".8px solid",
    borderRadius: 4,
    borderColor: theme.palette.secondary.main60,
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  containerConfig: {
    marginTop: 10,
    width: "80%",
    borderLeft: `1.8px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "99%",
  borderBottom: ".8px solid",
  borderBottomColor: theme.palette.secondary.main60,
  justifyContent: "space-between",
  marginTop: 8,
  marginLeft: 5,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "35%",
  marginBottom: 3,
  marginTop: 4,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "100%",
  overflow: "auto",
  marginTop: 4,
  marginBottom: 3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default function FileConfigurationAdminTab() {
  const navigate = useNavigate();
  const classes = useStyles();
  const file = useSelector((state) => state.File.item);
  const [open, setOpen] = useState(false);
  const [openReturnSigned, setOpenReturnSigned] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const returnToSignature = async () => {
    try {
      const fileCustomService = FileService.getInstance();
      const responseReturn = await fileCustomService.ResetFileSignStage({
        File_Id: file.FileData.Id,
      });
      if (
        responseReturn.data.data.Message !== null &&
        responseReturn.data.data.ResponseCode === 1
      ) {
        ToastNotification({
          icon: "error",
          text: responseReturn.data.data.Message,
        });
        setOpenReturnSigned(!openReturnSigned);
      } else if (responseReturn.data.data.ResponseCode === 0) {
        Alert({
          icon: "success",
          title: "Solicitud reiniciada con éxito",
          text: "Su solicitud se reprocesará al proceso de firma nuevamente",
        });
        navigate("/inbox/files");
        setOpenReturnSigned(!openReturnSigned);
      }
    } catch (error) {
      console.log("error", error);
      setOpenReturnSigned(!openReturnSigned);
    }
  };

  const returnRequest = async () => {
    try {
      const fileCustomService = FileService.getInstance();
      const responseReturn = await fileCustomService.ResetFileNewFileStage({
        File_Id: file.FileData.Id,
      });
      if (
        responseReturn.data.data.Message !== null &&
        responseReturn.data.data.ResponseCode === 1
      ) {
        ToastNotification({
          icon: "error",
          text: responseReturn.data.data.Message,
        });
        handleClickOpen();
      } else if (
        responseReturn.data.data.ResponseCode === 0 &&
        responseReturn.data.data.Response.m_Item1 === true
      ) {
        Alert({
          icon: "success",
          title: "Solicitud reiniciada con éxito",
          text: "Su solicitud se reprocesará al proceso de nuevo expediente",
        });
        navigate("/inbox/files");
        handleClickOpen();
      }
      console.log("responseReturn", responseReturn);
    } catch (error) {
      console.log("error", error);
      handleClickOpen();
    }
  };

  return (
    <Grid container justifyContent={"center"} item lg={12}>
      <Box className={classes.containerPrincipal}>
        <BoxRexponsiveText
          titulo={<b>Regresión de Firma</b>}
          description={"El expediente comenzará de nuevo su proceso de firma."}
          texto={
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: 45, px: 10 }}
                onClick={() => setOpenReturnSigned(!openReturnSigned)}
              >
                Regresar
              </Button>
            </Box>
          }
        />
        <BoxRexponsiveText
          titulo={<b>Regresión de solicitud</b>}
          description={
            "El expediente regresará a proceso de nuevo expediente para poder cambiar y/o editar su configuración e iniciarse de nuevo."
          }
          texto={
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: 45, px: 10 }}
                onClick={() => handleClickOpen()}
              >
                Regresar
              </Button>
            </Box>
          }
        />
        <CustomModalAlert
          Open={open}
          Title={
            "¿Estás seguro que deseas regresar esta solicitud al proceso de Nuevo Expediente?"
          }
          LeftButtonText="Cancelar"
          LeftButtonAction={() => handleClickOpen()}
          RightButtonText="Aceptar"
          Comments={false}
          RightButtonAction={() => returnRequest()}
        />
        <CustomModalAlert
          Open={openReturnSigned}
          Title={
            "¿Estás seguro que deseas regresar esta solicitud al proceso de Firma de Documentos?"
          }
          LeftButtonText="Cancelar"
          LeftButtonAction={() => setOpenReturnSigned(!openReturnSigned)}
          RightButtonText="Aceptar"
          Comments={false}
          RightButtonAction={() => returnToSignature()}
        />
      </Box>
    </Grid>
  );
}

const BoxRexponsiveText = (props) => {
  return (
    <BoxContainer>
      <BoxTexto>
        <Typography sx={{ mb: 2 }} variant="subtitle1">
          {props.titulo}
        </Typography>
        <Typography variant="subtitle1">{props.description}</Typography>
      </BoxTexto>
      <BoxTitulo>
        <Typography variant="subtitle1">{props.texto}</Typography>
      </BoxTitulo>
    </BoxContainer>
  );
};
