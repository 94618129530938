import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  styled,
  Typography,
  Grid,
  AccordionDetails as AccDet,
  Accordion as Acc,
  AccordionSummary as AccSum,
  Alert as AlertMUI,
  IconButton,
  Radio,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx/xlsx.js";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import iconDocument from "../../assets/icons/Documento.png";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { TypoTooltip } from "../../components/TypoTooltip";
import { Divider } from "../../components/Divider";
import {
  resetPdf,
  addToDocsStack,
  setDocsStack,
  UploadMetadataBatchFileFC,
  GetQuestionTypes,
  GetContentTemplate,
  GetTemplatesActive,
  GetCategoriesTemplate,
} from "../../store/actions";
import { FileService } from "../../services/FileService";
import { Alert } from "../../components/Alert";
import { Loader } from "../../components/Loader";
import { DocumentButton } from "./DocumentButton";
import { FileFlowService } from "../../services/FileFlowService";
import { CustomInputField, CustomModalDialog } from "../../components";
import { UploadGenericControl } from "../../components/UploadGenericControl";
import iconExel from "../../assets/icons/IconTemplateDownload.png";
import { GenericTable } from "../../components/GenericTable";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { b64toUrl } from "../../utils";
import { ToastNotification } from "../../components/toastNotification";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import ReorderDocuments from "./ReorderDocuments";
import { DocumentService } from "../../services/DocumentService";

const DocButtonLoaded = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #CCCCCC",
  borderRadius: 0,
  padding: "6px 8px",
  width: "100%",
}));

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "1px solid red",
  borderBottomColor: theme.palette.secondary.main40,
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));
const AccordionDetails = styled(AccDet)(({ theme }) => ({
  padding: 20,
}));

export const CaptureFileTab = (props) => {
  const { control, register, watch, setValue } = useForm({ mode: "onBlur" });

  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const [file, setfile] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [fileBase64, setfileBase64] = useState(null);
  const [documentTypes, setdocumentTypes] = useState([]);
  const [docSelectorStage, setdocSelectorStage] = useState("init");
  const [confirmFile, setconfirmFile] = useState(false);
  const [openaccordion, setopenaccordion] = useState(true);
  const [bodyInfo, setBodyInfo] = useState(null);
  const [loadingNextDocument, setLoadingNextDocument] = useState(false);

  const [newOrdenDocs, setNewOrdenDocs] = useState([]);
  const [activeReorder, setActiveReorder] = useState(false);

  const [templateUploadOption, setTemplateUploadOption] = useState(null);
  const [nameDocumentCurrent, setNameDocumentCurrent] = useState("");
  const [metaDataDocumentCurrent, setMetaDataDocumentCurrent] = useState(null);
  const [actionCurrent, setActionCurrent] = useState("");
  const [extensionCurrent, setExtensionCurrent] = useState("");
  const [useTemplate, setUseTemplate] = useState(false);
  const [IdTemplate, setIdTemplate] = useState("");
  const [typeConfiguration, setTypeConfiguration] = useState("");
  const rdTypeChange = watch("rdTypeChange");

  const [documentNewList, setDocumentNewList] = useState([]);

  const dispatch = useDispatch();
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  useScrollToTop();
  const idWorkFlow = useSelector(
    (state) =>
      state.Authentication.items?.UserLoginData?.EnviromentVars?.WorkFlow_Id
  );

  console.log("idWorkFlow", idWorkFlow);

  const listDocStacDataState = useSelector(
    (state) => state.NewFile?.documentsStack
  );

  useEffect(() => {
    setDocumentNewList(newFileState?.documentsStack);
  }, [newFileState.documentsStack]);

  useEffect(() => {
      getProductId();
      setValue("rdTypeChange", "Carga de documento");
  }, []);

  useEffect(() => {
    if (
      listDocStacDataState !== undefined &&
      listDocStacDataState !== null &&
      (actionCurrent === "add" || actionCurrent === "next")
    ) {
      if (actionCurrent === "add") {
        handleAddAnotherDocument();
      } else if (actionCurrent === "next") {
        handleSetupParticipants();
      }
      setActionCurrent("");
    }
  }, [listDocStacDataState]);

  // AQUI METEMOS LOS DOUCMENTOS DENTRO DEL STACK EN CASO DE EXISTIR
  useEffect(() => {
    if (newFileState.editStack) {
      if (fileState?.item) {
        const checkListDocs = fileState?.item?.Documents?.map((item) => {
          return {
            ...item,
            DocResponseId: item.DocumentData.Id,
          };
        });
        dispatch(setDocsStack(checkListDocs));
      }
    }
  }, [fileState?.item]);

  useEffect(() => {
    if (rdTypeChange == "Plantilla") {
      setdocSelectorStage("SelectTemplates");
    } else {
      setdocSelectorStage("init");
    }
  }, [rdTypeChange]);

  const changeFile = () => {
    dispatch(resetPdf());
    setfile(null);
    setfileUrl(null);
    setfileBase64(null);
    setconfirmFile(false);
  };

  const handleAddAnotherDocument = () => {
    setdocSelectorStage("init");
    setopenaccordion(true);
    changeFile();
  };

  const getProductId = async () => {
    const fileService = FileService.getInstance();
    const fileData = await fileService.getDocumentType(
      fileState.item?.FileData?.ProductId || newFileState.form?.Id
    );
    console.log("fileDataUsers", fileData);
    // idWorkFlow
    setdocumentTypes(fileData.Body.Groups[0].DocumenTypes);
  };

  const agregarStageParticipante = async () => {
    try {
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: folioIdSolicitud,
        Status: "Configuración de participantes",
        Result: null,
      });
    } catch (error) {
      console.log("errorerror", error);
    }
  };

  const handleSetupParticipants = async () => {
    let listDocsMailmerge = listDocStacDataState.filter(
      (item) =>
        item.TypeDocumentoProcess === "DOCX con campos" ||
        item.TypeDocumentoProcess === "PDF editable"
    );
    if (
      listDocsMailmerge?.length > 0 &&
      typeConfiguration === "docProcesoCaptura"
    ) {
      setdocSelectorStage("changeTemplate");
    } else {
      agregarStageParticipante();
      const someProcess = newFileState.documentsStack.filter(
        (doc) =>
          doc.Sign ||
          doc.Check ||
          doc.DocumentConfiguration.Sign ||
          doc.DocumentConfiguration.Check
      );
      if (docSelectorStage === "init") {
        if (someProcess.length > 0) {
          props.handleNextStep();
        } else if (someProcess.length === 0) {
          props.handleSetStep(3);
        }
      }
      if (docSelectorStage === "information") {
        if (someProcess.length > 0) {
          props.handleNextStep();
        }
      }
    }
  };

  useEffect(() => {
    if (newFileState.document?.file) {
      const file = newFileState.document.file;
      setfile(file);
    }
  }, [newFileState]);

  let onSubmitUploadDocumentMetadata = async (data, action) => {
    setLoadingNextDocument(true);
    try {
      setActionCurrent(action);
      let dateCurrentExp =
        data.validityDefaultDocument !== undefined &&
        data.validityDefaultDocument !== null
          ? new Date(data.validityDefaultDocument)
          : null;

      let Body = {
        FileId: folioIdSolicitud,
        DocumentTemplate_Id:
          (IdTemplate !== "" || IdTemplate !== null) && useTemplate === true
            ? IdTemplate
            : null,
        DocumentConfiguration: {
          SkipSearchFields:
            (IdTemplate !== "" || IdTemplate !== null) && useTemplate === true
              ? false
              : true,
          DocumentTypeId: documentTypes[0].DocumenType_Id,
          FileName: nameDocumentCurrent,
          Public: false,
          Capture: false,
          Check: data.rdTypeProcessDocs === "docProcesoRevision" ? true : false,
          Sign: true,
          OnlyView:
            data.rdTypeProcessDocs === "docComplementario" ? true : false,
          SameValidityFile: true,
          Validity:
            data.validityDocument !== undefined &&
            data.validityDocument !== null
              ? data.validityDocument
              : dateCurrentExp !== undefined && dateCurrentExp !== null
              ? dateCurrentExp
              : null,
          AutomaticRenewal: false,
          Publish: false,
          TypeDocumentoProcess: "",
          UploadMetadata: true,
          ConvertDocumentToImage: true,
        },
        UploadConfiguration:
          (IdTemplate === "" || IdTemplate === null) && useTemplate === false
            ? {
                B64Content: fileBase64,
                Extension: extensionCurrent.includes(".")
                  ? `${extensionCurrent}`
                  : `.${extensionCurrent}`,
              }
            : null,
      };
      console.log('Body', Body )
      let response = "";

      const fileService = FileService.getInstance();
      if ((IdTemplate === "" || IdTemplate === null) && useTemplate === false) {
        response = await fileService.registerDocument(Body);
      } else {
        response = await fileService.registerDocumentTemplateData(Body);
      }

      const objetoModificado = { ...Body };
      objetoModificado.DocumentId = response.Body.DocumentData.Id;
      objetoModificado.DocumentConfiguration.TypeDocumentoProcess =
        response.Body.TypeDocumentoProcess;
      objetoModificado.DocumentConfiguration.DocumentTemplate_Id =
        (IdTemplate !== "" || IdTemplate !== null) && useTemplate === true
          ? IdTemplate
          : null;
      setBodyInfo(objetoModificado);

      // NOTA Carga documento con una respuesta TypeDocumentoProcess, para saber que tipo de documento trae
      dispatch(
        addToDocsStack({
          DocResponseId: response.Body.DocumentData.Id,
          file,
          fileUrl: fileUrl,
          TypeDocumentoProcess: response.Body.TypeDocumentoProcess,
          DocumentData: response.Body.DocumentData,
          ...Body,
        })
      );
      setUseTemplate(false);
      setIdTemplate("");
      setLoadingNextDocument(false);
    } catch (error) {
      ToastNotification({
        icon: "error",
        text: "Ha ocurrido un error." + error,
      });
      setLoadingNextDocument(false);
    }
  };

  useEffect(() => {
    if (activeReorder === true) {
      setActiveReorder(false);
      dispatch(setDocsStack(newOrdenDocs));
    }
  }, [newOrdenDocs, activeReorder]);

  useEffect(() => {
    if (
      newFileState.documentsStack.length != 0 &&
      newFileState.documentsStack !== null
    ) {
      let newOrden = newFileState.documentsStack.map((item, index) => {
        return {
          Document_Id: item.DocResponseId,
          Order: index + 1,
        };
      });
      registerNewOrdenDocuments(newOrden);
    }
  }, [newFileState.documentsStack]);

  const registerNewOrdenDocuments = async (data) => {
    try {
      const documentService = DocumentService.getInstance();
      await documentService.ReOrderDocument({
        File_Id: folioIdSolicitud,
        OrderDocuments: data,
      });
    } catch (error) {
      console.error("ErrorOrdenamiento", error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <RootStyle>
        <Accordion
          disableGutters
          defaultExpanded
          expanded={file && confirmFile ? true : openaccordion}
          onChange={() => setopenaccordion(!openaccordion)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="titulo">
              {templateUploadOption == 1
                ? "Verificación de información"
                : templateUploadOption == 2
                ? "Solicitud de llenado de información"
                : file && confirmFile
                ? "Información del documento - " + nameDocumentCurrent
                : file && confirmFile
                ? "Documento - " + nameDocumentCurrent
                : "Prepare un documento para solicitar que sea revisado y/o firmado"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            {docSelectorStage === "init" && (
              <>
                <Typography variant="body1" sx={{ my: 2 }}>
                  Carga los documentos que formarán parte del expediente, estos
                  documentos podrán ser editables o solo de lectura.
                </Typography>

                <CustomInputField
                  register={register}
                  inputType="radio"
                  control={control}
                  name="rdTypeChange"
                  radioValues={[
                    {
                      value: "Carga de documento",
                      label: "Carga de documento",
                    },
                    {
                      value: "Plantilla",
                      label: "Plantilla",
                    },
                  ]}
                  validations={{ required: true }}
                  required={true}
                />
                {rdTypeChange === "" ? (
                  <AlertMUI
                    iconMapping={{
                      warning: (
                        <Icon
                          icon="mdi:information-variant-circle"
                          fontSize={30}
                          style={{ color: "#FBBF28" }}
                        />
                      ),
                    }}
                    severity="warning"
                    sx={{
                      backgroundColor: "secondary.warningAlert",
                      ".MuiAlert-icon": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    Puedes cargar plantillas las cuales deberán estar en formato
                    word y podrás configurar campos, para esto el campo deberá
                    estar precedido de un signo de #, sin espacios y caracteres
                    especiales. <br></br>
                    Ejemplo: #nombre#, #NOMBRE_APELLIDOS#, #nombre_apellidos#,
                    #nombreapellidos#, #NOMBRE#
                  </AlertMUI>
                ) : null}
                <Box sx={(theme) => ({ boxShadow: theme.shadows[9], mt: 2 })}>
                  <ReorderDocuments
                    newOrdenDocs={newOrdenDocs}
                    setNewOrdenDocs={setNewOrdenDocs}
                    data={documentNewList}
                    setActiveReorder={setActiveReorder}
                  />
                  <DocumentButton
                    doc={{ Name: "Añadir documento" }}
                    onSelect={() => {
                      setdocSelectorStage("upload");
                    }}
                  />
                </Box>
              </>
            )}

            {docSelectorStage === "upload" && (
              <>
                <UploadFileComponent
                  onChange={(file, base64, extension, fileUrl, metaData) => {
                    setfile(file);
                    setExtensionCurrent(extension);
                    setfileBase64(base64);
                    if (file !== undefined && file !== null) {
                      let nameInput = file.name.lastIndexOf(".");
                      setNameDocumentCurrent(file.name.substring(0, nameInput));
                    }
                    setMetaDataDocumentCurrent(metaData);
                  }}
                />
              </>
            )}

            {file && confirmFile && docSelectorStage === "information" && (
              <DetailedInformationUploadedFile
                loadingNextDocument={loadingNextDocument}
                metaDataDocument={metaDataDocumentCurrent}
                NameDocumentCurrent={nameDocumentCurrent}
                setTypeConfiguration={setTypeConfiguration}
                onClick={(data, action) => {
                  onSubmitUploadDocumentMetadata(data, action);
                }}
              />
            )}

            {docSelectorStage === "changeTemplate" && (
              <>
                {templateUploadOption == 1 ? (
                  <Typography variant="body1" sx={{ my: 2 }}>
                    Verifica que la información sea correcta, si existe algún
                    error deberás modificarlo y cargar nuevamente el archivo
                    presionando el botón <b>actualizar archivo</b>.
                  </Typography>
                ) : templateUploadOption == 2 ? (
                  <Typography variant="body1" sx={{ my: 2 }}>
                    Los siguientes campos serán solicitados a los firmantes para
                    su captura, la información capturada será insertada a las
                    plantillas.
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body1" sx={{ my: 2 }}>
                      Si cuentas con la información complementaría, descarga el
                      formato de carga (Excel), agrega la información y al
                      finalizar, adjunta tu archivo en la plataforma.
                    </Typography>
                    <AlertMUI
                      iconMapping={{
                        warning: (
                          <Icon
                            icon="mdi:information-variant-circle"
                            fontSize={30}
                            style={{ color: "#FBBF28" }}
                          />
                        ),
                      }}
                      severity="warning"
                      sx={{
                        backgroundColor: "secondary.warningAlert",
                        ".MuiAlert-icon": {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                    >
                      Descarga el formato de Excel, y agrega la información
                      complementaría.<br></br>
                      Si no cuentas con la información del usuario, podrás
                      ingresarla manualmente en la sección.
                    </AlertMUI>
                  </>
                )}
                <UploadFileTemplateComponent
                  BodyInfo={bodyInfo}
                  handleAddAnotherDocument={handleAddAnotherDocument}
                  setdocSelectorStage={setdocSelectorStage}
                  setTemplateUploadOption={setTemplateUploadOption}
                  HandleNextStep={() => {
                    
                    props.handleNextStep();
                  }}
                  FileId={folioIdSolicitud}
                />
              </>
            )}

            {docSelectorStage === "SelectTemplates" && (
              <>
                <Typography variant="body1" sx={{ my: 2 }}>
                  Para incorporar la plantilla, comience eligiendo la categoría
                  correspondiente. A continuación, realice una búsqueda de la
                  plantilla deseada dentro de esa categoría y, una vez ubicada,
                  simplemente haga clic en el cuadro para seleccionarla.
                </Typography>

                <DetailTemplatesUploaded
                  setUseTemplate={setUseTemplate}
                  setIdTemplate={setIdTemplate}
                  setdocSelectorStage={setdocSelectorStage}
                  handleConfirm={(data, base64) => {
                    setfile({
                      name: data.FileName,
                    });
                    setExtensionCurrent(data.Extension.replace(/\s+/g, ""));
                    setfileBase64(base64);
                    setNameDocumentCurrent(
                      data.FileName.replace(
                        data.Extension.replace(/\s+/g, ""),
                        ""
                      )
                    );
                    setMetaDataDocumentCurrent(null);

                    setconfirmFile(true);
                    setopenaccordion(false);
                    setdocSelectorStage("information");
                  }}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>

        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          {docSelectorStage === "init" && (
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                sx={{ height: 45, px: 10 }}
                onClick={() => {
                  props.handleBackStep();
                }}
              >
                Anterior
              </Button>
            </Grid>
          )}
          {file && !confirmFile && (
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10 }}
                onClick={() => {
                  setconfirmFile(true);
                  setopenaccordion(false);
                  setdocSelectorStage("information");
                }}
              >
                Siguiente
              </Button>
            </Grid>
          )}

          {docSelectorStage === "init" && (
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10 }}
                onClick={handleSetupParticipants}
                disabled={!listDocStacDataState.length > 0}
              >
                Siguiente
              </Button>
            </Grid>
          )}
        </Grid>
      </RootStyle>
    </LocalizationProvider>
  );
};

const UploadFileComponent = (props) => {
  const [isChangeFile, setIsChangeFile] = useState(null);
  return (
    <>
      {!isChangeFile ? (
        <Typography variant="body1" textAlign={"justify"} sx={{ mt: 2 }}>
          Carga los documentos que formarán parte de la solicitud, estos
          documentos podrán ser editables o de solo lectura. Prepara un
          documento a cargar del tipo pdf o docx.
        </Typography>
      ) : (
        <>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Asegúrate que el documento sea el correcto y presiona el botón
            siguiente.
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, display: "flex", alignItems: "center" }}
          >
            Si el documento no es el correcto, presiona el botón de eliminar{" "}
            <Icon icon="ph:trash-light" fontSize={19} sx={{ ml: 3, mr: 3 }} /> y
            carga el archivo correcto.
          </Typography>
        </>
      )}
      <UploadGenericControl
        UploadTemplate={false}
        Accept=".docx,.pdf"
        onChange={(file, base64, extension, fileUrl, metaData) => {
          setIsChangeFile(base64);
          props.onChange(file, base64, extension, fileUrl, metaData);
        }}
      />
    </>
  );
};

const DetailedInformationUploadedFile = (props) => {
  const [isEditable, setIsEditable] = useState(null);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState,
    resetField,
  } = useForm({ mode: "onBlur" });

  const [savingDocument, setsavingDocument] = useState(false);
  const watchValidity = watch("validity");
  const watchTypeConfiguration = watch("rdTypeProcessDocs");
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  const handleValidateDateMax = () => {
    const ValidityDate = newFileState.editStack
      ? fileState.item?.Validity
      : newFileState.file?.fileForm?.FileConfiguration?.Validity;
    if (ValidityDate?.length > 0) {
      const limDate = new Date(ValidityDate);
      return limDate.setDate(limDate.getDate());
    } else {
      return new Date();
    }
  };

  useEffect(() => {
    setValue("sign", true);
  }, []);

  useEffect(() => {
    if (
      props.NameDocumentCurrent !== undefined &&
      props.NameDocumentCurrent != null
    ) {
      setValue("docName", props.NameDocumentCurrent);
    }
  }, [props.NameDocumentCurrent]);

  useEffect(() => {
    if (
      props.metaDataDocument !== undefined &&
      props.metaDataDocument != null
    ) {
      let activeEditable =
        props.metaDataDocument?.TypeDocumentoProcess === "PDF editable"
          ? true
          : props.metaDataDocument?.TypeDocumentoProcess === "DOCX con campos"
          ? true
          : false;
      setIsEditable(activeEditable);
      if (activeEditable) {
        setValue("chkRequiereCapturaInfo", activeEditable);
      }
    }
  }, [props.metaDataDocument]);

  useEffect(() => {
    if (watchValidity === "noassign") {
      resetField("validityDefaultDocument");
      resetField("validityDocument");
    } else if (watchValidity === "assign") {
      resetField("validityDefaultDocument");
    } else if (watchValidity === "assignExpDefault") {
      resetField("validityDocument");
      const ValidityDate =
        newFileState.editStack === true
          ? fileState.item?.Validity
          : newFileState.file?.fileForm?.FileConfiguration?.Validity
          ? newFileState.file?.fileForm?.FileConfiguration?.Validity?.toString()
          : "";
      if (ValidityDate?.length > 0) {
        const limDate = new Date(ValidityDate);
        setValue("validityDefaultDocument", new Date(limDate));
      }
    }
  }, [watchValidity, resetField]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="body1">
        Captura la información relacionada con el documento.
      </Typography>
      <CustomInputField
        register={register}
        label="Nombre del documento"
        control={control}
        name="docName"
        validations={{ required: true }}
        error={!!formState.errors["docName"]}
        helperText={formState.errors["docName"]?.message}
        required={true}
      />
      <Typography variant="subtitle1" sx={{ fontWeight: 700, mt: 1 }}>
        Vigencia documental <spam style={{ color: "#C20E30" }}>*</spam>
      </Typography>
      <Box>
        <CustomInputField
          inputType="radio"
          control={control}
          name="validity"
          radioValues={[
            { value: "assign", label: "Asignar una fecha" },
            {
              value: "assignExpDefault",
              label: "Misma vigencia documental",
            },
            {
              value: "noassign",
              label: "Sin vigencia",
            },
          ]}
          validations={{ required: true }}
          error={!!formState.errors["validity"]}
          helperText={formState.errors["validity"]?.message}
          required={true}
        />
      </Box>
      {watchValidity === "assign" && (
        <CustomInputField
          register={register}
          inputType="date"
          control={control}
          name="validityDocument"
          validations={{ required: true }}
          error={!!formState.errors[`validityDocument`]}
          helperText={formState.errors[`validityDocument`]?.message}
          sxInput={{ maxWidth: 500 }}
          required={true}
          minDate={Date.now()}
          defaultValue={""}
        />
      )}
      {watchValidity === "assignExpDefault" && (
        <CustomInputField
          register={register}
          inputType="date"
          control={control}
          name="validityDefaultDocument"
          validations={{ required: true }}
          error={!!formState.errors[`validityDefaultDocument`]}
          helperText={formState.errors[`validityDefaultDocument`]?.message}
          minDate={Date.now()}
          maxDate={handleValidateDateMax()}
          sxInput={{ maxWidth: 500 }}
          disabled={true}
          defaultValue={""}
        />
      )}
      <TypoTooltip sxTypo={{ mt: 2 }} text="" />
      {isEditable ? (
        <>
          <Typography variant="subtitle1" sx={{ fontWeight: 700, mt: 3 }}>
            Selecciona el tipo de operación que se realizará en el documento
          </Typography>
          <CustomInputField
            inputType="check"
            control={control}
            name="chkRequiereCapturaInfo"
            label="Se requiere captura de información"
            tooltipText=""
            defaultValue={true}
            disabled={true}
            validations={{ required: true }}
            error={!!formState.errors["chkRequiereCapturaInfo"]}
            helperText={formState.errors["chkRequiereCapturaInfo"]?.message}
          />
        </>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, mt: 2, mb: 0.8 }}
          >
            Selecciona el tipo de operación que se realizará sobre el documento{" "}
            <spam style={{ color: "#C20E30" }}>*</spam>
          </Typography>
          <CustomInputField
            register={register}
            inputType="radio"
            control={control}
            name="rdTypeProcessDocs"
            radioValues={[
              {
                value: "docProcesoRevision",
                label: "El documento tendrá un proceso de revisión",
              },
              {
                value: "docProcesoCaptura",
                label: "El documento tendrá un proceso de captura",
              },
              {
                value: "docComplementario",
                label:
                  "Documento complementario (solo lectura) o directo a firma",
              },
            ]}
            validations={{ required: true }}
            error={!!formState.errors["rdTypeProcessDocs"]}
            helperText={formState.errors["rdTypeProcessDocs"]?.message}
            required={true}
          />
        </>
      )}
      <Typography variant="subtitle1" sx={{ fontWeight: 700, mt: 3 }}>
        Firma de documento
      </Typography>
      <CustomInputField
        inputType="check"
        control={control}
        name="sign"
        label="El documento tendrá un proceso de firmado electrónico"
        tooltipText="Proceso donde una persona acepta y da por válido el
                  contenido del documento para confirmar su integridad,
                  empleando datos legítimos de firma electrónica."
        defaultValue={true}
        disabled={true}
        error={!!formState.errors["sign"]}
        helperText={formState.errors["sign"]?.message}
      />

      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        <Divider />
        {watchTypeConfiguration == "docProcesoCaptura" ? null : (
          <Grid item xs={12} sm={4}>
            <LoadingButton
              fullWidth
              loading={props.loadingNextDocument}
              variant="outlined"
              sx={{ height: 45, px: 10 }}
              onClick={handleSubmit((data) => {
                props.onClick(data, "add");
              })}
            >
              Añadir otro documento
            </LoadingButton>
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <LoadingButton
            fullWidth
            loading={props.loadingNextDocument}
            variant="contained"
            sx={{ height: 45, px: 10 }}
            onClick={handleSubmit((data) => {
              setsavingDocument(true);
              props.setTypeConfiguration(watchTypeConfiguration);
              props.onClick(data, "next");
            })}
          >
            Siguiente
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const UploadFileTemplateComponent = (props) => {
  const { control, register, watch } = useForm({ mode: "onBlur" });

  const [base64File, setBase64File] = useState(null);
  const [loadFile, setLoadFile] = useState(false);
  const [loadActiveInformation, setLoadActiveInformation] = useState(false);

  const [columnsTable, setColumnsTable] = useState([]);
  const [rowsTable, setRowsTable] = useState([]);

  const watchRdTypeProcessDocs = watch("rdTypeProcessDocs");
  const uploadMetadataBatchFileFCDataState = useSelector(
    (state) => state.UploadMetadataBatchFileFCData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      uploadMetadataBatchFileFCDataState.items !== undefined &&
      uploadMetadataBatchFileFCDataState.items !== null &&
      loadFile
    ) {
      setLoadFile(false);
      let respuestaValida =
        uploadMetadataBatchFileFCDataState.items?.Response?.Result.filter(
          (itemFilter) => itemFilter.State === false
        );
      if (respuestaValida?.length === 0) {
        props.HandleNextStep();
      } else {
        if (
          columnsTable?.filter((item) => item.accessor === "statusError")
            ?.length == 0
        ) {
          columnsTable.push({ Header: "", accessor: "statusError" });
        }
        if (
          rowsTable?.length > 0 &&
          rowsTable?.filter(
            (item) => item.status !== undefined && item.status !== null
          )?.length == 0
        ) {
          // Copia el arreglo original y agrega la nueva columna "precio"
          const newArray = rowsTable.map((item) => {
            let BuscarId = respuestaValida.find(
              (itemVal, index) =>
                itemVal.ClaveSolicitud === item.Clave_Solicitud_Consecutiva
            )?.ErrorToolTip;
            return {
              ...item,
              estatus: BuscarId === "" || BuscarId === undefined ? false : true,
              statusError:
                BuscarId === "" || BuscarId === undefined ? "" : BuscarId,
            };
          });
          setRowsTable(newArray);
        }
      }
    } else if (
      uploadMetadataBatchFileFCDataState.error !== undefined &&
      uploadMetadataBatchFileFCDataState.error !== null &&
      loadFile
    ) {
      setLoadFile(false);
      Alert({
        icon: "error",
        text: uploadMetadataBatchFileFCDataState.error,
        okbtntext: "Aceptar",
      });
    }
  }, [uploadMetadataBatchFileFCDataState]);

  useEffect(() => {
    if (
      columnsTable?.filter((item) => item.accessor === "estatus")?.length == 0
    ) {
      columnsTable.push({ Header: "Estatus", accessor: "estatus" });
    }
  }, [columnsTable]);

  useEffect(() => {
    if (
      rowsTable?.length > 0 &&
      rowsTable?.filter(
        (item) => item.estatus !== undefined && item.estatus !== null
      )?.length == 0
    ) {
      // Copia el arreglo original y agrega la nueva columna "precio"
      const newArray = rowsTable.map((item) => {
        return {
          ...item,
          estatus: Object.values(item).includes("") ? true : false,
        };
      });
      setRowsTable(newArray);
    }
  }, [rowsTable]);

  const handleDownloadTemplate = async () => {
    const fileService = FileService.getInstance();
    const responseExcel = await fileService.GetUploadMetadataTemplate(
      props.FileId
    );
    const url = window.URL.createObjectURL(new Blob([responseExcel.data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "File_" + props.FileId + "_Template.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleFileChange = async (file) => {
    if (file === undefined || file === null) {
      return;
    }
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
        defval: "",
        cellDates: true,
        dateNF: "dd/mm/yyyy",
        raw: false,
      });

      let arregloFiltrado = jsonData?.slice(1)?.map((subarreglo) => {
        // Filtrar los elementos no vacíos en cada subarreglo
        return subarreglo.filter((item) => item !== "");
      });
      setColumnsTable(
        arregloFiltrado[0].map((column, index) => ({
          Header: column?.replace(/ /g, "_"),
          accessor: column?.replace(/ /g, "_"),
        }))
      );
      setRowsTable(
        arregloFiltrado.slice(1).map((r) =>
          r.reduce((acc, x, i, index) => {
            let formatFecha = x.includes("/") ? new Date(x) : null;
            acc[arregloFiltrado[0][i]?.replace(/ /g, "_")] =
              x.includes("/") && formatFecha !== null
                ? formatFecha.toLocaleDateString("es-ES")
                : x;
            return acc;
          }, {})
        )
      );
    };
    reader.readAsBinaryString(file);
  };

  const updateDocumentInfo = async () => {
    try {
      const objetoModificado = { ...props.BodyInfo };
      objetoModificado.DocumentConfiguration.Capture = true;

      const fileService = FileService.getInstance();
      await fileService.UpdateDocumentCustom({
        Body: objetoModificado,
      });

      setLoadActiveInformation(true);
      props.setTemplateUploadOption(2);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      {!loadActiveInformation &&
      watchRdTypeProcessDocs === "chkCargarInformacion" &&
      base64File === null ? (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            paddingRight: "1em",
            paddingTop: "0em",
          }}
        >
          <IconButton
            xs={{ width: 60 }}
            title="Descargar plantilla"
            color="secondary"
            onClick={() => handleDownloadTemplate()}
          >
            <img alt="ImagenDescarga" src={iconExel} style={{ width: 45 }} />
          </IconButton>
        </Box>
      ) : null}

      {!loadActiveInformation && base64File === null ? (
        <CustomInputField
          register={register}
          inputType="radio"
          control={control}
          name="rdTypeProcessDocs"
          radioValues={[
            {
              value: "chkSolicitarInformacion",
              label: "Solicitar información",
            },
            {
              value: "chkCargarInformacion",
              label: "Cargar información",
            },
          ]}
          validations={{ required: true }}
        />
      ) : null}

      {watchRdTypeProcessDocs === "chkCargarInformacion" &&
      base64File === null ? (
        <UploadGenericControl
          Accept=".xlsx"
          onChange={(file, base64, extension, fileUrl) => {
            setBase64File(base64);
            handleFileChange(file);
            if (base64 !== null) {
              props.setTemplateUploadOption(1);
            } else {
              props.setTemplateUploadOption(null);
            }
          }}
        />
      ) : null}
      {columnsTable?.length > 0 && rowsTable?.length > 0 && (
        <GenericTable
          Columns={columnsTable}
          Data={rowsTable}
          onClickRow={() => {}}
          TotalRows={rowsTable.length}
          TotalPages={1}
          PageCurrent={1}
          onClickChangePage={() => {}}
          ShowPagination={false}
        />
      )}

      {loadActiveInformation && (
        <Box>
          <AlertMUI
            iconMapping={{
              warning: (
                <Icon
                  icon="mdi:information-variant-circle"
                  fontSize={30}
                  style={{ color: "#FBBF28" }}
                />
              ),
            }}
            severity="warning"
            sx={{
              mt: 1,
              backgroundColor: "secondary.warningAlert",
              ".MuiAlert-icon": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            Ingresa el rol encargado de capturar la información, si deseas que
            la información sea capturada por más roles, debes definirlos e
            ingresarlos en el campo de captura correspondiente, los roles pueden
            ser modificados en esta sección o al momento de crear la solicitud.
          </AlertMUI>
          <InformationFillingRequestComponent
            handleAddAnotherDocument={props.handleAddAnotherDocument}
            onReturn={() => {
              setLoadActiveInformation(false);
            }}
            HandleNextStep={() => {
              props.HandleNextStep();
            }}
          />
        </Box>
      )}

      {!loadActiveInformation && (
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          <Divider />
          {base64File && (
            <Grid item xs={12} sm={4}>
              <LoadingButton
                loading={loadFile}
                fullWidth
                variant="outlined"
                sx={{ height: 45, px: 10 }}
                disabled={
                  (watchRdTypeProcessDocs === "chkCargarInformacion" &&
                    loadFile) ||
                  !watchRdTypeProcessDocs == "chkSolicitarInformacion" ||
                  loadFile
                }
                onClick={() => {
                  setBase64File(null);
                  setColumnsTable(null);
                  setRowsTable(null);
                }}
              >
                Actualizar archivo
              </LoadingButton>
            </Grid>
          )}
          {rowsTable?.filter((item) => item.estatus === true)?.length >
          0 ? null : (
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10 }}
                onClick={() => {
                  if (watchRdTypeProcessDocs === "chkSolicitarInformacion") {
                    updateDocumentInfo();
                  } else {
                    let request = {
                      File_Id: props.FileId,
                      FileContentB64: base64File,
                    };
                    dispatch(UploadMetadataBatchFileFC(request));
                    setLoadFile(true);
                  }
                }}
                disabled={
                  watchRdTypeProcessDocs === null ||
                  (watchRdTypeProcessDocs === "chkCargarInformacion" &&
                    base64File === null) ||
                  !watchRdTypeProcessDocs == "chkSolicitarInformacion" ||
                  loadFile
                }
              >
                Siguiente
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

const InformationFillingRequestComponent = (props) => {
  const [templateMetadataList, setTemplateMetadataList] = useState([]);
  const [sendDataActive, setSendDataActive] = useState(false);
  const [newDocument, setNewDocument] = useState(false);
  const [listOptionsTypes, setListOptionsTypes] = useState([]);

  const listDocStacDataState = useSelector(
    (state) => state.NewFile?.documentsStack
  );

  const dispatch = useDispatch();
  const QuestionsTypesState = useSelector((state) => state.QuestionsTypesData);

  useEffect(() => {
    dispatch(GetQuestionTypes(""));
  }, []);

  useEffect(() => {
    let listFilterDocs = listDocStacDataState?.filter(
      (item) =>
        item.TypeDocumentoProcess === "DOCX con campos" ||
        item.TypeDocumentoProcess === "PDF editable"
    );
    if (listFilterDocs?.length > 0) {
      let listAddFields = [];
      listFilterDocs.forEach((itemDoc) => {
        itemDoc.DocumentData.DocumentFields?.forEach((itemFields, index) => {
          let newObject = {
            Id: index + 1,
            NombreCampo: itemFields.Code,
            NombreEtiqueta: itemFields.Name?.replace(/_|#|-|@|<>/g, (match) => match === '_' ? ' ' : ''),
            TipoDato: itemFields.DataType, /// Indica el tipo de dato default que quiere mosttarse en el control a pintar.
            Required: itemFields.Required,
            Role: itemFields.Role,
          };
          let objectExists = listAddFields.some(
            (item) => item.NombreCampo === newObject.NombreCampo
          );
          if (!objectExists) {
            listAddFields.push(newObject);
          }
        });
      });
      setTemplateMetadataList(listAddFields);
    }
  }, [listDocStacDataState]);

  useEffect(() => {
    if (
      QuestionsTypesState.items?.Response?.QuestionTypes !== undefined &&
      QuestionsTypesState.items?.Response?.QuestionTypes?.length > 0
    ) {
      let listUpdate = QuestionsTypesState.items?.Response?.QuestionTypes?.map(
        (item) =>
          item.Value === undefined ? { ...item, Value: item.Code } : item
      );
      setListOptionsTypes(listUpdate);
    }
  }, [QuestionsTypesState]);

  const columnsTable = [
    {
      Header: "Nombre de campo",
      accessor: "NombreCampo",
      Toltip: "Identificador que aparece en tu documento como metadato.",
    },
    {
      Header: "Etiqueta captura",
      accessor: "NombreEtiqueta",
      type: "Editable",
      Toltip: "Nombre que le aparecerá al usuario en su formulario.",
    },
    {
      Header: "Tipo de dato",
      accessor: "TipoDato",
      type: "Option",
      listOption: listOptionsTypes,
      Toltip: "Dato que deseas que sea solicitado al firmante.",
    },
    { Header: "Obligatorio", accessor: "select", type: "check" },
    {
      Header: "Rol de captura",
      accessor: "Role",
      type: "EditableRole",
      Toltip: "Rol asignado al capturista de datos.",
    },
  ];

  const handleSendData = async (data) => {
    const DataFormat = templateMetadataList?.map((item) => {
      return {
        Code: item.NombreCampo,
        Name: data[`EtiquetaCaptura_${item.NombreEtiqueta}`],
        DataType: data[`TipoDato_${item.NombreEtiqueta}`],
        Required: data[`CheckCaptura_${item.NombreEtiqueta}`],
        Role: data[`RoleUser_${item.NombreEtiqueta}`].toUpperCase().trim(),
      };
    });

    let listDocsMailmerge = listDocStacDataState?.filter(
      (item) =>
        item.TypeDocumentoProcess === "DOCX con campos" ||
        item.TypeDocumentoProcess === "PDF editable"
    );

    if (listDocsMailmerge?.length > 0) {
      let error = 0;

      await listDocsMailmerge.forEach(async (itemDoc, index) => {
        if (
          itemDoc.DocumentData === undefined ||
          itemDoc.DocumentData === null
        ) {
          return;
        }

        const DataFormatForDocument = DataFormat.filter((itemFormat) =>
          itemDoc.DocumentData.DocumentFields.some(
            (itemField) => itemField.Code === itemFormat.Code
          )
        );
        let request = {
          Document_Id: itemDoc.DocumentData.Id,
          UploadMetadata: false,
          Questions: DataFormatForDocument,
        };
        const fileService = FileService.getInstance();
        const responseUMD = await fileService.UpdateDocumentFieldsCustom(
          request
        );
        if (responseUMD?.Body?.ResponseCode != 0) {
          error++;
        }
      });

      if (error > 0) {
        Alert({
          icon: "error",
          text: "Ocurrió un error al intentar registrar los metadatos, intente nuevamente",
          okbtntext: "Aceptar",
        });
      } else {
        if (newDocument === true) {
          props.handleAddAnotherDocument();
        } else {
          props.HandleNextStep();
        }
      }
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      {QuestionsTypesState.loading ? (
        <Loader>Cargando datos...</Loader>
      ) : columnsTable?.length > 0 &&
        templateMetadataList?.length > 0 &&
        listOptionsTypes?.length > 0 &&
        (!sendDataActive || sendDataActive) ? (
        <GenericTable
          Columns={columnsTable}
          Data={templateMetadataList}
          onClickRow={() => {}}
          ShowPagination={false}
          sendDataActive={sendDataActive}
          HandleSendData={handleSendData}
          setSendDataActive={setSendDataActive}
        />
      ) : null}

      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        <Divider />
        <Grid item xs={12} sm={4}>
          <LoadingButton
            fullWidth
            variant="outlined"
            sx={{ height: 45, px: 10 }}
            onClick={() => {
              props.onReturn();
            }}
          >
            Atras
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 45, px: 10 }}
            onClick={() => {
              setSendDataActive(true);
            }}
          >
            Siguiente
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 45, px: 10 }}
            onClick={() => {
              setNewDocument(true);
              setSendDataActive(true);
            }}
          >
            Añadir otro documento
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const DetailTemplatesUploaded = (props) => {
  const { control, handleSubmit, register, formState } = useForm({
    mode: "onBlur",
  });

  const GetContentTemplateState = useSelector(
    (state) => state.GetContentTemplateData
  );
  const GetTemplatesActiveState = useSelector(
    (state) => state.GetTemplatesActiveData
  );
  const GetCategoriesTemplateState = useSelector(
    (state) => state.GetCategoriesTemplateData
  );

  const [resultCategories, setResultCategories] = useState([]);
  const [resultTemplates, setResultTemplates] = useState([]);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [sendDataFile, setSendDataFile] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetCategoriesTemplate("aa"));
  }, []);

  useEffect(() => {
    if (GetCategoriesTemplateState?.items) {
      const filesTable = GetCategoriesTemplateState?.items?.map((itemData) => {
        return {
          Value: itemData.Id,
          Name: itemData.Name,
        };
      });
      setResultCategories(filesTable);
    }
  }, [GetCategoriesTemplateState]);

  useEffect(() => {
    if (GetTemplatesActiveState?.items) {
      const filesTable = GetTemplatesActiveState?.items?.map((itemData) => {
        return {
          ...itemData,
          Checked: null,
        };
      });
      setResultTemplates(filesTable);
      dispatch(GetTemplatesActive(null, true));
    }
  }, [GetTemplatesActiveState]);

  useEffect(() => {
    console.log("GetContentTemplateState", GetContentTemplateState);
    if (GetContentTemplateState?.items) {
      let nameFile = GetContentTemplateState?.items?.m_Item2;
      let base64 = GetContentTemplateState?.items?.m_Item3
        ? `data:application/pdf;base64,${GetContentTemplateState?.items?.m_Item3}`
        : `data:application/pdf;base64,${GetContentTemplateState?.items?.m_Item1}`;

      let base64Original =
        nameFile.includes(".docx") || nameFile.includes(".doc")
          ? GetContentTemplateState?.items?.m_Item1
          : base64.replace("data:application/pdf;base64,", "");
      async function fetchData() {
        let blobUrl = await b64toUrl(base64);
        setDataFile({
          URL: blobUrl,
          Base64: base64Original,
          Name: nameFile,
        });
      }
      fetchData();
      if (sendDataFile) {
        setSendDataFile(false);
        props.handleConfirm(
          resultTemplates?.find((item) => item.Checked == true),
          base64Original
        );
      }
      dispatch(GetContentTemplate(null, true));
    }
  }, [GetContentTemplateState]);

  const GetContentData = (dataItem) => {
    dispatch(
      GetContentTemplate({
        template_Id: dataItem.Template_Id,
      })
    );
    props.setIdTemplate(dataItem.Template_Id);
    props.setUseTemplate(true);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CustomInputField
        inputType="select"
        control={control}
        register={register}
        name="cmbCategory"
        label="Categoría"
        validations={{ required: true }}
        defaultValue=""
        optionsList={resultCategories}
        error={!!formState.errors["cmbCategory"]}
        helperText={formState.errors["cmbCategory"]?.message}
        required={true}
        onSelect={(data) => {
          const { name, value } = data.target;
          dispatch(GetTemplatesActive(value));
        }}
      />
      <Typography variant="subtitle1" sx={{ fontWeight: 700, mt: 1 }}>
        Documentos
      </Typography>
      <Box>
        {resultTemplates.map((item, index) => (
          <DocumentListButton
            Id={`id_${index}`}
            TemplateId={item.Template_Id}
            Name={item.FileName}
            Checked={item.Checked}
            handleView={(data) => {
              GetContentData(item);
              setViewTemplate(true);
            }}
            handleSelect={(checked, TemplateId) => {
              const updatedItems = resultTemplates.map((item) =>
                item.Template_Id === TemplateId
                  ? { ...item, Checked: checked }
                  : { ...item, Checked: null }
              );
              setResultTemplates(updatedItems);
            }}
          />
        ))}
      </Box>
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        <Divider />
        <Grid item xs={12} sm={4}>
          <LoadingButton
            fullWidth
            variant="outlined"
            sx={{ height: 45, px: 10 }}
            onClick={(data) => {
              props.setdocSelectorStage("init");
            }}
          >
            Cancelar
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 45, px: 10 }}
            onClick={handleSubmit((data) => {
              // props.handleConfirm(resultTemplates?.find(item => item.Checked == true));
              setSendDataFile(true);
              GetContentData(
                resultTemplates?.find((item) => item.Checked == true)
              );
            })}
            disabled={
              resultTemplates?.filter((item) => item.Checked == true)?.length ==
              0
            }
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>

      <CustomModalDialog
        Open={viewTemplate}
        Title={`Plantilla - ${dataFile?.Name}`}
        OnClose={() => {
          setViewTemplate(false);
        }}
        Body={
          <VisorPDF
            parsingDoc={false}
            pdfUrl={dataFile?.URL}
            pdfName={dataFile?.Name}
            changeFile={() => {}}
          />
        }
      />
    </Box>
  );
};

const DocumentListButton = (props) => {
  return (
    <Box sx={{ mt: 2.5, width: "100%" }}>
      <DocButtonLoaded
        style={props.style}
        fullWidth
        sx={{ color: "#000" }}
        component={Box}
        id={props.Id}
      >
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Radio
              edge="start"
              checked={props.Checked}
              tabIndex={-1}
              disableRipple
              onChange={(event) => {
                const { checked } = event.target;
                let ValueChecked = checked ? true : null;
                props.handleSelect(ValueChecked, props.TemplateId);
              }}
            />

            <img
              style={{ width: 13, marginRight: 5 }}
              alt="imagenLogo"
              src={iconDocument}
            />
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: "14px !important",
              }}
            >
              {props.Name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <WhiteTooltip title="Ver plantilla">
              <IconButton
                onClick={() => {
                  props.handleView(props.TemplateId);
                }}
              >
                <VisibilityIcon sx={{ color: "black", fontSize: 26 }} />
              </IconButton>
            </WhiteTooltip>
          </Box>
        </Box>
      </DocButtonLoaded>
    </Box>
  );
};
