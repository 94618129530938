import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class FileService {
  static instance;

  constructor() {
    this.baseUrl = "file";
  }

  static getInstance() {
    if (!FileService.instance) {
      FileService.instance = new FileService();
    }
    return FileService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    let respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      let responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
        code: respObject?.ResponseCode
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseMassive(response) {
    let respObject = response.data.data;

    if (respObject.ResponseCode !== 0) {
      const responseObject = {
        message: `${respObject.Messages ?? respObject.Message} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject?.Body === undefined || respObject?.Body === null ? { Body: respObject } : respObject;
  }

  async getFiles(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetFilesCustom`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async getGlobalFiles(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetFilesGlobalCustom`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async getFilters(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetFiltersGlobal`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async getDynamicForm(data) {
    const response = await GenericService.post({
      endpoint: `product/GetProducts`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }

  async registerFile(data) {
    console.log('CREACION 0', data)
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterFileCustom`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    console.log('CREACION', response)
    const newFile = await this.handleResponseForceEncripted(response);
    return newFile;
  }

  async updateFileCustom(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/UpdateFileCustom`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const newFile = await this.handleResponseForceEncripted(response);
    return newFile;
  }

  async registerDocument(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterDocumentCustom`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const newDocument = await this.handleResponseForceEncripted(response);
    return newDocument;
  }

  async registerDocumentTemplateData(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterDocumentCustomByTemplate`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const newDocument = await this.handleResponseForceEncripted(response);
    return newDocument;
  }

  async getFile(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/GetFileCustomInfo`,
      // data: data,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const file = await this.handleResponseForceEncripted(response);
    return file;
  }

  async GetFileCustomInfoMasive(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/GetFileCustomInfoMasive`,
      data: data,
      // Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponseForceEncripted(response);
    return dataRes;
  }

  async getDocumentType(data) {
    const response = await GenericService.post({
      /*  endpoint: `product/GetDocumentTypesByProduct`, */
      endpoint: `FileCustom/GetDocumentsProductsCustom`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    console.log('responseForm', response)
    const productId = await this.handleResponseForceEncripted(response);
    return productId;
  }

  async getParticipants(data) {
    const response = await GenericService.post({
      endpoint: `user/GetUserByCorreroNombre`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const productId = await this.handleResponseForceEncripted(response);
    return productId;
  }

  async deleteFile(id) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/DisableFile`,
      data: Config.setParametersForceEncripted(id, false),
      config: Config.configAuth(),
    });
    const productId = await this.handleResponseForceEncripted(response);
    return productId;
  }

  async getComents(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/GetCommentsByDocument`,
      data: data,
      config: Config.configAuth(),
    });
    const comentsId = await this.handleResponseForceEncripted(response);
    return comentsId;
  }

  async publicComents(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterComment`,
      data: data,
      config: Config.configAuth(),
    });
    const comentsId = await this.handleResponseForceEncripted(response);
    return comentsId;
  }

  async getDownloadDocument(data) {
    const response = await GenericService.post({
      endpoint: `document/DownloadDocument`,
      data: Config.setParametersForceEncripted(data),
      config: Config.configAuth(),
    });
    const doc64 = await this.handleResponseForceEncripted(response);
    return doc64;
  }

  // DESCARGAR DOCUMENTO SIN EL TOKENB
  async getDownloadDocumentWithOuthT(data) {
    const response = await GenericService.post({
      endpoint: `document/DownloadDocumentWithOuthT`,
      data: Config.setParametersForceEncripted(data),
      // config: Config.configAuth(),
    });
    const doc64 = await this.handleResponseForceEncripted(response);
    return doc64;
  }

  //Retorna las versiones de los documentos para los CB
  async getDocumentVersions(data) {
    const response = await GenericService.post({
      endpoint: `document/GetVersionDocumentById`,
      data: data,
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }

  //Retornando las version de 64 bits del documento seleccionado ys u version
  async getVersionDoc(data) {
    const response = await GenericService.post({
      endpoint: `document/DownloadDocumentVersion`,
      data: data,
      config: Config.configAuth(),
    });
    const questions = await this.handleResponseForceEncripted(response);
    return questions;
  }

  //Para poder registrar usuarios dentro de un
  async registerParticipantOperador(data) {
    const response = await GenericService.post({
      endpoint: `user/RegisterUserRemote`,
      data: Config.setParametersForceEncripted(data, false),
      // config: Config.configAuth(),
    });
    const roles = await this.handleResponseForceEncripted(response);
    return roles;
  }

  async convertWordToPdf(data) {
    const response = await GenericService.post({
      endpoint: `document/ConvertDocumentWordToPdf`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async getDocumentsRequiredByFile(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/GetDocumentsProductsCustom`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async getIdsTemplates(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/GetDocumentTypeTemplates`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async getTemplateById(data) {
    const response = await GenericService.post({
      endpoint: `workflow/GetResourceDataById`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async downloadSection(data) {
    const response = await GenericService.post({
      endpoint: `document/DownloadSection`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async registerParticipantInFile(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterParticipant`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async removeParticipantInFile(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RemoveParticipant`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async createParticipant(data) {
    const response = await GenericService.post({
      endpoint: `user/RegisterUserCustom`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  // Traer datos del usuario con el ID
  async getUserActionCustom(data) {
    const response = await GenericService.post({
      endpoint: `user/GetUserActionCustom`,
      data: Config.setParametersForceEncripted(data, false),
      // config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  // Actualizar al usuario
  async updateUserCustom(data) {
    const response = await GenericService.post({
      endpoint: `user/UpdateUserCustom`,
      data: Config.setParametersForceEncripted(data, false),
      // config: Config.configAuth(),
    });
    const responseData = await this.handleResponseForceEncripted(response);
    return responseData;
  }

  async getTextEvidence(data) {
    const response = await GenericService.post({
      endpoint: `file/GetTextEvidence`,
      data: data,
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  // API PARA RETORNAR DATOS SIN LOGIN Y LOGEAR
  async getFileUserActionCustom(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/GetFileUserActionCustom`,
      data: Config.setParametersForceEncripted(data, false),
      // config: Config.configAuth(),
    });
    return response;
  }

  // API PARA REPORTE DE EXCEL
  async getFilesReport(data) {
    try {
      const response = await GenericService.get({
        endpoint: `file/GetFilesReport?reference=${data}`,
        config: Config.configDoc(),
      });
      return response;
    } catch (error) {
      return error
    }
  }

  // API PARA RETORNAR IMAGEN DE CODIGO QR
  async getFileQR(data) {
    const response = await GenericService.get({
      endpoint: `FileCustom/GetQRFileActionImage?file_Id=${data.idFile}&Authorization=${data.token}`,
      config: Config.configDoc(),
    });
    return response;
  }

  async ReorderParticipant(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/ReorderParticipant`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  async RegisterPrerequisites(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterPrerequisites `,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const pdf = await this.handleResponseForceEncripted(response);
    return pdf;
  }

  // API PARA ELIMINAR DOCUMENTOS DENTREO DEL EXPEDIENTE
  async DeleteDocument(data) {
    const response = await GenericService.post({
      endpoint: `document/DeleteDocument`,
      data: data,
      config: Config.configAuth(),
    });
    const dataRes = await this.handleResponseForceEncripted(response);
    return dataRes;
  }

  // API PARA SACAR DOCUMENTOS EN IMAGENES
  // async ConvertToDocumentToImage(data) {
  //   const response = await GenericService.get({
  //     endpoint: `document/ConvertDocToImagesProps2?document_Id=${data}`,
  //     config: Config.configAuth(),
  //   });
  //   return response;
  // };

  async ConvertToDocumentToImage(data) {
    const response = await GenericService.get({
      endpoint: `document/ConvertDocToImagesProps2?document_Id=${data}`,
      config: Config.configAuth(),
    });
    return response.data.data;
  };

  async GetConvertDocumentsToImages(data) {
    const response = await GenericService.post({
      endpoint: `document/GetConvertDocumentsToImages`,
      data: data,
      config: Config.configAuth(),
    });
    return response.data.data;
  }

  // API PARA PODER MANDAR LOS DATOS DE LOS DOCUMENTOS
  async AddTraceSignDocument(data) {
    const response = await GenericService.post({
      endpoint: `document/AddTraceSignDocument`,
      data: data,
      config: Config.configAuth(),
    });
    return response;
  }

  // API PARA OMITIR EL PASO DE REQUISITOS PREVIOS
  async DeleteTraceSignDocumen(data) {
    const response = await GenericService.get({
      endpoint: `document/DeleteTraceSignDocument?file_Id=${data}`,
      config: Config.configAuth(),
    });
    return response;
  };

  async IntegrateDocumentToSign(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/IntegrateDocumentToSing`,
      data: data,
      config: Config.configAuth(),
    });
    const reponseIntegrate = await this.handleResponseMassive(response);
    return reponseIntegrate;
  }

  async RegisterDocumentFields(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterDocumentFieldsData`,
      data: data,
      config: Config.configAuth(),
    });
    const reponseIntegrate = await this.handleResponseMassive(response);
    return reponseIntegrate;
  }

  async GetFilesCustomMasive(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetFilesCustomMasive`,
      data: { Body: data },
      config: Config.configAuth(),
    });
    const responseIntegrate = await this.handleResponseMassive(response);
    return responseIntegrate;
  }

  async RegisterBatchUploadSignedProcess(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/RegisterBatchUploadSignedProcess`,
      data: data,
      config: Config.configAuth(),
    });
    const reponseIntegrate = await this.handleResponseMassive(response);
    return reponseIntegrate;
  }

  /// Aplican para el flujo de carga masivo en un flujo individual
  /// INiCIO
  async UpdateDocumentCustom(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/UpdateDocumentCustom`,
      data: data,
      config: Config.configAuth(),
    });
    const reponseIntegrate = await this.handleResponseMassive(response);
    return reponseIntegrate;
  }

  async UpdateDocumentFieldsCustom(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/UpdateDocumentFieldsCustom`,
      data: data,
      config: Config.configAuth(),
    });
    const reponseIntegrate = await this.handleResponseMassive(response);
    return reponseIntegrate;
  }

  async GetUploadMetadataTemplate(data) {
    const response = await GenericService.get({
      endpoint: `FileCustom/GetUploadMetadataTemplate?file_Id=${data}`,
      config: Config.configDoc(),
    });
    return response;
  }

  async UploadMetadataBatchFile(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/UploadMetadataBatchFile`,
      data: data,
      config: Config.configAuth(),
    });
    const reponseIntegrate = await this.handleResponseMassive(response);
    return reponseIntegrate;
  }

  /// Aplican para el flujo de carga masivo en un flujo individual
  /// FIN


  // APIS PARA REGRESAR SOLICITUDES DE EXPEDIENTES
  async ResetFileSignStage(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/ResetFileSignStage`,
      data: data,
      config: Config.configAuth(),
    });
    return response;
  }

  async ResetFileNewFileStage(data) {
    const response = await GenericService.post({
      endpoint: `FileCustom/ResetFileNewFileStage`,
      data: data,
      config: Config.configAuth(),
    });
    return response;
  }

}
