import { Icon } from "@iconify/react";
import { Box, Button, CircularProgress, useTheme, Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersData } from "../../store/actions/UserActions";
import { UseImage } from "../../components/UseImage";
import moment from "moment";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import UserRegistration from "./UserRegistration";
import { InputField } from "../../components/InputField";
import { GenericTablePaginationAutomatic } from "../../components/GenericTablePaginationAutomatic";
import { Search } from "@mui/icons-material";
import { useForm } from "react-hook-form";

const columns = [
  {
    Header: "Correo electrónico",
    accessor: "emailUser",
  },
  {
    Header: "Nombre completo",
    accessor: "nameUser",
  },
  {
    Header: "Fecha alta",
    accessor: "validity",
  },
  {
    Header: "Rol",
    accessor: "rol",
  },
  {
    Header: "Estatus",
    accessor: "statusCheck",
  },
];

export default function Users() {
  const dispatch = useDispatch();
  const { control, watch } = useForm({
    mode: "onBlur",
  });
  const theme = useTheme();
  const usersData = useSelector((state) => state.UsersData);
  const formMethods = useForm();
  const [dataTableUsers, setDataTableUsers] = useState([]);
  const [userRegistration, setUserRegistration] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [reloadState, setReloadState] = useState(false);
  const [usersSelected, setUsersSelected] = useState();
  const watchinputSearch = watch("inputSearch");

  useEffect(() => {
    setDataTableUsers([]);
    dispatch(
      getUsersData({
        SearchText: "",
      })
    );
    setReloadState(false);
  }, [reloadState]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (usersData.items?.length > 0) {
      const filesTable = usersData.items.map((file) => {
        return {
          emailUser: file?.Email,
          nameUser: file?.Name
            ? `${file?.Name} ${
                file?.FatherLastName ? file?.FatherLastName : ""
              } ${file?.MotherLastName ? file?.MotherLastName : ""}`
            : file?.UserAlias,
          validity: moment(file.CreationDate).format("DD-MM-YYYY"),
          rolData: file?.Roles,
          FatherLastName: file?.FatherLastName,
          MotherLastName: file?.MotherLastName,
          Name: file?.Name,
          Id: file?.Id,
          PhoneNumber: file?.PhoneNumber,
          Active: file?.Active,
          Locked: file?.Locked,
          rol:
            file?.Roles[0]?.Name === "Operador" ? (
              <WhiteTooltip
                title={"Participante."}
                placement="top"
                enterTouchDelay={0}
              >
                <Icon
                  icon="solar:user-broken"
                  fontSize={30}
                  color={"#F69D53"}
                />
              </WhiteTooltip>
            ) : (
              <WhiteTooltip
                title={"Administrador."}
                placement="top"
                enterTouchDelay={0}
              >
                <Box
                  sx={{ display: "flex", width: 28, justifyContent: "left" }}
                >
                  <UseImage
                    type="Image"
                    width={28}
                    src={"IconAdmin.png"}
                    height={28}
                  />
                </Box>
              </WhiteTooltip>
            ),
          statusCheck:
            file?.Locked === true ? (
              <Box>
                <Icon
                  icon="material-symbols:circle"
                  fontSize={12}
                  style={{ marginRight: 3 }}
                  color={"#FBBF28"}
                />
                Pendiente de registro
              </Box>
            ) : file?.Locked === false && file?.Active === true ? (
              <Box>
                <Icon
                  icon="material-symbols:circle"
                  fontSize={12}
                  style={{ marginRight: 3 }}
                  color={"#08905C"}
                />
                Activo
              </Box>
            ) : file?.Locked === false && file?.Active === false ? (
              <Box>
                <Icon
                  icon="material-symbols:circle"
                  fontSize={12}
                  style={{ marginRight: 3 }}
                  color={"#C20E30"}
                />
                Inactivo
              </Box>
            ) : null,
        };
      });
      setDataTableUsers(filesTable);
    }
  }, [usersData.items]);

  const editUser = (user) => {
    setUsersSelected(user.original);
    setUserEdit(true);
    setUserRegistration(true);
  };

  const registerUsers = () => {
    setUserEdit(false);
    setUserRegistration(true);
  };

  const handleFilter = formMethods.handleSubmit(() => {
    setDataTableUsers([]);
    dispatch(
      getUsersData({
        SearchText: watchinputSearch,
      })
    );
  });

  if (userRegistration === true) {
    return (
      <UserRegistration
        setUserRegistration={setUserRegistration}
        userEdit={userEdit}
        setReloadState={setReloadState}
        usersSelected={usersSelected}
      />
    );
  }
  if (usersData.loading === true) {
  }
  return (
    <Fragment>
      <Grid container justifyContent={"flex-end"} alignContent={"flex-end"}>
        <Grid item xs={12} md={12} lg={8} sx={{ p: 1 }}>
          <InputField
            inputType="text"
            control={control}
            placeholder="Ingresa el nombre o correo del participante"
            name="inputSearch"
            posIcon="start"
            icon={
              <Search
                sx={{ color: theme.palette.primary.main }}
                fontSize="small"
              />
            }
            onEnter={(e) => {
              if (e.key === "Enter" && e.shiftKey === false) {
                return handleFilter();
              }
            }}
            sxInput={{
              "& .MuiOutlinedInput-input": {
                paddingLeft: 0,
              },
              "& .MuiOutlinedInput-root": {
                bgcolor: theme.palette.action.hover,
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
            sxLabel={{ color: "#888 !important" }}
          />
        </Grid>
        <Grid item lg={2} md={6} xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleFilter()}
            sx={{ height: 45, width: "95%", mt: 2 }}
          >
            Buscar
          </Button>
        </Grid>
        <Grid item lg={2} md={6} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => registerUsers()}
            sx={{ height: 45, width: "95%", mt: 2 }}
            startIcon={<Icon icon="gridicons:add-outline" />}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      {usersData.loading === true ? (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={60}
            sx={{ margin: "auto", display: "flex" }}
          />
        </Box>
      ) : (
        <Grid container alignContent={"center"} justifyContent={"center"}>
          <Grid sx={{ mt: 2 }} item lg={12} md={12} xs={12}>
            <GenericTablePaginationAutomatic
              Columns={columns}
              Data={dataTableUsers}
              TotalRows={dataTableUsers?.length}
              onClickRow={(e) => editUser(e)}
              ShowPagination={false}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
