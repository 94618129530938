import { GenericService } from "./GenericService";
import { Config } from "./Config";

export class GraphicsService {
    static instance;

    constructor() {
        this.baseUrl = "file";
    }

    static getInstance() {
        if (!GraphicsService.instance) {
            GraphicsService.instance = new GraphicsService();
        }
        return GraphicsService.instance;
    }

    async handleResponse(response) {
        console.log("GraphicsService----handleResponse----response: ", response)
        let respObject = response.data.data;
        if (respObject.ResponseCode != 0) {
            const responseObject = {
                message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
                    } ${respObject.statusText ? respObject.statusText : ""}`,
            };
            const error = respObject.Body || responseObject;
            return Promise.reject(error);
        }
        return { Body: respObject };
    }

    async GetGraphicsByLastMonth(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetFilesChartsLatestMonth?reference=${data}`,
            data: Config.setParametersForceEncripted(data, true),
            config: Config.configAuth(),
        });
        const files = await this.handleResponse(response);
        console.log('filesfiles', files)
        return files;
    }

    async GetGraphicsByApplicant(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetFilesChartsByApplicant?reference=${data}`,
            data: Config.setParametersForceEncripted(data, true),
            config: Config.configAuth(),
        });
        console.log(response)
        const files = await this.handleResponse(response);
        return files;
    }

    async GetGraphicsByTypeSign(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetFilesChartsByTypeSign?reference=${data}`,
            data: Config.setParametersForceEncripted(data, true),
            config: Config.configAuth(),
        });
        console.log(response)
        const files = await this.handleResponse(response);
        return files;
    }

    async GetGraphicsByStatusDeadLine(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetFilesByStatusDeadLine?reference=${data}`,
            data: Config.setParametersForceEncripted(data, true),
            config: Config.configAuth(),
        });
        console.log(response)
        const files = await this.handleResponse(response);
        return files;
    }

    async GetFilesChartCurrentMonth(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetFilesChartCurrentMonth?reference=${data}`,
            data: Config.setParametersForceEncripted(data, true),
            config: Config.configAuth(),
        });
        console.log(response)
        const files = await this.handleResponse(response);
        return files;
    }

    async GetFilesByStatus(data) {
        const response = await GenericService.post({
            endpoint: `${this.baseUrl}/GetFilesByStatus`,
            data: Config.setParametersForceEncripted(data, true),
            config: Config.configAuth(),
        });
        console.log(response)
        const files = await this.handleResponse(response);
        return files;
    }

}
