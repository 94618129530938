import { paymentsConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
};

export const GetPlansPayment = (state = initialState, action) => {
  switch (action.type) {
    case paymentsConstants.GET_PLANS_REQUEST:
      return {
        ...state,
        loading: true,
        plans: null,
        error: null,
      };

    case paymentsConstants.GET_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: action.payload,
        error: null,
      };

    case paymentsConstants.GET_PLANS_FAILURE:
      return {
        ...state,
        loading: false,
        plans: null,
        error: action.payload,
      };
    case paymentsConstants.PAYMENT_SELECTEDPLAN_USER_SUCCESS:
      return {
        ...state,
        planUserSeleted: action.payload,
      };
    case paymentsConstants.PAYMENT_RESETPLAN_USER:
      return {
        ...state,
        planUserSeleted: null,
      };
    default:
      return state;
  }
};

export const DataPaymentClient = (state = initialState, action) => {
  switch (action.type) {
    case paymentsConstants.PAYMENT_GETUSERDATA_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case paymentsConstants.PAYMENT_GETUSERDATA_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    case paymentsConstants.PAYMENT_GETUSERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentsConstants.PAYMENT_GETUSERDATA_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const DataPaymentGetBillings = (state = initialState, action) => {
  switch (action.type) {
    case paymentsConstants.PAYMENT_GETBILLING_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentsConstants.PAYMENT_GETBILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentsConstants.PAYMENT_GETBILLING_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const RegisterBillingInformation = (state = initialState, action) => {
  switch (action.type) {
    case paymentsConstants.PAYMENT_REGISTER_BILLING_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentsConstants.PAYMENT_REGISTER_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentsConstants.PAYMENT_REGISTER_BILLING_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    case paymentsConstants.PAYMENT_REGISTER_BILLING_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const GetRegimenFiscal = (state = initialState, action) => {
  switch (action.type) {
    case paymentsConstants.PAYMENT_GET_REGIMENFISCAL_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentsConstants.PAYMENT_GET_REGIMENFISCAL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentsConstants.PAYMENT_GET_REGIMENFISCAL_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const CancelSuscription = (state = initialState, action) => {
  switch (action.type) {
    case paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case paymentsConstants.PAYMENT_CANCEL_SUSCRIPCION_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};