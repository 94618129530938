import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import {
  Box,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTable } from "react-table";
import BackdropComponent from "../../components/BackDrop";
import { GetBilling, setTitleGral } from "../../store/actions";
import {  numeroFormateado } from "../../utils";
import moment from "moment/moment";

const columns = [
  {
    Header: "Número de pedido",
    accessor: "uid",
  },
  {
    Header: "Fecha",
    accessor: "billingDate",
  },
  {
    Header: "Tipo",
    accessor: "type",
  },
  
  {
    Header: "Planes",
    accessor: "planName",
  },
  {
    Header: "Monto",
    accessor: "amount",
  },
  /* {
    Header: "Acción",
    accessor: "status",
  }, */
];
const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function Billings() {
  const dispatch = useDispatch();
  const dataClient = useSelector((state) => state.DataPaymentClient);
  const dataBilling = useSelector((state) => state.DataPaymentGetBillings);
  const [data, setData] = useState([]);
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable({
      columns,
      data,
      title: "",
      initialState: { pageIndex: 0, hiddenColumns: ["stage"] },
    });

  useEffect(() => {
    dispatch(setTitleGral("Facturación"));
    dispatch(
      GetBilling({
        referenceData: "",
        clientId: dataClient?.items?.client_Id,
      })
    );
  }, []);

  useEffect(() => {
    if (dataBilling.items?.data) {
      setData(dataBilling.items?.data?.billings);
    }
  }, [dataBilling.items]);

  return (
    <Grid container alignContent={"center"} alignItems={"center"}>
      <BackdropComponent loading={dataBilling?.loading} />
      <Grid>
        <TableContainer component={Paper}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell sx={{ padding: 0.8, paddingLeft: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          ml: 1,
                          minWidth: column.Header === "Folio" ? 30 : 200,
                        }}
                      >
                        <Typography
                          variant="subtitle"
                          sx={{
                            fontSize: 14,
                            minWidth: 80,
                            fontWeight: 500,
                          }}
                        >
                          {column.render("Header")}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRowStyled
                    {...row.getRowProps()}
                    sx={{
                      borderBottom: "none !important",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          sx={{
                            padding: 1.5,
                            paddingLeft: 4,
                            borderBottom: "none",
                            fontSize: "14px !important",
                          }}
                        >
                          {cell.column.id === "amount" ? (
                            <>
                              {console.log(
                                "DINERO",
                                cell.render("Cell").props.value
                              )}
                              {numeroFormateado(
                                cell.render("Cell").props.value
                              )}
                            </>
                          ) : cell.column.id === "billingDate" ? (
                            moment(cell.render("Cell").props.value).format(
                              "DD-MM-YYYY"
                            )
                          ) : cell.column.id === "type" ? (
                            "Factura"
                          ) : (
                            cell.render("Cell")
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRowStyled>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
