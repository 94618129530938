import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocument, trueLoading } from "../../store/actions/ExpedienteActions";
import { useLocation, useNavigate } from "react-router-dom";
import icon_document from '../../assets/icon_document.svg'

export default function ListDocuments(props) {
  const dispatch = useDispatch();
  const fileItem = useSelector((state) => state.File);
  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.FlowState
  );
  const stateAprovatte = useSelector(
    (state) => state.File?.document?.DocumentData?.Validation?.Approved
  );
  const userData = useSelector(
    (state) => state.Authentication.items?.UserLoginData
  );
  const rolUser = useSelector(
    (state) => state.Authentication.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name
  );

  const [documentSeleted, setDocumentSeleted] = useState([]);

  const selectedDocument = (item) => {
    console.log("items selected: ", item)
    dispatch(setDocument(item));
    setDocumentSeleted(item);
    props.setCurrentDocumentPreview(null)
  };


  useEffect(() => {
    if (props.currentDocumentPreview !== undefined && props.currentDocumentPreview !== null && props.currentDocumentPreview !== "") {
      selectedDocument([])
    }
  }, [props.currentDocumentPreview]);


  const renderCheckList = () => {
    const participant = fileItem?.item?.Participants?.filter(
      (part) => part.UserId === userData.Id && (part.Check === true || part.Reviewer === true)
    );
    if (participant?.length === 0) {
      return null;
    }
    const checkListDocs = fileItem?.item?.Documents?.filter(
      (doc) => doc.Check === true
    );
    if (checkListDocs?.length === 0) {
      return null;
    }

    return (
      <ButtonList
        docuemnts={checkListDocs}
        type={"REVISION"}
        status={stateAprovatte}
        documentSeleted={documentSeleted}
        selectedDocument={selectedDocument}
        setCurrentDocumentPreview={props.setCurrentDocumentPreview}
        currentDocumentPreview={props.currentDocumentPreview}
      />
    );
  };


  const stagesDocumentList = () => {
    switch (stageFileFlow) {
      case "Nuevo expediente":
        return renderCheckList()
      case "Revisión de documentos":
        return renderCheckList();
      case "Notificacion De Asignacion de Revisión":
        return renderCheckList();
      case "Revisión de Administrador":
        return renderCheckList();
      case "Asignando solicitud de revisión":
        return renderCheckList();
      case "Firma de documentos":
        return null
      case "Notificacion De Asignacion de Firma":
        return null
      case "Asignando solicitud de firma":
        return null
      case "Notificacion De Finalización de Firma":
        return null
      case "OTP Evidencias firma de documentos":
        return null
      case "Validación de evidencias":
        return null
      case "FinalizacionXml":
        return renderCheckList()
      case "Finalizado":
        return renderCheckList()
      case "Carga a Docflow":
        return renderCheckList()
      default:
        break;
    }
  };

  const renderCheckListAdmin = () => {
    const signCheckDocs = fileItem?.item?.Documents.filter(
      (doc) => doc.Check === true
    );

    if (signCheckDocs?.length === 0) {
      return null;
    }

    return (
      <ButtonList
        docuemnts={signCheckDocs}
        type={"REVISAR"}
        status={stateAprovatte}
        documentSeleted={documentSeleted}
        selectedDocument={selectedDocument}
        setCurrentDocumentPreview={props.setCurrentDocumentPreview}
        currentDocumentPreview={props.currentDocumentPreview}
      />
    );
  };

  return (
    <Fragment>
      <Box sx={{ mt: 1, mb: 1 }}>
        {rolUser === 'Administrador' || rolUser == 'Super usuario' ? (
          <>
            {renderCheckListAdmin()}
          </>
        ) :
          stagesDocumentList()
        }
      </Box>
    </Fragment>
  );
}

const ButtonList = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ruta = location.pathname.includes('/inbox/file-information-search');
  const fileState = useSelector((state) => state.File);
  const FileFlow = useSelector((state) => state.FileFlow);
  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.FlowState
  );
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );

  const filePrerequisitesState = useSelector((state) => state.File?.item?.Prerequisites?.ApprovalPrerrequisitesStatusDocument);
  const active = (item) => {
    return props?.documentSeleted?.DocumentData?.Id !== undefined ?
      props?.documentSeleted?.DocumentData?.Id === item?.DocumentData?.Id :
      props?.documentSeleted?.DocumentTypeId === item?.DocumentTypeId;
  };
  const userParticipante = fileState.item?.Participants?.filter(
    (use) => use?.UserId === userData?.Id
  );
  const statusAdmin =
    userData?.Username === fileState?.item?.FileData?.CreationUser;

  const stagesDocumentList = () => {
    switch (stageFileFlow) {
      case "Nuevo expediente":
        return statusAdmin ? false : true;
      case "Revisión de documentos":
        return statusAdmin ? false : true;
      case "Notificacion De Asignacion de Revisión":
        return statusAdmin ? false : true;
      case "Revisión de Administrador":
        return false;
      case "Nom 151":
        return statusAdmin ? false : true;
      case "Asignando solicitud de revisión":
        return statusAdmin ? false : true;
      case "Firma de documentos":
        return false;
      case "Notificacion De Asignacion de Firma":
        return statusAdmin ? false : true;
      case "Asignando solicitud de firma":
        return statusAdmin ? false : true;
      case "Notificacion De Finalización de Firma":
        return statusAdmin ? false : true;
      case "OTP Evidencias firma de documentos":
        return false;
      case "Validación de evidencias":
        return false;
      case "FinalizacionXml":
        return false;
      case "Finalizado":
        return false;
      default:
        break;
    }
  };

  useEffect(() => {
    const ultimoDoc = props?.docuemnts?.pop();

    if (
      ultimoDoc?.DocumentData?.Validation?.Status === "Revisado" ||
      ultimoDoc?.DocumentData?.Validation?.Status === "Firmado"
    ) {
      if (stagesDocumentList() === true) {
        if (!ruta) {
          if (FileFlow?.file_flow?.IsOk === true) {
            if (!userParticipante[0]?.IsCheckedAllDocuments) {
              setTimeout(() => {
                dispatch(trueLoading());
                navigate("/inbox/files");
              }, 1500);
            }
          }
        }
      }
    }
  }, [props.selectedDocument]);


  useEffect(() => {
    if (props.currentDocumentPreview == null) {
      props.setCurrentDocumentPreview(null)
    }
  }, [props.currentDocumentPreview]);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          Documentos por {props.type === "FIRMA" ? "firmar" : "revisar"}
        </Typography>
      </Box>

      {props.docuemnts?.map((item, index) => (
        <ButtonAS
          item={item}
          document
          active={active}
          index={index}
          documents={props.docuemnts}
          selectedDocument={props.selectedDocument}
          type={props.type}
        />
      ))}
      
      {
        console.log('filePrerequisitesState', filePrerequisitesState)
      }

      {filePrerequisitesState?.length > 0 || props.currentDocumentPreview || props.currentDocumentPreview === null ? <>
        <Box sx={{ mb: 1, mt: 3 }}>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Requisitos previos
          </Typography>
          <Typography variant="subtitle" sx={{ fontWeight: 400, fontSize: '10px !important', lineHeight: '10px !important' }}>
            Estos documentos son los requisitos previos que fueron solicitados para iniciar esta solicitud, en este apartado solo puedes visualizarlos.
          </Typography>
        </Box>
        {filePrerequisitesState?.map((item, index) => (
          <ButtonReqPrevios
            item={item}
            documents={filePrerequisitesState}
            setCurrentDocumentPreview={props.setCurrentDocumentPreview}
            currentDocumentPreview={props.currentDocumentPreview}
          />
        ))}
      </> : null}
    </>
  );
};

const ButtonAS = ({
  item,
  active,
  documents,
  index,
  selectedDocument,
  type,
}) => {
  const location = useLocation();
  const ruta = location.pathname.includes("/inbox/file-information-search");

  let stateAprovatte = item?.DocumentData?.Validation?.Approved;

  useEffect(() => {
    if (ruta) {
      selectedDocument(documents[0]);
      active(documents[0]);
    } else {
      for (let i = 0; i < documents.length; i++) {
        if (!documents[i].DocumentData?.Validation?.Approved) {
          selectedDocument(documents[i]);
          active(documents[i])
        }
      }
    }

  }, []);

  return (
    <Button
      sx={(theme) => ({
        color: stateAprovatte
          ? "#28A745"
          : active(item)
            ? theme.palette.secondary.main80
            : "#C1C1C1",
        background: stateAprovatte
          ? "#F0FDF3"
          : active(item)
            ? "#F2F2F2"
            : "#F7F8FA",
        width: "90%",
        borderRadius: "0%",
        borderStyle: "solid",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        padding: 0,
        border: active(item) ? '3px solid #848484' : 'none',
        minHeight: 70,
        marginBottom: '1em'
      })}
      onClick={() => selectedDocument(item)}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: '100%' }}>
        <Box sx={{
          display: 'flex', backgroundColor: active(item)
            ? '#D6D6D6' : 'rgba(209, 209, 209, 0.24)', justifyContent: 'center',
          borderRight: active(item) ? '3px solid #848484' : 'none', minHeight: 72, width: '15%', minWidth: '15%', alignItems: 'center'
        }}>
          <spam
            style={{
              borderWidth: 1,
              display: "block",
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '28px',
              lineHeight: '130%',
              color: '#FFFFFF',
            }}
          >
            {index + 1}
          </spam>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Box sx={{ flexGrow: 8, ml: 1 }}>
            <Typography
              align="left"
              sx={(theme) => ({
                fontSize: "12px !important",
                color: stateAprovatte
                  ? "#000"
                  : active(item)
                    ? "#000"
                    : theme.palette.secondary.main80,
                // ACOMODAR AQUI LOS COLORES
              })}
            >
              Documento
            </Typography>
            <Typography
              align="left"
              sx={(theme) => ({
                fontSize: "12px !important",
                textTransform: 'capitalize',
                color: stateAprovatte
                  ? "#28A745"
                  : active(item)
                    ? "#000"
                    : theme.palette.secondary.main80,
              })}
            >
              {item.FileName}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              ml: 1,
            }}
          >
            <ArrowForwardIosIcon
              sx={(theme) => ({
                fontSize: 25,
                color: stateAprovatte
                  ? "#28A745"
                  : active(item)
                    ? "#000"
                    : theme.palette.secondary.main80,
              })}
            />
          </Box>
        </Box>
      </Box>
    </Button>
  );
};

const ButtonReqPrevios = ({
  item,
  documents,
  setCurrentDocumentPreview,
  currentDocumentPreview
}) => {
  const [documentCurrentSelected, setDocumentCurrentSelected] = useState([]);

  useEffect(() => {
    if (currentDocumentPreview !== undefined && currentDocumentPreview !== null && currentDocumentPreview !== "") {
      setDocumentCurrentSelected({ Document_Id: currentDocumentPreview })
    }
  }, [currentDocumentPreview]);


  let active = (item) => {
    return documentCurrentSelected?.Document_Id === item?.Document_Id;
  };

  return (
    <Button
      sx={(theme) => ({
        color: active(item)
          ? theme.palette.secondary.main80
          : "#C1C1C1",
        background: active(item)
          ? "#F2F2F2"
          : "#F7F8FA",
        width: "90%",
        borderRadius: "0%",
        borderStyle: "solid",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        padding: 0,
        border: active(item) ? '3px solid #848484' : 'none',
        minHeight: 70,
        marginBottom: '1em'
      })}
      onClick={() => {
        setDocumentCurrentSelected(item)
        setCurrentDocumentPreview(item?.Document_Id)
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: '100%' }}>
        <Box sx={{
          display: 'flex', justifyContent: 'center', backgroundColor: active(item)
            ? '#D6D6D6' : 'rgba(209, 209, 209, 0.24)', borderRight: active(item) ? '3px solid #848484' : 'none', minHeight: 70, minWidth: '15%', alignItems: 'center'
        }}>
          <img alt="imagenDoc" style={{ width: 23, height: 31 }} src={icon_document} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Box sx={{ flexGrow: 8, ml: 1 }}>
            <Typography
              align="left"
              sx={(theme) => ({
                fontSize: "12px !important",
                color: active(item)
                  ? "#000"
                  : theme.palette.secondary.main80,
                // ACOMODAR AQUI LOS COLORES
              })}
            >
              Documento
            </Typography>
            <Typography
              align="left"
              sx={(theme) => ({
                fontSize: "12px !important",
                color: active(item)
                  ? "#000"
                  : theme.palette.secondary.main80,
              })}
            >
              {item.DocumentName}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              ml: 1,
            }}
          >
            <ArrowForwardIosIcon
              sx={(theme) => ({
                fontSize: 25,
                color: active(item)
                  ? "#000"
                  : theme.palette.secondary.main80,
              })}
            />
          </Box>
        </Box>
      </Box>
    </Button>
  );
};
