import React, { useEffect, useState } from "react";
import {
  Box,
  styled,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Grid,
  AccordionDetails as AccDet,
  Accordion as Acc,
  AccordionSummary as AccSum,
  Tooltip,
  tooltipClasses,
  autocompleteClasses,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { InputField } from "../../components/InputField";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FileService } from "../../services/FileService";
import { emailPattern } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  createFile,
  GetCategoriesTemplate,
  GetDataPlanClient,
  setForm,
  updateFileCustom,
} from "../../store/actions";
import { ExpandMore } from "@mui/icons-material";
import { CustomModalAlert } from "../../components/CustomModalAlert";
import { CustomInputField } from "../../components/CustomInputField";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const SpinnerContainer = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: 900,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 300,
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "2px solid red",
  borderBottomColor: theme.palette.primary.borderASummary,
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));
const AccordionDetails = styled(AccDet)(({ theme }) => ({
  padding: 20,
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

export const ConfirmFileTab = (props) => {
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const environmentCtrlSigned = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.EnviromentVars
        ?.IsActiveSignatureZones
  );

  const useClient = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.Email
  );

  const fileAuthentication = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.EnviromentVars
  );
  const regexNombreSolicitud =
    /^[A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ\/\\_-\s.]*$|^[^A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ\/\\_-\s.]$/;
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    watch,
    register,
    getValues,
  } = useForm({ mode: "onSubmit" });

  const [fileTypes, setFileTypes] = useState(null);
  const [fetching, setfetching] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [resultCategories, setResultCategories] = useState([]);
  const [openAlertGps, setOpenAlertGps] = useState(false);
  const dispatch = useDispatch();
  const fileState = useSelector((state) => state.NewFile);

  const fileEdit = useSelector((state) => state.File.item);
  const fileEditLoading = useSelector((state) => state.File);

  const disabledNotificationSignerDoc = useSelector(
    (state) =>
      state.Authentication.items?.UserLoginData?.EnviromentVars
        ?.ActiveCheckNotifySignedDocuments
  );

  const GetCategoriesTemplateState = useSelector(
    (state) => state.GetCategoriesTemplateData
  );

  const watchSign = watch("signatureType");
  const currentFileName = watch("fileName");

  const [productSeleted, setProductSeleted] = useState(null);

  const getTypeField = (field) => {
    switch (field.QuestionType) {
      case "Fecha":
        return (
          <Box sx={{ mt: 2, mb: 1 }}>
            <InputField
              key={field.Name}
              inputType="date"
              tooltipText={field.Description + "."}
              control={control}
              label={field.Name}
              name={field.Name.replaceAll(" ", "")}
              validations={{ required: field.Required }}
              error={!!formState.errors[field.Name.replaceAll(" ", "")]}
              defaultValue={""}
              minDate={new Date()}
            />
          </Box>
        );
      case "Phone":
        return (
          <InputField
            key={field.Name}
            inputType="phone"
            control={control}
            label={field.Name}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: field.Required }}
            error={!!formState.errors[field.Name.replaceAll(" ", "")]}
          />
        );
      case "Email":
        return (
          <InputField
            key={field.Name}
            inputType="text"
            control={control}
            label={field.Name}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: field.Required, pattern: emailPattern }}
            error={!!formState.errors[field.Name.replaceAll(" ", "")]}
          />
        );
      case "Radio":
        return (
          <InputField
            key={field.Name}
            label={field.Name}
            iconSpam={field.Required}
            inputType="radio"
            control={control}
            radioValues={field.Options.map((item) => ({
              value: item.Value,
              label: item.Option,
            }))}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: field.Required }}
          />
        );
      case "Currency":
        return (
          <InputField
            key={field.Name}
            label={field.Name}
            iconSpam={true}
            inputType="currency"
            control={control}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: field.Required }}
          />
        );
      case "Number":
        return (
          <InputField
            key={field.Name}
            label={field.Name}
            iconSpam={true}
            inputType="number"
            control={control}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: field.Required }}
          />
        );
      case "Combo":
        const dataNew = field.Options.map((item) => ({
          Id: item.Value,
          Name: item.Option,
        }));
        return (
          <InputField
            key={field.Name}
            inputType="select"
            control={control}
            label={field.Name}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: true }}
            error={!!formState.errors["fileType"]}
            selectField="Id"
            optionsList={dataNew}
          />
        );
      case "Texto":
      case "TextoMultilinea":
      default:
        return (
          <InputField
            key={field.Name}
            inputType="text"
            tooltipText={field.Description + "."}
            iconSpam={field.Name === "Nombre del solicitante" ? true : false}
            control={control}
            placeholder={field.Description}
            multiline={field.QuestionType === "TextoMultilinea"}
            minRows={field.QuestionType === "TextoMultilinea" ? 4 : 1}
            label={field.Name}
            name={field.Name.replaceAll(" ", "")}
            validations={{ required: field.Required }}
            error={!!formState.errors[field.Name.replaceAll(" ", "")]}
          />
        );
    }
  };

  const getForm = async () => {
    try {
      setfetching(true);
      const fileService = FileService.getInstance();
      const response = await fileService.getDynamicForm("");
      if (response.Body.length > 0) {
        setFileTypes(response.Body);
      } else {
        setFileTypes(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setfetching(false);
    }
  };

  console.log("setFileTypes", fileTypes);

  const submitForm = async (data) => {
    if (fileState.editStack || fileEdit?.FileData?.Id || folioIdSolicitud) {
      dispatch(updateFileCustom(data, props.handleNextStep));
    } else {
      dispatch(createFile(data, props.handleNextStep));
      dispatch(
        GetDataPlanClient({
          referenceData: "",
          clientId: null,
          email: useClient,
        })
      );
    }
  };

  const onSubmit = (data) => {
    const {
      fileName,
      validity,
      OTP,
      videoCheck,
      NOM151,
      CheckGPS,
      signatureType,
      ctrlPreFirmado,
      notificaRevisores,
      notificaFirmantes,
      notificationSignerDoc,
      ctrlReqPrevios,
      cmbTipoTramite,
      ...questions
    } = data;

    console.log("datadata", watchSign, data);

    const dataList = fileState.form.QuestionDefinitions.map((questionProd) => {
      const match = Object.keys(questions).find(
        (item) => item === questionProd.Name.replaceAll(" ", "")
      );
      if (match) {
        return {
          GroupName: questionProd.QuestionGroup,
          Name: questionProd.Name,
          Value: questions[questionProd.Name.replaceAll(" ", "")],
        };
      }
      return null;
    });
    localStorage.setItem("ctrlReqPrevios", ctrlReqPrevios);
    sessionStorage.setItem(
      "ctrlSolicitudMasiva",
      JSON.stringify(
        productSeleted?.Code === "Masiva" ||
          fileEdit?.FileData?.Code === "Masiva"
          ? true
          : false
      )
    );

    let tipoFirma =
      signatureType == undefined || signatureType == null || signatureType == ""
        ? watchSign
        : signatureType;

    let newFecha = dataList.find(
      (item) => item.Name == "Fecha de vigencia documental"
    ).Value;

    if(cmbTipoTramite != undefined && cmbTipoTramite != null && cmbTipoTramite != '' ){
    const objetFiltrado = resultCategories.find((item) =>item.Id == cmbTipoTramite)
    let newObjetoTramite = {
      GroupName: "Expediente",
      Name: "Tipo de trámite",
      Value: objetFiltrado.Name,
    };
    dataList.push(newObjetoTramite)
  }

    let body = {
      FileConfiguration: {
        Name: fileName,
        ProductId: fileState.form.Id,
        Validity: newFecha,
        MetadataList: dataList,
        Nom151: NOM151,
        CheckGPS: CheckGPS,
        SignatureZones: environmentCtrlSigned === false ? false : true,
        SignatureOptions:
          productSeleted?.Code === "Masiva" ||
          fileEdit?.FileData?.Code === "Masiva"
            ? null
            : {
                OTP:
                  tipoFirma === "simpleSign"
                    ? true
                    : tipoFirma === "advancedSign"
                    ? OTP
                    : false,
                VideoRecording: videoCheck,
                AutographSignature: null,
                PSC: false,
                SignatureType:
                  tipoFirma === "simpleSign"
                    ? "Simple"
                    : tipoFirma === "advancedSign"
                    ? "Avanzada"
                    : null,
                AudioValidate:
                  fileAuthentication?.ConfigurationVideoValidates
                    ?.ValidateLocked,
              },
        Publish: true,
        Prerequisites: {
          ActivePrerequisites: ctrlReqPrevios === "Si" ? true : false,
        },
        Participants: null,
        NotificationOptions: {
          NotifyAllUserCheck: notificaRevisores,
          NotifyAllUserSign: notificaFirmantes,
          NotifySignedDocuments: notificationSignerDoc,
          ActiveCheckNotifySignedDocuments: disabledNotificationSignerDoc,
        },
      },
    };

    let editForm = {
      ...body,
      FileId: fileEdit?.FileData?.Id,
    };

    console.log("BODY", body);

    if (fileState.editStack || folioIdSolicitud) {
      console.log("BODY ---", editForm);
      submitForm(editForm);
      console.log("CASO EDICION 0");
    } else {
      console.log("CASO EDICION 0 2");
      submitForm(body);
    }
  };

  const traerDatos = () => {
    console.log("datadata 01");

    let getName =
      currentFileName == undefined ||
      currentFileName === null ||
      currentFileName == ""
        ? fileEdit?.Name
        : currentFileName;
    // setValue("fileName", getName);

    let firma = fileEdit?.SignatureOptions?.SignatureType;
    setValue(
      "signatureType",
      firma === "Avanzada"
        ? "advancedSign"
        : firma === "Simple"
        ? "simpleSign"
        : "singParticipant"
    );
    setValue(
      "ctrlReqPrevios",
      fileEdit?.Prerequisites?.ActivePrerequisites ? "Si" : "No"
    );
    /* setValue("NOM151", fileEdit?.Nom151);
    setValue("CheckGPS", fileEdit?.CheckGPS); */
    /*  setValue("notificaRevisores", fileEdit.NotificationOptions.NotifyAllUserCheck);
    setValue("notificaFirmantes", fileEdit.NotificationOptions.NotifyAllUserSign);
    setValue("notificationSignerDoc", fileEdit.NotificationOptions.NotifySignedDocuments); */
  };

  useEffect(() => {
    getForm();
    dispatch(GetCategoriesTemplate("aa"));
  }, []);

  useEffect(() => {
    if (GetCategoriesTemplateState?.items) {
      setResultCategories(GetCategoriesTemplateState?.items);
    }
  }, [GetCategoriesTemplateState]);

  useEffect(() => {
    getValues();
  }, [getValues]);

  useEffect(() => {
    if (fileEdit !== null && fileEdit !== undefined && fileEdit !== "") {
      setValue("fileName", fileEdit?.Name);
      setValue("validity", new Date(fileEdit?.Validity));

      fileEdit.MetadataList.map((data) => {
        if (data.Name === "Nombre del solicitante") {
          setValue("Nombredelsolicitante", data.Value);
        } else if (data.Name === "Fecha de vigencia documental") {
          if (data.Value !== undefined && data.Value !== null) {
            console.log(" data.Value", data.Value);
            setValue("Fechadevigenciadocumental", new Date(data.Value));
          }
        } else {
          setValue(data.Name?.replace(/\s/g, ""), data.Value);
        }
      });

      let firma = fileEdit?.SignatureOptions?.SignatureType;
      setValue(
        "signatureType",
        firma === "Avanzada"
          ? "advancedSign"
          : firma === "Simple"
          ? "simpleSign"
          : "singParticipant"
      );

      setValue("fileType", fileEdit?.ProductId);
      const file = fileTypes?.find((fl) => fl.Id === fileEdit?.ProductId);
      dispatch(setForm(file));

      setValue(
        "ctrlReqPrevios",
        fileEdit?.Prerequisites?.ActivePrerequisites ? "Si" : "No"
      );
      setValue("NOM151", fileEdit?.Nom151);
      setValue("CheckGPS", fileEdit?.CheckGPS);

      setValue(
        "notificaRevisores",
        fileEdit.NotificationOptions.NotifyAllUserCheck
      );
      setValue(
        "notificaFirmantes",
        fileEdit.NotificationOptions.NotifyAllUserSign
      );
      setValue(
        "notificationSignerDoc",
        fileEdit.NotificationOptions.NotifySignedDocuments
      );

      if (firma === "Avanzada" || firma === "Simple") {
        setValue("videoCheck", fileEdit?.SignatureOptions?.VideoRecording);
        setValue("OTP", fileEdit?.SignatureOptions?.OTP);
        console.log("Ajuste tres");
      }
    } else {
      setValue("validity", null);
      setValue("NOM151", true);

      setValue("notificaRevisores", true);
      setValue("notificaFirmantes", true);
      setValue("notificationSignerDoc", true);

      setValue("CheckGPS", true);
    }
  }, [fileEdit, fileTypes, fileEditLoading.loading]);

  useEffect(() => {
    if (watchSign === "simpleSign") {
      setValue("OTP", true);
    }
    if (
      fileEdit?.SignatureOptions != undefined &&
      fileEdit?.SignatureOptions != null &&
      watchSign == undefined
    ) {
      traerDatos();
    } else if (
      fileEdit?.SignatureOptions != undefined &&
      fileEdit?.SignatureOptions != null
    ) {
      if (watchSign !== "singParticipant") {
        setValue("OTP", fileEdit?.SignatureOptions?.OTP);
        setValue("videoCheck", fileEdit.SignatureOptions.VideoRecording);
      }
    }
  }, [watchSign, fileEdit]);

  if (fetching) {
    return (
      <SpinnerContainer>
        <CircularProgress size={60} />
      </SpinnerContainer>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <RootStyle>
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="titulo">
              Información de la solicitud
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            <Typography variant="subtitle" sx={{ mb: 2, display: "block" }}>
              Captura la información que te ayudará a identificar tu solicitud
              durante el proceso de revisión, firmado y consulta.
            </Typography>
            {fileTypes && (
              <>
                <CustomInputField
                  inputType="inputRegex"
                  required={true}
                  iconSpam={false}
                  regexData={regexNombreSolicitud}
                  control={control}
                  register={register}
                  label={<>Nombre de la solicitud</>}
                  name="fileName"
                  placeholder="Introduce el nombre con el que identificarás la solicitud"
                  helperText={
                    "El nombre de la solicitud solo puede contener: Mayúsculas, minúsculas, puntos, espacios, acentos, diagonal (se reemplaza por guión bajo) y guiones."
                  }
                  error={!!formState.errors["fileName"]}
                />
                <InputField
                  inputType="select"
                  tooltipText="Clasificación que tendrá el contenido del expediente."
                  control={control}
                  label={
                    <>
                      Tipo de solicitud{" "}
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    </>
                  }
                  placeholder="Selecciona el tipo de solicitud"
                  name="fileType"
                  disabled={fileState.editStack}
                  validations={{ required: true }}
                  error={!!formState.errors["fileType"]}
                  selectField="Id"
                  optionsList={fileTypes}
                  onSelect={(e) => {
                    const fileId = e.target.value;
                    const file = fileTypes.find((fl) => fl.Id === fileId);
                    setProductSeleted(file);
                    dispatch(setForm(file));
                  }}
                />
              </>
            )}
            {fileState.form?.QuestionDefinitions.map(getTypeField)}
            {fileState.form && (
              <>
                {productSeleted?.Code === "Masiva" ||
                fileEdit?.FileData?.Code === "Masiva" ? null : (
                  <>
                    <Typography variant="h6" sx={{ mb: 1, mt: 1 }}>
                      Tipo de firma<spam style={{ color: "#C20E30" }}> *</spam>
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{ mb: 1, display: "flex", alignItems: "center" }}
                    >
                      Selecciona el tipo de firma que incluirás en los
                      documentos.
                    </Typography>

                    <InputField
                      inputType="radio"
                      control={control}
                      disabled={fileState.editStack}
                      name="signatureType"
                      radioValues={[
                        {
                          value: "simpleSign",
                          label: "Firma Autógrafa en Mensaje de Datos",
                          tooltipText:
                            "Los participantes deberán usar una representación gráfica (grafo) que será plasmado en los documentos a firmar, se agregará atribución legal mediante el uso de una firma digital verificado por una Contraseña de un solo uso (OTP).",
                        },
                        {
                          value: "advancedSign",
                          label: "Firma Electrónica Avanzada",
                          tooltipText:
                            "Los participantes deberán usar su Firma Electrónica Avanzada emitida por el SAT, durante el proceso de firmado se validará la vigencia y validez del mismo. Se requiere contar con los archivos: Clave Privada (.key), Certificado (.cer) y Contraseña de clave privada de la firma.",
                        },
                        {
                          value: "singParticipant",
                          label: "Especificar por usuario - Mixto",
                          tooltipText:
                            "Al momento de agregar cada participante debes especificar el tipo de firma.",
                        },
                      ]}
                      validations={{ required: true }}
                    />
                  </>
                )}
                <Box>
                  <InputField
                    inputType="select"
                    control={control}
                    register={register}
                    name="cmbTipoTramite"
                    label="Tipo de trámite"
                    validations={{ required: false }}
                    selectField="Id"
                    optionsList={resultCategories}
                    required={false}
                  />
                </Box>
                <Typography variant="h6" sx={{ mb: 1, mt: 1 }}>
                  Propiedades del tipo de firma
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ mb: 1, display: "flex", alignItems: "center" }}
                >
                  Selecciona la opción que deseas incluir para el proceso de
                  firma electrónica de la solicitud.
                </Typography>
                {watchSign === "singParticipant" ||
                watchSign == undefined ||
                watchSign == null ? null : (
                  <>
                    <InputField
                      inputType="check"
                      control={control}
                      disabled={fileState.editStack}
                      label="Videograbación: Los participantes deberán grabar un video de consentimiento de la aplicación de su firma en los documentos."
                      tooltipText={
                        "Se realizará una grabación de video corta donde el participante demuestra estar de acuerdo con las condiciones específicas para el procedimiento de firma electrónica."
                      }
                      name="videoCheck"
                    />

                    <InputField
                      inputType="check"
                      control={control}
                      tooltipText={
                        "La contraseña de un solo uso será enviada vía correo electrónico y por mensaje de texto el cual tendrá una vigencia de 5 minutos."
                      }
                      label={
                        watchSign === "simpleSign"
                          ? "Contraseña de un solo uso (OTP): Se agregará un código numérico que complementará la atribución de un firmado electrónico"
                          : '<Box><Box>Contraseña de un solo uso (OTP): Se agregará un código numérico que complementará la atribución de un firmado electrónico</Box><Box> <span style="color:#C20E30">(Ampliamente recomendado como complemento)</span> </Box> </Box>'
                      }
                      name="OTP"
                      disabled={
                        watchSign === "simpleSign" || fileState.editStack
                      }
                      defaultValue={watchSign === "advancedSign"}
                    />
                  </>
                )}
                <InputField
                  inputType="check"
                  control={control}
                  onClick={(e) =>
                    e.target.value == "true" ? setOpenAlertGps(true) : null
                  }
                  tooltipText={
                    "Se recolectarán las coordenadas (latitud y longitud) del dispositivo durante la firma de los documentos."
                  }
                  label="Geolocalización: Será recolectada la geolocalización de cada uno de los participantes durante la firma de los documentos."
                  name="CheckGPS"
                />
                <InputField
                  inputType="check"
                  control={control}
                  onClick={(e) =>
                    e.target.value == "true" ? setOpenAlert(true) : null
                  }
                  tooltipText={
                    "La NOM-151 garantiza la integridad de mensajes de datos a partir del momento de su generación, así como la disponibilidad de esta información cuando la persona así lo requiera. La constancia de conservación incluye una estampa de tiempo indicando la fecha y hora en que se generó."
                  }
                  label="NOM-151: Constancia de conservación de mensaje de datos."
                  name="NOM151"
                />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" sx={{ mb: 1, mt: 1 }}>
                    Notificaciones
                  </Typography>
                  <Tip
                    title={
                      " En caso de no seleccionar alguna de las opciones, el sistema notificará a cada uno de los participantes cuando sea su turno de realizar alguna acción (revisión y firmado). "
                    }
                    placement="top"
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                  </Tip>
                </Box>

                <Typography
                  variant="caption"
                  sx={{ mb: 1, display: "flex", alignItems: "center" }}
                >
                  Selecciona la configuración para el envío de notificaciones
                  (Opcional)
                </Typography>

                <InputField
                  inputType="check"
                  control={control}
                  tooltipText={
                    "Se enviara el expediente final también a los firmantes."
                  }
                  label="Notificación de documentos firmados"
                  name="notificationSignerDoc"
                />

                <InputField
                  inputType="check"
                  control={control}
                  tooltipText={
                    "Cada vez que ocurra un evento en el proceso de revisión serán notificados vía correo electrónico al resto de revisores."
                  }
                  label="Notificar eventos de revisión a todos los revisores (Cuando aplique)"
                  name="notificaRevisores"
                />

                <InputField
                  inputType="check"
                  control={control}
                  tooltipText={
                    "Cada vez que ocurra un evento en el proceso de firmado serán notificados vía correo electrónico los demás firmantes, independiente a su turno de firma."
                  }
                  label="Notificar eventos de firmado a todos los firmantes"
                  name="notificaFirmantes"
                />

                <Typography variant="h6" sx={{ mb: 1, mt: 1 }}>
                  Requisitos previos <spam style={{ color: "#C20E30" }}>*</spam>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="caption"
                    sx={{ mb: 1, display: "flex", alignItems: "center" }}
                  >
                    Selecciona “Sí”, en caso de que debas solicitar documentos
                    para iniciar el proceso de revisión y firmado.
                  </Typography>
                  <Tip
                    title={
                      "Son aquellos documentos que se solicitan previo al firmado de los documentos y que no forman parte del proceso de edición y firmado, pero que son necesarios para celebrar un contrato, por ejemplo, en el alta de un proveedor se requiere Acta Constitutiva, RFC, Identificación del Rep. Legal, etc."
                    }
                    placement="top"
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                  </Tip>
                </Box>
                <InputField
                  inputType="radio"
                  control={control}
                  disabled={fileState.editStack}
                  name="ctrlReqPrevios"
                  radioValues={[
                    {
                      value: "Si",
                      label: "Sí",
                      tooltipText: "",
                    },
                    {
                      value: "No",
                      label: "No",
                      tooltipText: "",
                    },
                  ]}
                  validations={{ required: true }}
                />

                {formState.errors.signatureType && (
                  <Typography
                    variant="caption"
                    color="#C20E30"
                    sx={{ display: "block", mt: 1 }}
                  >
                    Debes seleccionar al menos un tipo de firma{" "}
                    <spam style={{ color: "#C20E30" }}>*</spam>
                  </Typography>
                )}
                {Object.keys(formState.errors).length > 0 && (
                  <Typography color="primary" variant="caption">
                    Campos requeridos{" "}
                    <spam style={{ color: "#C20E30" }}>*</spam>
                  </Typography>
                )}
                <Divider
                  sx={{
                    mt: 5,
                    mb: 5,
                    borderBottomColor: ({ palette }) =>
                      palette.secondary.main60,
                  }}
                />
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={4}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      sx={{ height: 45, px: 10 }}
                      component={Link}
                      to="/inbox/files"
                      disabled={fileState.loading}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <LoadingButton
                      fullWidth
                      loading={fileState.loading}
                      variant="contained"
                      sx={{ height: 45, px: 10 }}
                      onClick={handleSubmit(onSubmit)}
                      disabled={!formState.isValid}
                    >
                      Siguiente
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <CustomModalAlert
          Open={openAlert}
          Title=""
          SubTitle={
            <>
              <Typography
                textAlign={"left"}
                variant="h6"
                sx={{ fontWeight: 500, p: 1 }}
              >
                La NOM 151 es la constancia de conservación de mensaje de datos
                emitido por un PSC, el cual permite verificar la integridad del
                documento original a lo largo del tiempo.
              </Typography>
              <Typography
                textAlign={"left"}
                variant="h6"
                sx={{ fontWeight: 500, mt: 2, p: 1 }}
              >
                ¿Estas seguro de remover la generación de la NOM 151 para esta
                solicitud?
              </Typography>
            </>
          }
          LeftButtonText="Aceptar"
          LeftButtonAction={() => {
            setValue("NOM151", false);
            setOpenAlert(false);
          }}
          RightButtonText="Cancelar"
          RightButtonAction={() => {
            setOpenAlert(false);
            setValue("NOM151", true);
          }}
        />

        <CustomModalAlert
          Open={openAlertGps}
          Title=""
          SubTitle={
            <>
              <Typography
                textAlign={"left"}
                variant="h6"
                sx={{ fontWeight: 500, p: 1 }}
              >
                No se recolectará la geolocalización de los firmantes, por lo
                tanto no formará parte de las evidencias de firma.
              </Typography>
              <Typography
                textAlign={"left"}
                variant="h6"
                sx={{ fontWeight: 500, mt: 2, p: 1 }}
              >
                ¿Estás seguro de remover la geolocalización para esta solicitud?
              </Typography>
            </>
          }
          LeftButtonText="Aceptar"
          LeftButtonAction={() => {
            setValue("CheckGPS", false);
            setOpenAlertGps(false);
          }}
          RightButtonText="Cancelar"
          RightButtonAction={() => {
            setOpenAlertGps(false);
            setValue("CheckGPS", true);
          }}
        />
      </RootStyle>
    </LocalizationProvider>
  );
};
