// true: utiliza la ruta de publiucacion, false: utiliza localhost
export const constPathRoot = {
  pathRoot: true ? `${process.env.REACT_APP_BASENAME}` : ''
};

//Variable que indica la activacion de encriptacion a todas las peticiones
export const constEncripted = {
  encriptedBody: false
};

export const constUrlVisor = {
  ambient: `${process.env.REACT_APP_VISOR}`
};

export const constUrlGraficas = {
  ambient: ``
};

export const constUrlGraficasMovil = {
  ambient: ``
};

//Variable que indica la activacion de encriptacion a todas las peticiones
export const algorithm = process.env.REACT_APP_ENCRIPTED_ALGORITHM
export const prPublicKeyDSC = process.env.REACT_APP_ENCRIPTED_PUBLICDSC_KEY
export const prPublicKey = process.env.REACT_APP_ENCRIPTED_PUBLIC_KEY
export const prPrivateKey = process.env.REACT_APP_ENCRIPTED_PRIVATE_KEY
