import { GenericServicePayment } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class PaymentsService {
  static instance;

  constructor() {
    this.baseUrl = "Payment";
  }

  static getInstance() {
    if (!PaymentsService.instance) {
      PaymentsService.instance = new PaymentsService();
    }
    return PaymentsService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    let respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      let responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async GetPlansService(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/GetPlans`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseDataPlans', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetClientData(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/GetClientData`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseDataCliente', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async RegisterClient(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/RegisterClient`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseRegisterClient', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async RegisterPaymentClientPlan(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/RegisterPaymentClientPlan`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseRegisterClient', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetBilling(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/GetBilling`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseGetBilling', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    } 
  }

  async RegisterBillingInformation(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/RegisterBillingInformation`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseRegisterClient', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetRegimenFiscal(data) {
    try {
      console.log('DATA', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/GetRegimenFiscal`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('responseRegisterClient', response)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async GetPaymentStatus(data) {
    try {
      console.log('GetPaymentStatus', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/GetPaymentStatus`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('GetPaymentStatus', response.data)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

  async CancelSuscription(data) {
    try {
      console.log('CancelSuscription', data)
      const response = await GenericServicePayment.post({
        endpoint: `Payment/CancelSuscription`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('CancelSuscription', response.data)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }

}
