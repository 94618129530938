import React from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import sign from "../../assets/sign.svg";
import { useDispatch, useSelector } from "react-redux";
import { editStack, resetFile, resetParticipantsMassive, resetUsersPrevious } from "../../store/actions";

const Image = styled("img")(({ theme }) => ({
  width: 170,
  marginTop: 30,
  marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    width: 150,
  },
}));

export const EmptyTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateRedux = useSelector((state) => state);

  const handleClick = () => {
    dispatch(editStack(false));
    dispatch(resetParticipantsMassive())
    dispatch(resetUsersPrevious());
    dispatch(resetFile());
    sessionStorage.removeItem('FileId');
    navigate("/inbox/new-file");
  };

  let rol =
    stateRedux?.Authentication?.items?.UserLoginData?.SecurityLoginData
      ?.Roles[0]?.Name;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Image alt="firma" src={sign} />
      <Typography variant="h4" sx={{ fontWeight: 600, my: 2 }} align="center">
        Sin documentos por revisar/firmar
      </Typography>
      <Typography
        variant="body2"
        sx={{ maxWidth: 600, color: "secondary.main80" }}
        align="center"
      >
        No tienes expedientes pendientes para firmar y aún no has sido invitado
        para firmar algún documento.
      </Typography>
      {rol === "Operador" ? null : (
        <Button variant="contained" sx={{ my: 3 }} onClick={handleClick}>
          Crear nueva solicitud
        </Button>
      )}
    </Box>
  );
};
