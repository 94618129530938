import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme,
  Alert as AlertMUI,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useLocation, useNavigate } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/AutorenewOutlined";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import iconDocument from "../../assets/icons/Documento.png";

import {
  CustomInputField,
  UploadGenericControl,
  CustomModalDialog,
} from "../../components";
import { Alert } from "../../components/Alert";
import BackdropComponent from "../../components/BackDrop";
import VisorPDF from "../../components/Viewer/VisorPDF";
import {
  GetContentTemplate,
  GetCategoriesTemplate,
  UploadTemplateData,
  resetRolesUsers,
} from "../../store/actions";
import { b64toUrl } from "../../utils";
import { ToastNotification } from "../../components/toastNotification";
import { WhiteTooltip } from "../../components/WhiteTooltip";
import { Icon } from "@iconify/react";

const DocButtonLoaded = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #CCCCCC",
  borderRadius: 0,
  padding: "6px 8px",
  width: "100%",
}));

export const RegistrationEditionTemplate = (props) => {
  const theme = useTheme();
  const formMethods = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const { control, handleSubmit, watch, setValue, formState, register } =
    useForm({
      mode: "onBlur",
    });
  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [newDocumentState, setNewDocumentState] = useState(false);
  const [resultCategories, setResultCategories] = useState([]);
  const dateNow = new Date();
  const valueActive = watch("rdStatus");
  const valuerdReplicar = watch("rdReplicar");
  const currentCreateDate = location.state?.FechaCreacion
    ? new Date(location.state?.FechaCreacion)
    : dateNow;
  const modifiedDate = location.state?.LastModification
    ? new Date(location.state?.LastModification)
    : dateNow;

  const dispatch = useDispatch();

  const templateDataUpload = useSelector((state) => state.TemplatesData);
  const GetContentTemplateState = useSelector(
    (state) => state.GetContentTemplateData
  );
  const GetCategoriesTemplateState = useSelector(
    (state) => state.GetCategoriesTemplateData
  );

  useEffect(() => {
    console.log("location:", location, templateDataUpload);
    dispatch(GetCategoriesTemplate("aa"));
    setValue("txtUser", sessionStorage.userName);
    if (location.state !== undefined && location.state !== null) {
      dispatch(GetContentTemplate({ template_Id: location.state?.TemplateId }));
      setValue("txtNameTemplate", location.state?.TemplateName);
      setValue("cmbCategory", location.state?.CategoryName); //location.state.CategoryId);
      setValue("rdStatus", location.state?.Active === "Activo");
      setValue("textTags", location.state?.Tag);
      setValue("rdReplicar", location.state?.ReplicateSignatureZones);
      setLoading(true);
      setNewDocumentState(false);
    }
    setValue("rdStatus", true); 
  }, []); 

  useEffect(() => {
    setLoading(GetCategoriesTemplateState?.loading);
    if (GetCategoriesTemplateState?.items) {
      const filesTable = GetCategoriesTemplateState?.items?.map((itemData) => {
        return {
          ...itemData,
          name: itemData.Name,
        };
      });
      setResultCategories(filesTable);
      setLoading(false);
    }
  }, [GetCategoriesTemplateState]);

  useEffect(() => {
    setLoading(templateDataUpload?.loading);
    if (templateDataUpload?.item && loading) {
      setLoading(false);
      if (valueActive == "false") {
        Alert({
          icon: "success",
          text: "Tu plantilla se deshabilitó con éxito.",
          okbtntext: "Aceptar",
        });
        navigate("/inbox/TemplateManager");
      } else {
        if (
          templateDataUpload?.item.TypeProcess === "DOCX con campos" ||
          templateDataUpload?.item.TypeProcess === "PDF editable"
        ) {
          props.handleNextStep();
        } else {
          props.handleNextDouble();
        }
      }
    } else if (templateDataUpload?.error && loading) {
      setLoading(false);
      Alert({
        icon: "error",
        text: templateDataUpload?.error,
        okbtntext: "Aceptar",
      });
    }
  }, [templateDataUpload]);

  useEffect(() => {
    setLoading(GetContentTemplateState?.loading);
    if (GetContentTemplateState?.items && location.state !== null) {
      let nameFile = GetContentTemplateState?.items?.m_Item2;
      let base64 = GetContentTemplateState?.items?.m_Item3
        ? `data:application/pdf;base64,${GetContentTemplateState?.items?.m_Item3}`
        : `data:application/pdf;base64,${GetContentTemplateState?.items?.m_Item1}`;
      async function fetchData() {
        let blobUrl = await b64toUrl(base64);
        setDataFile({
          File: {
            name: nameFile,
          },
          Base64: base64,
          Extension: location.state.Extension.replace(/\s+/g, ""),
          FileURL: blobUrl,
        });
      }
      fetchData();
      dispatch(GetContentTemplate(null, true));
    }
  }, [GetContentTemplateState]);

  const handleSendData = async (data) => {
    if (dataFile?.Base64 === null || dataFile?.Base64 === undefined) {
      ToastNotification({
        icon: "error",
        text: "Por favor carga un documento.",
      });
      return null;
    }
    let comboResult = data["cmbCategory"];
    let posicionUltimoPunto = dataFile?.File?.name.lastIndexOf(".");
    const nuevoNombreDoc = dataFile?.File?.name.slice(0, posicionUltimoPunto);

    let Body = {
      Name: data.txtNameTemplate,
      Template_Id:
        location.state?.TemplateId === undefined ||
        location.state?.TemplateId === null
          ? null
          : location.state?.TemplateId,
      FileName: nuevoNombreDoc,
      Extension: dataFile?.Extension,
      FileContent: null,
      FileContentB64:
        newDocumentState === false
          ? null
          : dataFile?.Base64.replace("data:application/pdf;base64,", ""),
      Category_Id:
        (location.state?.CategoryId !== undefined ||
          location.state?.CategoryId !== null) &&
        (comboResult === location.state?.CategoryName ||
          comboResult.name === location.state?.CategoryName)
          ? location.state?.CategoryId
          : comboResult.Id === undefined
          ? null
          : comboResult.Id,
      CategoryName:
        comboResult.name === undefined || comboResult.name === null
          ? comboResult
          : comboResult.name,
      Active: data["rdStatus"],
      CreationDate: data["txtCreateDate"],
      Tag: data.textTags,
      ReplicateSignatureZones: data.rdReplicar,
    };
    // console.log("BODY", Body, comboResult, valueState);
    dispatch(resetRolesUsers({ Body }));
    dispatch(UploadTemplateData({ Body }));
    props.setBackItem(newDocumentState === false ? true : false);
    setLoading(true);
  };

  return (
    <Fragment>
      <FormProvider {...formMethods} theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <BackdropComponent loading={loading} />
          <BackdropComponent loading={loading} />
          <Grid item lg={12} sx={{ p: 2 }}>
            <Box>
              <Typography variant="titulo">Plantilla</Typography>
            </Box>
            {props.userEdit === true ? null : (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">
                  Completa el siguiente formulario para agregar una nueva
                  plantilla
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item lg={9} md={8} xs={11}>
              <CustomInputField
                inputType="text"
                control={control}
                register={register}
                name="txtNameTemplate"
                label="Nombre de la plantilla"
                validations={{ required: true }}
                defaultValue=""
                error={!!formState.errors["txtNameTemplate"]}
                helperText={formState.errors["txtNameTemplate"]?.message}
                required={true}
              />
              <Box>
                <CustomInputField
                  inputType="autocomplete"
                  control={control}
                  register={register}
                  name="cmbCategory"
                  label="Categoría"
                  validations={{ required: true }}
                  defaultValue=""
                  optionsList={resultCategories}
                  error={!!formState.errors["cmbCategory"]}
                  helperText={formState.errors["cmbCategory"]?.message}
                  required={true}
                />
                <Typography>
                  Puedes crear una nueva categoría; escribe el nombre y da clic
                  en “agregar”, o bien, selecciona una en caso de que ya exista.
                </Typography>
              </Box>
              <Box>
                <CustomInputField
                  inputType="text"
                  control={control}
                  register={register}
                  name="textTags"
                  label="Tags"
                  validations={{ required: true }}
                  defaultValue=""
                  error={!!formState.errors["textTags"]}
                  helperText={formState.errors["textTags"]?.message}
                  required={true}
                />
                {/*  <Typography>
                  Separa las tags por medio de comas ","
                </Typography> */}
              </Box>
              <CustomInputField
                inputType="text"
                control={control}
                register={register}
                disabled={true}
                name="txtUser"
                label="Usuario"
                validations={{ required: true }}
                defaultValue=""
                error={!!formState.errors["txtUser"]}
                helperText={formState.errors["txtUser"]?.message}
                required={true}
              />
              <CustomInputField
                inputType="date"
                control={control}
                register={register}
                disabled={true}
                name="txtCreateDate"
                label="Fecha de alta"
                validations={{ required: true }}
                error={!!formState.errors["txtCreateDate"]}
                helperText={formState.errors["txtCreateDate"]?.message}
                required={true}
                defaultValue={currentCreateDate}
              />
              <CustomInputField
                inputType="date"
                control={control}
                register={register}
                disabled={true}
                name="txtUpdateDate"
                label="Fecha de actualización"
                validations={{ required: true }}
                error={!!formState.errors["txtUpdateDate"]}
                helperText={formState.errors["txtUpdateDate"]?.message}
                required={true}
                defaultValue={modifiedDate}
              />
              <Box sx={{ mt: 1, mb: 1 }}>
                <CustomInputField
                  inputType="check"
                  control={control}
                  register={register}
                  name="rdReplicar"
                  defaultValue={location?.state?.ReplicateSignatureZones}
                  label="Aplicar firma a todas las hojas"
                  error={!!formState.errors["rdReplicar"]}
                  helperText={formState.errors["rdReplicar"]?.message}
                />
              </Box>
              <CustomInputField
                inputType="radio"
                control={control}
                register={register}
                name="rdStatus"
                label="Estatus"
                validations={{ required: true }}
                error={!!formState.errors["rdStatus"]}
                helperText={formState.errors["rdStatus"]?.message}
                required={true}
                radioValues={[
                  {
                    value: true,
                    label: "Activo",
                    tooltipText: "Indica que está activa la plantilla.",
                  },
                  {
                    value: false,
                    label: "Inactivo",
                    tooltipText: "Indica que está inactiva la plantilla.",
                  },
                ]}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "140%",
                  [theme.breakpoints.down("sm")]: {
                    fontWeight: 400,
                    fontSize: "10px",
                    lineHeight: "144%",
                  },
                }}
              >
                Documento
              </Box>
              <AlertMUI
                iconMapping={{
                  warning: (
                    <Icon
                      icon="mdi:information-variant-circle"
                      fontSize={30}
                      style={{ color: "#FBBF28" }}
                    />
                  ),
                }}
                severity="warning"
                sx={{
                  mt: 1,
                  backgroundColor: "secondary.warningAlert",
                  ".MuiAlert-icon": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
              >
                Puedes cargar plantillas las cuales deberán estar en formato
                word y podrás configurar campos, para esto el campo deberá estar
                precedido de un signo de <b>#</b>, sin espacios y caracteres
                especiales. <br></br>
                Ejemplo: #nombre#, #NOMBRE_APELLIDOS#, #nombre_apellidos#,
                #nombreapellidos#, #NOMBRE#
              </AlertMUI>
              {dataFile !== undefined &&
              dataFile !== null &&
              dataFile !== "" ? (
                <DocumentButton
                  Name={dataFile.File?.name}
                  handleUpload={() => {
                    setDataFile(null);
                    setNewDocumentState(true);
                  }}
                  handleView={() => {
                    setViewTemplate(true);
                  }}
                />
              ) : (
                <UploadGenericControl
                  UploadTemplate={false}
                  ShowDocumentPreview={false}
                  Accept=".docx,.pdf"
                  onChange={(file, base64, extension, fileUrl) => {
                    if (base64 === undefined || base64 === null) {
                      return;
                    }
                    setNewDocumentState(true);
                    setDataFile({
                      File: file,
                      Base64: base64,
                      Extension: `.${extension}`,
                      FileURL: fileUrl,
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} sx={{ mt: 2 }}>
            <Grid item lg={3} md={4} xs={11} sx={{ p: 1 }}>
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => {
                  navigate("/inbox/TemplateManager");
                }}
                sx={{ height: 45 }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item lg={3} md={4} xs={11} sx={{ p: 1 }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                // disabled={
                //   dataFile?.Base64 === undefined || dataFile?.Base64 === null
                // }
                onClick={handleSubmit(handleSendData)}
                sx={{ height: 45 }}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>

          <CustomModalDialog
            Open={viewTemplate}
            Title={`Plantilla - ${dataFile?.File?.name}`}
            OnClose={() => {
              setViewTemplate(false);
            }}
            Body={
              <VisorPDF
                parsingDoc={false}
                pdfUrl={dataFile?.FileURL}
                pdfName={dataFile?.File?.name}
                changeFile={() => {}}
              />
            }
          />
        </LocalizationProvider>
      </FormProvider>
    </Fragment>
  );
};

export const DocumentButton = (props) => {
  return (
    <Box sx={{ mt: 2.5, width: "100%" }}>
      <DocButtonLoaded
        style={props.style}
        fullWidth
        sx={{ color: "#000" }}
        component={Box}
      >
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <img
              style={{ width: 13, marginRight: 5 }}
              alt="imagenLogo"
              src={iconDocument}
            />
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: "14px !important",
              }}
            >
              {props.Name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <WhiteTooltip title="Volver a cargar">
              <IconButton onClick={props.handleUpload}>
                <AutorenewIcon sx={{ color: "black", fontSize: 25 }} />
              </IconButton>
            </WhiteTooltip>
            <WhiteTooltip title="Ver plantilla">
              <IconButton onClick={props.handleView}>
                <VisibilityIcon sx={{ color: "black", fontSize: 26 }} />
              </IconButton>
            </WhiteTooltip>
          </Box>
        </Box>
      </DocButtonLoaded>
    </Box>
  );
};

const fotmatDate = (dateString) => {
  try {
    const dateParts = dateString.split(" "); // Dividimos la cadena en fecha y hora

    // Obtener las partes de la fecha y la hora
    const date = dateParts[0];
    const time = dateParts[1] + " " + dateParts[2];

    // Dividir las partes de la fecha
    const [day, month, year] = date.split("-");

    // Dividir las partes de la hora
    const [timeWithoutPeriod, period] = time.split(" ");
    const [hour, minute, second] = timeWithoutPeriod.split(":");

    // Crear un objeto Date con las partes analizadas
    const dateObject = new Date(
      year,
      month - 1,
      day,
      (period === "PM" ? (hour % 12) + 12 : hour) % 24,
      minute,
      second
    );

    // Puedes formatear la fecha y hora según tus necesidades
    const formattedDate = dateObject.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    return formattedDate;
  } catch (ex) {
    console.error(ex);
    return new Date();
  }
};
