import { graphicsConstants } from "../constants";

const initialState = {
    loading: false,
    error: null,
    item: null,
};

export const GetGraphicsByLastMonth = (state = initialState, action) => {
    switch (action.type) {
        case graphicsConstants.GET_FILES_CHARTS_BY_LASTMONTH_REQUEST:
            return {
                ...state,
                loading: true,
                item: null,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_LASTMONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_LASTMONTH_FAILTURE:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const GetGraphicsByApplicant = (state = initialState, action) => {
    switch (action.type) {
        case graphicsConstants.GET_FILES_CHARTS_BY_APPLICANT_REQUEST:
            return {
                ...state,
                loading: true,
                item: null,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_APPLICANT_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_APPLICANT_FAILTURE:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const GetGraphicsByTypeSign = (state = initialState, action) => {
    switch (action.type) {
        case graphicsConstants.GET_FILES_CHARTS_BY_TYPESIGN_REQUEST:
            return {
                ...state,
                loading: true,
                item: null,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_TYPESIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_TYPESIGN_FAILTURE:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const GetGraphicsByStatusDeadLine = (state = initialState, action) => {
    switch (action.type) {
        case graphicsConstants.GET_FILES_CHARTS_BY_STATUS_DEADLINE_REQUEST:
            return {
                ...state,
                loading: true,
                item: null,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_STATUS_DEADLINE_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_STATUS_DEADLINE_FAILTURE:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const GetFilesChartCurrentMonth = (state = initialState, action) => {
    switch (action.type) {
        case graphicsConstants.GET_FILES_CHARTS_BY_CURRENT_MONTH_REQUEST:
            return {
                ...state,
                loading: true,
                item: null,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_CURRENT_MONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_CURRENT_MONTH_FAILTURE:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const GetFilesByStatus = (state = initialState, action) => {
    switch (action.type) {
        case graphicsConstants.GET_FILES_CHARTS_BY_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                item: null,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.payload,
                error: null,
            };

        case graphicsConstants.GET_FILES_CHARTS_BY_STATUS_FAILTURE:
            return {
                ...state,
                loading: false,
                item: null,
                error: action.payload,
            };
        default:
            return state;
    }
};