export const graphicsConstants = {
  GET_FILES_CHARTS_BY_LASTMONTH_SUCCESS: "GET_FILES_CHARTS_BY_LASTMONTH_SUCCESS",
  GET_FILES_CHARTS_BY_LASTMONTH_REQUEST: "GET_FILES_CHARTS_BY_LASTMONTH_REQUEST",
  GET_FILES_CHARTS_BY_LASTMONTH_FAILTURE: "GET_FILES_CHARTS_BY_LASTMONTH_FAILTURE",

  GET_FILES_CHARTS_BY_APPLICANT_SUCCESS: "GET_FILES_CHARTS_BY_APPLICANT_SUCCESS",
  GET_FILES_CHARTS_BY_APPLICANT_REQUEST: "GET_FILES_CHARTS_BY_APPLICANT_REQUEST",
  GET_FILES_CHARTS_BY_APPLICANT_FAILTURE: "GET_FILES_CHARTS_BY_APPLICANT_FAILTURE",

  GET_FILES_CHARTS_BY_TYPESIGN_SUCCESS: "GET_FILES_CHARTS_BY_TYPESIGN_SUCCESS",
  GET_FILES_CHARTS_BY_TYPESIGN_REQUEST: "GET_FILES_CHARTS_BY_TYPESIGN_REQUEST",
  GET_FILES_CHARTS_BY_TYPESIGN_FAILTURE: "GET_FILES_CHARTS_BY_TYPESIGN_FAILTURE",

  GET_FILES_CHARTS_BY_STATUS_DEADLINE_SUCCESS: "GET_FILES_CHARTS_BY_STATUS_DEADLINE_SUCCESS",
  GET_FILES_CHARTS_BY_STATUS_DEADLINE_REQUEST: "GET_FILES_CHARTS_BY_STATUS_DEADLINE_REQUEST",
  GET_FILES_CHARTS_BY_STATUS_DEADLINE_FAILTURE: "GET_FILES_CHARTS_BY_STATUS_DEADLINE_FAILTURE",

  GET_FILES_CHARTS_BY_CURRENT_MONTH_SUCCESS: "GET_FILES_CHARTS_BY_CURRENT_MONTH_SUCCESS",
  GET_FILES_CHARTS_BY_CURRENT_MONTH_REQUEST: "GET_FILES_CHARTS_BY_CURRENT_MONTH_REQUEST",
  GET_FILES_CHARTS_BY_CURRENT_MONTH_FAILTURE: "GET_FILES_CHARTS_BY_CURRENT_MONTH_FAILTURE",

  GET_FILES_CHARTS_BY_STATUS_SUCCESS: "GET_FILES_CHARTS_BY_STATUS_SUCCESS",
  GET_FILES_CHARTS_BY_STATUS_REQUEST: "GET_FILES_CHARTS_BY_STATUS_REQUEST",
  GET_FILES_CHARTS_BY_STATUS_FAILTURE: "GET_FILES_CHARTS_BY_STATUS_FAILTURE",
}