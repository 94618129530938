import { massiveConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
  ListDocStack: []
};

export const ParticipantsMassive = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.FILE_PARTICIPANT_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.FILE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.FILE_PARTICIPANT_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    case massiveConstants.PARTICIPANTS_TEMPLATE_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };

    default:
      return state;
  }
};

export const SkipPrerequisitesData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.SKIP_PREREQUISITES_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.SKIP_PREREQUISITES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.SKIP_PREREQUISITES_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const UploadTemplateData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.UPLOAD_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.UPLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const UploadTemplateMetaData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.UPLOAD_TEMPLATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.UPLOAD_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.UPLOAD_TEMPLATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const DisableTemplateData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.DISABLE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.DISABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.DISABLE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const UploadMetadataTemplateData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.UPLOAD_METADATA_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.UPLOAD_METADATA_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.UPLOAD_METADATA_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const UploadMetadataBatchFileData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.UPLOAD_METADATA_BATCHFILE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.UPLOAD_METADATA_BATCHFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.UPLOAD_METADATA_BATCHFILE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const ListDocStackMassiveData = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.ADD_LISTDOC_STACK_MASSIVE:
      return {
        ...state,
        ListDocStack: [...state.ListDocStack, action.payload],
      };
    case massiveConstants.SET_LISTDOC_STACK_MASSIVE:
      return {
        ...state,
        ListDocStack: [...action.payload],
      };
    case massiveConstants.RESET_LISTDOC_STACK_MASSIVE:
      return {
        ...state,
        ListDocStack: [],
      };
    default:
      return state; //Retornar state vacío o actual
  }
};

export const UploadPrerequisitesUsers = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.PREREQUISITOS_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.PREREQUISITOS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.PREREQUISITOS_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case massiveConstants.PREREQUISITOS_TEMPLATE_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const ReorderParticipantMassive = (state = initialState, action) => {
  switch (action.type) {
    case massiveConstants.REODERDER_PARTICIPANT_MASSIVE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case massiveConstants.REODERDER_PARTICIPANT_MASSIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case massiveConstants.REODERDER_PARTICIPANT_MASSIVE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

      case massiveConstants.REODERDER_PARTICIPANT_MASSIVE_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};