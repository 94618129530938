import React, { useEffect, useState } from "react";
import {
  Tooltip,
  MenuItem,
  ListItemText,
  tooltipClasses,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Typography,
  autocompleteClasses,
  Radio as Rd,
  RadioGroup,
  createFilterOptions,
  useTheme,
  IconButton,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Parser from "html-react-parser";
import { styled } from "@mui/material/styles";
import "react-phone-input-2/lib/style.css";
import NumberFormat from "react-number-format";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/base";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": { fontSize: 14 },
  display: "inline-flex",
  alignItems: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "140%",
  fontFamily: '"Montserrat",sans-serif',
  [theme.breakpoints.down("sm")]: {
    fontWeight: 500,
    fontSize: "9px",
    lineHeight: "144%",
  },
}));

const Check = styled(Checkbox)(({ theme }) => ({
  padding: "4px 0",
  paddingLeft: theme.spacing(1),
  '&.Mui-checked': {
    color: theme.palette.secondary.main, // Cambia el color del recuadro de verificación cuando está seleccionado
  },
}));

const Radio = styled(Rd)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  '&.Mui-checked': {
    color: theme.palette.secondary.main, // Cambia el color del radio button cuando está seleccionado
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 1,
  },
}));

const InputText = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 12,
    fontSize: 14,
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: '10px',
      // lineHeight: '144%',
      borderRadius: 2,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#C20E30 !important",
    // #C20E30
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
}));

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: "2.5px 5px",
  },
  "& ~ .MuiAutocomplete-popper .MuiAutocomplete-noOptions": {
    fontSize: 14,
  },
}));

const SelectOption = styled(ListItemText)(({ theme }) => ({
  margin: "2px 0",
  "& .MuiListItemText-primary": {
    fontSize: 14,
    margin: 0,
  },
}));

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexCP = /^\d{5}$/;

export const InputField = (props) => {
  const theme = useTheme();

  const [optionsSelect, setoptionsSelect] = useState([]);
  const {
    inputType,
    onClick,
    iconSpam,
    defaultValue,
    value,
    displayInputs,
    label,
    multiline,
    minRows,
    sxLabel,
    sxInput,
    optionsList,
    tooltipText,
    control,
    name,
    validations,
    icon,
    posIcon,
    onEnter,
    placeholder,
    onSelect,
    selectField,
    disabled,
    loading,
    onInputChange,
    open,
    onOpen,
    onClose,
    error,
    isPassword,
    checked,
    radioValues,
    minDate,
    maxDate,
    required,
    register,
  } = props;

  const getInputType = () => {
    switch (inputType) {
      case "select":
        return (
          <Controller
            control={control}
            name={name}
            defaultValue=""
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <InputText
                error={error}
                label={label}
                onClose={onClose}
                fullWidth
                select
                inputRef={ref}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  onSelect && onSelect(e);
                }}
                inputProps={{
                  displayEmpty: true,
                  startAdornment: <ClearIcon />,
                }}
                {...restFields}
              >
                <MenuItem value="" onClick={() => onChange("")}>
                  <spam style={{ color: "#9E9E9E" }}>{placeholder}</spam>
                </MenuItem>
                {optionsSelect.map((opt, i) => (
                  <MenuItem
                    key={opt?.name + i}
                    value={opt[selectField ?? "name"]}
                  >
                    <SelectOption primary={opt.name} sx={{ fontSize: 14 }} />
                  </MenuItem>
                ))}
                {/* {restFields.value && (
                  <Text
                    onClick={() => onChange("")} // Limpia la selección al hacer clic
                  >
                    Borrar Selección
                  </Button>
                )} */}
              </InputText>
            )}
          />
        );
      case "check":
        return (
          <ControlLabel
            sx={{
              fontSize: "16px !important",
              mr: 1,
              mt: "0.5px",
              // fontFamily: '"Montserrat",sans-serif',
            }}
            name={name}
            disabled={disabled}
            control={
              <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={validations}
                render={({
                  field: { ref, onChange, value, name, ...restFields },
                }) => (
                  <Box sx={{ mt: 1.3 }}>
                    <Check
                      {...restFields}
                      name={name}
                      inputRef={ref}
                      checked={value ?? defaultValue}
                      disabled={disabled}
                      required={required}
                      onChange={(e) => {
                        onChange(e);
                        onSelect && onSelect(e);
                      }}
                      size="small"
                    />
                  </Box>
                )}
              />
            }
            label={
              <Box
                color={error ? "#DC1717" : ""}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 1,
                  mt: 1.3,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "16px",
                  // fontFamily: '"Montserrat",sans-serif',
                  textAlign: "justify",
                  [theme.breakpoints.down("sm")]: {
                    fontWeight: 400,
                    fontSize: "11px",
                    lineHeight: "144%",
                  },
                }}
              >
                {Parser(`${label} ${required ? "*" : ""}`)}
                {tooltipText && (
                  <Tip
                    title={tooltipText}
                    placement="top"
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon sx={{ ml: 1, fontSize: 16 }} />
                  </Tip>
                )}
              </Box>
            }
          />
        );
      case "radio-flex":
        return (
          <ControlLabel
            sx={{
              fontSize: "14px !important",
              m: "0",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
              width: "100% !important",
            }}
            disabled={disabled}
            // type=""
            control={
              <>
                <Controller
                  defaultValue={null}
                  // type=""
                  control={control}
                  name={name}
                  rules={validations}
                  disabled={disabled}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Box>
                        {radioValues.map((rv) => (
                          <FormControlLabel
                            key={rv.label + rv.value}
                            disabled={disabled}
                            value={rv.value}
                            control={<Radio size="small" />}
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 1,
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  // fontSize: '17px',
                                  lineHeight: "16px",
                                  fontFamily: '"Montserrat",sans-serif',
                                  [theme.breakpoints.down("sm")]: {
                                    fontWeight: 400,
                                    fontSize: "10px",
                                    lineHeight: "144%",
                                  },
                                }}
                              >
                                {rv.label}
                                {rv.tooltipText && (
                                  <Tip
                                    title={rv.tooltipText}
                                    placement="top"
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <InfoOutlinedIcon
                                      sx={{ ml: 1, fontSize: 15 }}
                                    />
                                  </Tip>
                                )}
                              </Box>
                            }
                          />
                        ))}
                      </Box>
                    </RadioGroup>
                  )}
                />
                {error ? (
                  <Typography
                    variant="caption"
                    color="#C20E30"
                    sx={{ display: "block", mt: 1 }}
                  >
                    Campo requerido *
                  </Typography>
                ) : null}
              </>
            }
          />
        );
      case "radio":
        return (
          <ControlLabel
            sx={{
              fontSize: "14px !important",
              m: "0",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
              width: "100% !important",
            }}
            disabled={disabled}
            // type=""
            control={
              <>
                <Controller
                  defaultValue={null}
                  // type=""
                  control={control}
                  name={name}
                  rules={validations}
                  disabled={disabled}
                  render={({ field }) => (
                    <Box sx={{ width: "100%", mt: 1, mb: 0.5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "140%",
                          [theme.breakpoints.down("sm")]: {
                            fontWeight: 400,
                            fontSize: "10px",
                            lineHeight: "144%",
                          },
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {label ? (
                          <Box sx={{ display: "flex" }}>
                            {label}
                            {iconSpam ? (
                              <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                                *
                              </spam>
                            ) : null}
                          </Box>
                        ) : null}
                        {tooltipText && (
                          <Tip
                            title={tooltipText}
                            placement="top"
                            arrow
                            enterTouchDelay={0}
                          >
                            <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                          </Tip>
                        )}
                      </Box>
                      <RadioGroup {...field}>
                        {radioValues.map((rv) => (
                          <FormControlLabel
                            key={rv.label + rv.value}
                            disabled={disabled}
                            value={rv.value}
                            control={<Radio size="small" />}
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 1,
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "16px",
                                  fontFamily: '"Montserrat",sans-serif',
                                  [theme.breakpoints.down("sm")]: {
                                    fontWeight: 400,
                                    fontSize: "10px",
                                    lineHeight: "144%",
                                  },
                                }}
                              >
                                {rv.label}
                                {rv.tooltipText && (
                                  <Tip
                                    title={rv.tooltipText}
                                    placement="top"
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <InfoOutlinedIcon
                                      sx={{ ml: 1, fontSize: 15 }}
                                    />
                                  </Tip>
                                )}
                              </Box>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  )}
                />
                {error ? (
                  <Typography
                    variant="caption"
                    color="#C20E30"
                    sx={{ display: "block", mt: 1 }}
                  >
                    Campo requerido *
                  </Typography>
                ) : null}
              </>
            }
          />
        );
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <Controller
              control={control}
              name={name}
              rules={validations}
              render={({ field: { ref, onChange, ...restFields } }) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      width: 400,
                      mb: 1,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}{" "}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                        {" "}
                        *{" "}
                      </spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                  <DatePicker
                    sx={{
                      minWidth: "400px",
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "250px",
                      },
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat="dd/MM/yyyy"
                    inputProps={{ placeholder: "dd/mm/aaaa" }}
                    label={""}
                    components={{
                      OpenPickerIcon: CalendarTodayOutlinedIcon,
                    }}
                    {...restFields}
                    onChange={(e) => {
                      if (e) {
                        const d = new Date();
                        d.setFullYear(
                          e.getFullYear(),
                          e.getMonth(),
                          e.getDate()
                        );
                        d.setHours(0);
                        d.setMinutes(0);
                        d.setSeconds(0);
                      }
                      onChange(e);
                      onSelect && onSelect(e);
                    }}
                    inputRef={ref}
                    // defaultValue={defaultValue ?? null}
                    disabled={disabled}
                    renderInput={(params) => {
                      return (
                        <InputText
                          onKeyUp={onEnter}
                          {...params}
                          error={error}
                          // placeholder="dd/mm/aaaa"
                          // placeholder={placeholder}
                          fullWidth
                          sx={{ ...(sxInput ?? {}) }}
                          // InputProps={{placeholder: "dd/mm/aaaa"}}
                          InputLabelProps={{ sx: sxLabel }}
                        />
                      );
                    }}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        );
      case "phone":
        return (
          <Controller
            control={control}
            name={name}
            // defaultValue=""
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                fullWidth
                error={error}
                inputRef={ref}
                disabled={disabled}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{ inputComponent: TextMaskCustom }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "number":
        return (
          <Controller
            control={control}
            name={name}
            // defaultValue=""
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                fullWidth
                error={error}
                inputRef={ref}
                disabled={disabled}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {" "}
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                onKeyPress={(event) => {
                  const charCode = event.which ? event.which : event.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    event.preventDefault(); // Prevenir entrada de caracteres no numéricos
                  }
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case "autocomplete":
        const filter = createFilterOptions();
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { onChange, value } }) => (
              <AutocompleteStyled
                /* freeSolo */
                clearOnBlur
                handleHomeEndKeys
                sx={{ p: 0 }}
                fullWidth
                disabled={disabled}
                onClick={onClick}
                /* onChange={(event, item) => {
                  onChange(item);
                }} */

                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    onChange({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    onChange({
                      name: newValue.inputValue,
                    });
                  } else {
                    onChange(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Agregar "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                loadingText="Cargando..."
                noOptionsText="Sin resultados"
                clearText="Limpiar"
                openText="Abrir"
                closeText="Cerrar"
                onInputChange={onInputChange}
                disablePortal={true}
                loading={loading}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                value={value}
                options={optionsList}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                /*  getOptionLabel={(item) => (item.name ? item.name : "")} */
                renderInput={(params) => (
                  <InputText
                    {...params}
                    error={error}
                    placeholder={placeholder}
                    InputLabelProps={{ sx: sxLabel }}
                    sx={{ ...(sxInput ?? {}) }}
                    label={
                      <>
                        {" "}
                        {label}
                        {iconSpam ? (
                          <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                            *
                          </spam>
                        ) : null}
                      </>
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <Typography
                    {...props}
                    sx={{ fontSize: "14px !important" /* margin: "0 10px" */ }}
                  >
                    {option.name}
                  </Typography>
                )}
              />
            )}
          />
        );
      case "currency":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <NumberFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {" "}
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    ) : null}
                  </>
                }
                customInput={InputText}
                decimalScale={2} // Decimales
                fixedDecimalScale={true}
                thousandSeparator={true} // Separador de miles
                prefix={"$"} // Símbolo de moneda
              />
            )}
          />
        );
      case "text":
      case "password":
      default:
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            // value={value ?? ""}
            // defaultValue={defaultValue}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                type={isPassword ? "password" : "text"}
                error={error}
                // value={value ?? ""}
                defaultValue={defaultValue}
                fullWidth
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                // onKeyUp={() => onEnter()}
                placeholder={placeholder}
                disabled={disabled}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}{" "}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    outlinet: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  startAdornment:
                    posIcon === "start" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon === "end" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel }}
                sx={{
                  "& $input": {
                    height: multiline ? "auto" : "25px",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
    }
  };

  useEffect(() => {
    /*  setoptionsSelect(["Contrato", "Consentimiento de convenio"]); */
    if (optionsList?.length > 0) {
      const opts = optionsList.map(({ Name, ...item }) => ({
        ...item,
        name: Name,
      }));
      setoptionsSelect(opts);
    }
  }, [optionsList]);

  if (inputType === "check") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>{getInputType()}</Box>
    );
  }

  return getInputType();
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  return (
    <IMaskInput
      {...props}
      mask="(00) 0000 - 0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      overwrite
    />
  );
});
