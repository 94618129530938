export const documentConstants = {
  REGISTER_DOCUMENT_FIELDATA_REQUEST: "REGISTER_DOCUMENT_FIELDATA_REQUEST",
  REGISTER_DOCUMENT_FIELDATA_SUCCESS: "REGISTER_DOCUMENT_FIELDATA_SUCCESS",
  REGISTER_DOCUMENT_FIELDATA_FAILURE: "REGISTER_DOCUMENT_FIELDATA_FAILURE",
  REGISTER_DOCUMENT_FIELDATA_EMPTY: "REGISTER_DOCUMENT_FIELDATA_EMPTY",
  UPLOAD_TEMPLATE_MANAGER_REQUEST: "UPLOAD_TEMPLATE_MANAGER_REQUEST",
  UPLOAD_TEMPLATE_MANAGER_SUCCESS: "UPLOAD_TEMPLATE_MANAGER_SUCCESS",
  UPLOAD_TEMPLATE_MANAGER_FAILURE: "UPLOAD_TEMPLATE_MANAGER_FAILURE",
  UPLOAD_TEMPLATE_MANAGER_EMPTY: "UPLOAD_TEMPLATE_MANAGER_EMPTY",
  INHERIT_TRACE_SIGN_DOCUMENTS_REQUEST: "INHERIT_TRACE_SIGN_DOCUMENTS_REQUEST",
  INHERIT_TRACE_SIGN_DOCUMENTS_SUCCESS: "INHERIT_TRACE_SIGN_DOCUMENTS_SUCCESS",
  INHERIT_TRACE_SIGN_DOCUMENTS_FAILURE: "INHERIT_TRACE_SIGN_DOCUMENTS_FAILURE",
  INHERIT_TRACE_SIGN_DOCUMENTS_EMPTY: "INHERIT_TRACE_SIGN_DOCUMENTS_EMPTY",
};