import { Button, Box, styled, Typography, useTheme } from "@mui/material";
import { setDocsStack, setListDocsStackMassive } from "../../store/actions";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useDispatch, useSelector } from "react-redux";
import { FileService } from "../../services/FileService";
import { MassiveService } from "../../services/MassiveService";
import iconDocument from "../../assets/icons/Documento.png";
import { Alert } from "../../components/Alert";
import { Icon } from "@iconify/react";
import useAsyncEffect from "use-async-effect";

const DocButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #EEE",
  borderRadius: 0,
}));

const DocButtonLoaded = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #EEE",
  borderRadius: 0,
  padding: "6px 8px",
}));

export const DocumentButton = (props) => {
  const newFileState = useSelector((state) => state.NewFile);
  const listDocStackMassiveDataState = useSelector((state) => state.ListDocStackMassiveData);
  const dispatch = useDispatch();
  const theme = useTheme();

  const docAlreadyLoaded = () => {
    /* return newFileState.documentsStack.find(
      (doc) =>
        doc.DocumentConfiguration.DocumentTypeId === props.doc.DocumenType_Id
    ); */

    return (
      !!props.doc.DocResponseId || !!props.doc.DocumentData?.Id || props.index
    );
  };

  const handleRemoveDocument = async () => {
    try {
      const fileService = FileService.getInstance();
      const response = await fileService.DeleteDocument({
        Body: props.doc.DocResponseId || props.doc.DocumentData?.Id,
      });
      if (response.Body) {
        const newStack = newFileState.documentsStack.filter(
          (doc) =>
            (doc.DocResponseId || doc.DocumentData?.Id) !==
            (props.doc.DocResponseId || props.doc.DocumentData?.Id)
        );
        dispatch(setDocsStack(newStack));
        Alert({
          icon: "success",
          okbtntext: "Aceptar",
          text: `Documento eliminado`,
        });
      }
    } catch (error) {
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        // okbtnclass: "confirmButtonSweetCustom",
        text: `${error.message}`,
      });
    }
  };

  const handleRemoveMassiveDocument = async (key) => {
    try {
      // console.log("props.IsMassive: props.IsMassive,  props.doc.DocResponseId: ", props.IsMassive, props.doc.DocResponseId)
      const massiveService = MassiveService.getInstance();
      const response = await massiveService.DisableTemplate({
        BatchUploadDocument_Id: props.doc.DocResponseId,
      });
      if (response.Body) {
        console.log("Eliminate body:handleRemoveMassiveDocument ", response.Body)
        const newStack = listDocStackMassiveDataState.ListDocStack.filter((doc) =>
          doc.DocResponseId !== props.doc.DocResponseId,
        );
        console.log("newStack massive: ", newStack)
        dispatch(setListDocsStackMassive(newStack));
        Alert({
          icon: "success",
          okbtntext: "Aceptar",
          text: `Documento eliminado`,
        });
      }
    } catch (error) {
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        // okbtnclass: "confirmButtonSweetCustom",
        text: `${error.message}`,
      });
    }
  };



  if (props.customComponent) {
    return (
      <DocButton fullWidth sx={{ color: "#000" }} onClick={props.onSelect}>
        {props.customComponent}
      </DocButton>
    );
  }

  if (docAlreadyLoaded()) {
    return (
      <DocButtonLoaded
        style={props.style}
        fullWidth
        sx={{ color: "#000" }}
        component={Box}
      >
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {props?.preview ? (
              <img
                style={{ width: 13, marginRight: 5 }}
                alt="imagenLogo"
                src={iconDocument}
              />
            ) : (
              <CheckCircleOutlinedIcon
                sx={{
                  color: "#2b9348",
                  fontSize: "30px",
                  ml: 2,
                  mr: 1,
                }}
              />
            )}

            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: props?.preview
                  ? "12px !important"
                  : "14px !important",
              }}
            >
              Documento -{" "}
              {props.doc.DocumentConfiguration
                ? props.doc.DocumentConfiguration.FileName
                : props.doc.FileName || props.doc?.DocumentName}
            </Typography>
            {props.doc.Required && (
              <span
                style={{
                  marginLeft: 5,
                  color: theme.palette.primary.main,
                  fontSize: 23,
                  fontWeight: 500,
                }}
              >
                *
              </span>
            )}
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Typography
                variant="body2"
                component="span"
                sx={{
                  fontSize: "11px !important",
                }}
              >
                {props.preview === true
                  ? props.doc.OnlyView === true
                    ? "Lectura/Firma"
                    : "Revisión/Firma"
                  : null}
              </Typography>
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              {props.listDocs === true
                ? props.doc.DocumentConfiguration
                  ? props.doc.DocumentConfiguration?.OnlyView
                    ? "Lectura/Firma"
                    : "Revisión/Firma"
                  : props.doc.OnlyView === true
                    ? "Lectura/Firma"
                    : "Revisión/Firma"
                : null}
            </Box>
            {props?.preview ? null : (
              <Box>
                <Button
                  id={props.doc.DocResponseId || props.doc.DocumentData?.Id}
                  sx={{ p: 0, color: "#000", fontWeight: 400, ml: "auto" }}
                  onClick={() => {
                    if (props.IsMassive == true) {
                      handleRemoveMassiveDocument(props.key)
                    } else if (props.HandleRemoveList === undefined) {
                      handleRemoveDocument()
                    } else {
                      props.HandleRemoveList(props.doc)
                    }
                  }} >
                  <Icon icon="ph:trash-light" fontSize={19} />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DocButtonLoaded>
    );
  }

  return (
    <DocButton fullWidth sx={{ color: "#000" }} onClick={props.onSelect}>
      <AddCircleOutlineOutlinedIcon
        sx={{
          color: "primary.main",
          fontSize: "30px",
          ml: 2,
          mr: 1,
        }}
      />
      <Typography variant="body2" sx={{ fontSize: "14px !important" }}>
        {props?.doc?.Name || props?.doc?.FileName || props?.doc?.DocumentName}
      </Typography>
      {props.doc.Required && (
        <span
          style={{
            marginLeft: 8,
            color: theme.palette.primary.main,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          *
        </span>
      )}
    </DocButton>
  );
};
