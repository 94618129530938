import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class TemplatesServices {
    static instance;

    constructor() {
        this.baseUrl = "Template";
    }

    static getInstance() {
        if (!TemplatesServices.instance) {
            TemplatesServices.instance = new TemplatesServices();
        }
        return TemplatesServices.instance;
    }

    async handleResponse(response) {
        let respObject = response.data.data;

        if (respObject.ResponseCode !== 0) {
            const responseObject = {
                message: `${respObject.Messages ?? respObject.Message} ${respObject.TansactionId ? respObject.TansactionId : ""
                    } ${respObject.statusText ? respObject.statusText : ""}`,
            };
            const error = respObject || responseObject || respObject?.Body;
            return Promise.reject(error);
        }
        return respObject?.Body === undefined || respObject?.Body === null ? {
            Body:
                respObject?.Response !== undefined && respObject?.Response !== null ?
                    respObject.Response
                    : respObject
        } : respObject;
    }

    async handleResponseForceEncripted(response) {
        let respObject = BodySecurityResponseForceEncripted(response.data.data);
        if (!respObject.IsOK) {
            let responseObject = {
                message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
                    } ${respObject.statusText ? respObject.statusText : ""}`,
            };
            const error = respObject.Body || responseObject;
            return Promise.reject(error);
        }
        return respObject;
    }

    async UploadTemplateData(data) {
        try {
            const response = await GenericService.post({
                endpoint: `${this.baseUrl}/UploadTemplate2`,
                data: data,
                //  Config.setParametersForceEncripted(data, false),
                config: Config.configAuth(),
            });
            const dataUsers = await this.handleResponseForceEncripted(response);
            return dataUsers;
        } catch (error) {
            return error
        }
    }

    async GetTemplates(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetTemplates?categoria_Id=${data}`,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponse(response);
        return dataRes;
    };


    async GetTemplatesActive(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetTemplatesActive?categoria_Id=${data}`,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponse(response);
        return dataRes;
    };

    async GetCategoriesTemplate(data) {
        const response = await GenericService.get({
            endpoint: `${this.baseUrl}/GetCategoriesTemplate?references=${data}`,
            config: Config.configAuth(),
        });
        const dataRes = await this.handleResponse(response);
        return dataRes;
    };

    async UpdateDocumentTemplateFieldsCustom(data) {
        try {
            const response = await GenericService.post({
                endpoint: `${this.baseUrl}/UpdateDocumentTemplateFieldsCustom`,
                data: data,
                config: Config.configAuth(),
            });
            return response.data.data;
        } catch (error) {
            return error
        }
    }
    async ConvertDocToImagesProps(data) {
        try {
            const response = await GenericService.post({
                endpoint: `${this.baseUrl}/ConvertDocToImagesProps?documentTemplate_Id=${data}`,
                data: data,
                config: Config.configAuth(),
            });
            return response.data.data;
        } catch (error) {
            return error
        }
    }

    async AddTraceSignDocumentTemplate(data) {
        try {
            const response = await GenericService.post({
                endpoint: `${this.baseUrl}/AddTraceSignDocumentTemplate`,
                data: data,
                config: Config.configAuth(),
            });
            return response.data.data;
        } catch (error) {
            return error
        }
    }

    async DeleteTraceSignDocument(data) {
        try {
            const response = await GenericService.post({
                endpoint: `${this.baseUrl}/DeleteTraceSignDocument`,
                data: data,
                config: Config.configAuth(),
            });
            return response.data.data;
        } catch (error) {
            return error
        }
    }

    async GetContentTemplate(data) {
        try {
            const response = await GenericService.get({
                endpoint: `${this.baseUrl}/GetContentTemplate?template_Id=${data.template_Id}`,
                config: Config.configAuth(),
            });
            const dataRes = await this.handleResponse(response);
            return dataRes;
        } catch (error) {
            return error
        }
    };

    async GetRolesByFile(data) {
        try {
            const response = await GenericService.get({
                endpoint: `FileCustom/GetRolesByFile?file_id=${data}`,
                data: data,
                config: Config.configAuth(),
            });
            const dataRes = await this.handleResponse(response);
            return dataRes;
        } catch (error) {
            return error
        }
    }

    async GetFieldsCaptureParticipant(data) {
        try {
            const response = await GenericService.post({
                endpoint: `FileCustom/GetFieldsCaptureParticipant`,
                data: data,
                config: Config.configAuth(),
            });
            const dataRes = await this.handleResponse(response);
            return dataRes;
        } catch (error) {
            return error
        }
    }

    async GetDocumentTemplateFields(data) {
        try {
            const response = await GenericService.get({
                endpoint: `Template/GetDocumentTemplateFields?template_Id=${data}`,
                config: Config.configAuth(),
            });
            const dataRes = await this.handleResponse(response);
            return dataRes;
        } catch (error) {
            return error
        }
    }

}