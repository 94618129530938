import { Button, Dialog, Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { UseImage } from "../../components/UseImage";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { getFile, trueLoading } from "../../store/actions";
import { useNavigate } from "react-router-dom";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogFlowExpedient(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogoutClose = true;
  const listParticipants = useSelector(
    (state) => state.File?.item?.Participants
  );
  const rolUser = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        ?.Code
  );

  const fileState = useSelector((state) => state.File.item);

  const userSign = useSelector(
    (state) => state.Authentication.items.UserLoginData?.Email
  );

  const cerrarSesion = () => {
    sessionStorage.clear();
    navigate("/auth/login");
  };

  const renderSinglist = listParticipants?.filter((user) => user.Sign === true);

  const reenviarVentana = () => {
    if (props.openModalReenvio === true) {
      if (rolUser === "Operador" && userLogoutClose === true) {
        props.setOpenModal(!props.openModal);
        setTimeout(() => {
          cerrarSesion();
        }, 1000);
      } else {
        dispatch(getFile({ FileId: fileState.FileData.Id }));
        dispatch(trueLoading());
        setTimeout(() => {
          navigate("/inbox/files");
        }, 1000);
        props.setOpenModal(!props.openModal);
      }
    } else {
      props.setOpenModal(!props.openModal);
    }
  };

  // props.openModal
  return (
    <CustomDialog
      aria-labelledby="customized-dialog-title"
      open={props.openModal}
      fullWidth
      maxWidth={"sm"}
    >
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item lg={10}>
          <Grid container>
            <Grid item lg={12} xs={11} sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  mt: 4,
                  display: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography variant="h4">
                  Tu firma se envió con éxito
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UseImage type="Image" height={150} src="FirmaGifModal.gif" />
          </Box>
          <Box sx={{ mt: "1em" }}>
            <Box>
              <Typography variant="subtitle">Firmantes</Typography>
            </Box>
          </Box>
          <Box sx={{ mt: "1em", mb: "1em" }}>
            {renderSinglist?.map((user, index) => (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                {user.IsSignedAllDocuments === true ||
                userSign === user.Email ? (
                  <Icon
                    style={{ marginRight: ".5em", color: "#4ECB71" }}
                    fontSize={22}
                    fontWeight={600}
                    icon="material-symbols:check-circle-outline"
                  />
                ) : (
                  <Icon
                    style={{ marginRight: ".5em", color: "#EFDF52" }}
                    fontWeight={600}
                    fontSize={22}
                    icon="ic:round-access-time"
                  />
                )}
                <Typography variant="subtitle">
                  {props.isActiveMassiveSignature === true
                    ? `Firmante ${index + 1}`
                    : user.FullName}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              p: "1em",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ minWidth: 230 }}
              onClick={() => {
                reenviarVentana();
              }}
            >
              Continuar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}
