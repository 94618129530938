import { Category, Inject, ChartComponent, ColumnSeries, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts'
import React from 'react'
import arrayColors from './ArrayColors.json'

export default function ChartBarras({ data, name, idData }) {

    let newObjeto = data?.map((item) => {
        let randomIndex = Math.floor(Math.random() * 8);
        return {
            ...item,
            HexColor: arrayColors[randomIndex]
        };
    });

    const primaryxAxis = { valueType: 'Category' };

    return (
        <ChartComponent id={`${idData}`} primaryXAxis={primaryxAxis} title={`${name}`}>
            <Inject services={[ColumnSeries, Legend, LineSeries, Category]} />
            <SeriesCollectionDirective>
                <SeriesDirective dataSource={newObjeto} pointColorMapping="HexColor" xName='Nombre' type='Column' yName='Valor' name={`${name}`} />
            </SeriesCollectionDirective>
        </ChartComponent>
    )
}
