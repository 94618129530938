import React, { Fragment, useEffect, useState } from "react";
import { Accordion, AccordionDetails, Box, Grid, styled, AccordionSummary as AccSum, Typography } from "@mui/material";
import VisorPDF from "../../../components/Viewer/VisorPDF";
import { useSelector } from "react-redux";
import FirmaAvanzada from "./StagesSignature/FirmaAvanzada";
import CodigoOTP from "./StagesSignature/CodigoOTP";
import FirmaAutografa from "./StagesSignature/FirmaAutografa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocumentosFirmar from "./StagesSignature/DocumentosFirmar";
import GrabacionVideo from "./StagesSignature/GrabacionVideo";
import ValidacionBiometrica from "./StagesSignature/FirmaVideo/ValidacionBiometrica";
import DialogFlowExpedient from "../../inbox/DialogFlowExpedient";
import { MassiveFlowDocumentViewer } from "../../MassiveSignature/MassiveFlowDocumentViewer";
import IndexVideo from "./indexVideo";

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  padding: 0,
  margin: 0,
  width: '100%',
}));

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  try {
    let atob = require("atob");
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  } catch (e) {
    console.log("Advertencia: ", e)
  }
};

export default function SignatureDocument({ setValue, isActiveMassiveSignature }) {
  const stageFile = useSelector((state) => state.File);
  const [blobUrl, setBlobUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalReenvio, setOpenModalReenvio] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );

  const fileAuthentication = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.EnviromentVars
  );

  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.StageName
  );
  const stageFirma = useSelector((state) => state.File.item?.SignatureOptions);
  const participantStage = useSelector((state) => state.File.item?.SignatureOptions?.SignatureType);

  const stageName = useSelector((state) => state.File.item?.FileData?.Stage?.WorkflowInstance);

  const participant = stageFile?.item?.Participants?.filter(
    (part) => part.UserId === userData.Id && part.Sign === true
  );
  const ActiveCaptureFieldsState = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.Properties?.find(item => item.Name === "ActiveCaptureFields")?.Value
  );

  useEffect(() => {
    try {
      const blob = B64toBlob(stageFile?.document_64?.B64Content, "application/pdf");
      setBlobUrl(URL.createObjectURL(blob));
    } catch (e) {
      console.log("Advertencia: ", e)
    }
  }, [stageFile?.document_64?.B64Content])

  const stagesSingsExpediente = () => {
    switch (stageFileFlow) {
      case "Firma de documentos":
        return stageFirma?.SignatureType === "Avanzada" ? (
          <FirmaAvanzada setOpenModalReenvio={setOpenModalReenvio} setValue={setValue} setOpenModal={setOpenModal} isActiveMassiveSignature={isActiveMassiveSignature} />
        ) : (
          <FirmaAutografa setOpenModalReenvio={setOpenModalReenvio} setValue={setValue} setOpenModal={setOpenModal} isActiveMassiveSignature={isActiveMassiveSignature} />
        )
      case "Notificacion De Asignacion de Firma":
        return null;
      case "Asignando solicitud de firma":
        return null;
      case "Notificacion De Finalización de Firma":
        return null;
      case "Evidencias":
      case "EvidenciasValidacion":
        return fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true ?
          <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} /> :
          <IndexVideo setViewDocument={setViewDocument} setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />
      default:
        break;
    }
  };

  const stagesSingsParticipant = () => {
    switch (stageFileFlow) {
      case "Firma de documentos":
        return participant[0].SignatureOptions.SignatureType === "Avanzada" || participant[0].SignatureOptions.SignatureType === "Firma Electrónica Avanzada" ? (
          <FirmaAvanzada setOpenModalReenvio={setOpenModalReenvio} setValue={setValue} setOpenModal={setOpenModal} isActiveMassiveSignature={isActiveMassiveSignature} />
        ) : (
          <FirmaAutografa setOpenModalReenvio={setOpenModalReenvio} setValue={setValue} setOpenModal={setOpenModal} isActiveMassiveSignature={isActiveMassiveSignature} />
        )
      case "Notificacion De Asignacion de Firma":
        return null;
      case "Asignando solicitud de firma":
        return null;
      case "Notificacion De Finalización de Firma":
        return null;
      case "Evidencias":
      case "EvidenciasValidacion":
        return fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true ?
          <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} /> :
          <IndexVideo setViewDocument={setViewDocument} setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />
      default:
        break;
    }
  };

  console.log('stageFileFlow', stageFileFlow)

  const ValidationViews = () => {
    if (stageFileFlow === "Evidencias") {
      return fileAuthentication?.ConfigurationVideoValidates?.ShowEvidenceTextGeneric === true ?
        <GrabacionVideo setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} /> :
        <IndexVideo setViewDocument={setViewDocument} setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />
    } else if (stageFileFlow === "EvidenciasValidacion" || stageName?.CurrentStatus === "Completo") {
      return <ValidacionBiometrica setValue={setValue} ValidateStatus={true} isActiveMassiveSignature={isActiveMassiveSignature} />;
    } else if (stageFileFlow === "EvidenciasValidacion" || stageName?.CurrentStatus === "Validando Evidencia") {
      return <ValidacionBiometrica setValue={setValue} ValidateStatus={true} isActiveMassiveSignature={isActiveMassiveSignature} />;
    } else if (stageName?.CurrentStatus === "OTP") {
      return <CodigoOTP setValueStage={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />
    } else if (stageName?.CurrentStatus === "Aprovacion Documentos" || stageName?.CurrentStatus === "Inicializado"
      || (ActiveCaptureFieldsState === "1" && stageName?.CurrentStatus === "Integración de documentos")) {
      return <DocumentosFirmar setValue={setValue} isActiveMassiveSignature={isActiveMassiveSignature} />;
    } else if (stageName?.CurrentStatus === "Firma Documentos") {
      return participantStage ? stagesSingsExpediente() : stagesSingsParticipant();
    }
  };

  useEffect(() => {
    ValidationViews();
  }, [])

  return (
    <Fragment>
      <DialogFlowExpedient openModal={openModal} setOpenModal={setOpenModal} isActiveMassiveSignature={isActiveMassiveSignature} openModalReenvio={openModalReenvio} />
      <Grid container>
        <Grid item lg={6} md={6} xs={11.8}>
          {viewDocument === true ? null : (
            <>
              <Box sx={{ p: 1, display: { xs: 'none', md: 'block' } }}>
                {isActiveMassiveSignature == true ?
                  <MassiveFlowDocumentViewer />
                  :
                  <VisorPDF
                    pdfUrl={blobUrl ?? ""}
                    pdfName={stageFile?.document_64?.FileName}
                  />}
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="titulo" sx={{ fontWeight: 500, ml: 2 }}>
                      Documento a firmar
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ height: '100%' }}>
                    {isActiveMassiveSignature == true ?
                      <MassiveFlowDocumentViewer />
                      :
                      <VisorPDF
                        pdfUrl={blobUrl ?? ""}
                        pdfName={stageFile?.document_64?.FileName}
                      />}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </>
          )}
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {ValidationViews()}
        </Grid>
      </Grid>
    </Fragment>
  );
}
