import React, { Fragment, useRef, useState } from "react";
import { Box, IconButton, Tooltip, tooltipClasses } from "@mui/material";

import "simplebar/dist/simplebar.min.css";
import { styled } from "@mui/system";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  Add,
  Fullscreen,
  Remove,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const PdfContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isfullscreen",
})(({ height, isfullscreen, theme }) => ({
  width: "100%",
  margin: "10px auto",
  height: height === undefined || height === null ? 690 : height,
  overflow: "hidden",
  position: "relative",
  "& .react-pdf__Document": {
    // width: "100%",
    backgroundColor: "#888",
    minHeight: isfullscreen ? "100vh" : 60,
    height: height === undefined || height === null ? 690 : height,
    display: "flex",
    flexDirection: "column",
  },
  "& .react-pdf__Page": {
    flexGrow: 1,
    padding: "8px 8px",
    display: "flex",
    justifyContent: "center",
  },
  "& .react-pdf__Page__annotations": {
    display: "none",
  },
  "& canvas": {
    padding: "10px 0px",
    /*  paddingRight: 90, */
    margin: "0 auto",
  },
  [theme.breakpoints.down("sm")]: {
    height: 400,
    "& .react-pdf__Page": {
      padding: "5px 5px",
    },
    "& .react-pdf__Document": {
      height: 400,
    },
    "& canvas": {
      // padding: "10px 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    height: 400,
    "& .react-pdf__Document": {
      height: 400,
    },
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const VisorPDF = (propsDoc) => {
  const [isFullScreen, setisFullScreen] = useState(false);
  const pdfRef = useRef();
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //"https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js"

  const handleDownload = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        justifyItems: "flex-end",
      }}
    >
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          CurrentScale,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ZoomIn,
          ZoomOut,
          EnterFullScreen,
          Download,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut>
                {(props) => (
                  <LightTooltip placement="top" title="Alejar">
                    <IconButton onClick={props.onClick}>
                      <Remove sx={{ color: "#000" }} fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                )}
              </ZoomOut>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <CurrentScale>
                {(props) => <span>{`${Math.round(props.scale * 100)}%`}</span>}
              </CurrentScale>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn>
                {(props) => (
                  <LightTooltip placement="top" title="Acercar">
                    <IconButton onClick={props.onClick}>
                      <Add sx={{ color: "#000" }} fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                )}
              </ZoomIn>
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <GoToPreviousPage>
                {(props) => (
                  <LightTooltip placement="top" title="Página anterior">
                    <IconButton
                      sx={{ ml: "auto" }}
                      disabled={props.isDisabled}
                      onClick={props.onClick}
                    >
                      <SkipPrevious fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                )}
              </GoToPreviousPage>
            </div>
            <div style={{ padding: "0px 2px", width: "3rem" }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage>
                {(props) => (
                  <LightTooltip placement="top" title="Página siguiente">
                    <IconButton
                      disabled={props.isDisabled}
                      onClick={props.onClick}
                    >
                      <SkipNext fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                )}
              </GoToNextPage>
            </div>
            {propsDoc?.downloadActive === true &&
            (propsDoc.downloadActive !== null ||
              propsDoc.downloadActive !== undefined) ? (
              <div style={{ padding: "0px 2px" }}>
                <Download>
                  {(props) => (
                    <LightTooltip placement="top" title="Descargar">
                      <IconButton
                        onClick={() =>
                          handleDownload(propsDoc.pdfUrl, propsDoc.pdfName)
                        }
                      >
                        <FileDownloadIcon
                          sx={{ color: "#000" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </LightTooltip>
                  )}
                </Download>
              </div>
            ) : null}
            <div style={{ padding: "0px 2px" }}>
              <EnterFullScreen>
                {(props) => (
                  <LightTooltip placement="top" title="Pantalla completa">
                    <IconButton onClick={props.onClick}>
                      <Fullscreen sx={{ color: "#000" }} fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                )}
              </EnterFullScreen>
            </div>

            {propsDoc.changeFile && (
              <LightTooltip placement="top" title="Eliminar documento">
                <IconButton onClick={propsDoc.changeFile}>
                  <Icon
                    icon="ph:trash-light"
                    fontSize={18}
                    style={{ color: "#000" }}
                  />
                </IconButton>
              </LightTooltip>
            )}
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <Fragment>
      {propsDoc.pdfUrl && (
        <Worker workerUrl={pdfjsWorker}>
          <PdfContainer
            ref={(el) => (pdfRef.current = el)}
            // scrollableNodepropsDoc={{ ref: (el) => (pdfRef.current = el) }}
            isfullscreen={isFullScreen}
            height={propsDoc.height}
          >
            <Viewer
              fileUrl={propsDoc.pdfUrl}
              plugins={[defaultLayoutPluginInstance]}
            />
          </PdfContainer>
        </Worker>
      )}
    </Fragment>
  );
};

export default VisorPDF;
