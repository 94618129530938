import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "rgba(0, 0, 0, 0.3)"
  },
}));

export default function BackdropComponent({ loading }) {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading ? loading : false} /* onClick={handleClose} */>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

