import { AccumulationChartComponent, AccumulationDataLabel, AccumulationLegend, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject, PieSeries } from '@syncfusion/ej2-react-charts'
import React from 'react'
import arrayColors from './ArrayColors.json'

export default function ChartPieRadius({ data, name, idData }) {

    let newObjeto = data?.map((item) => {
        let randomIndex = Math.floor(Math.random() * 8);
        return {
            ...item,
            HexColor: arrayColors[randomIndex]
        };
    });

    return (
        <AccumulationChartComponent id={`${idData}`} legendSettings={{ visible: true, reverse: true }} enableSmartLabels={true} title={`${name}`} enableBorderOnMouseMove={false} enableAnimation={true}
            tooltip={{ enable: true, format: '<b>${point.x}</b><br/>Cantidad: <b>${point.y}' }}
        >
            <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
            <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective dataSource={newObjeto} pointColorMapping="HexColor" xName='Nombre' yName='Valor' innerRadius='50%' tooltipMappingName='100' dataLabel={{ visible: true, position: 'Outside', name: 'Name', enableRotation: true, font: { fontWeight: '600' }, connectorStyle: { length: '20px', type: 'Curve' } }} radius='140' />
            </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
    )
}
