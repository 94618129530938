import React from 'react'
import { styled, Button } from "@mui/material";

const ButtonCustom = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  lineHeight: 1.5,
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  color: '#FFFFFF',
  borderRadius: '100px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    borderRadius: '31px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '&:focus': {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const CustomButton = (props) => {
  return (
    <ButtonCustom {...props} />
  )
}