import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class ProductService {
  static instance;

  constructor() {
    this.baseUrl = "product";
  }

  static getInstance() {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService();
    }
    return ProductService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (respObject.ResponseCode !== 0) {
        const responseObject = {
            message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
                } ${respObject.statusText ? respObject.statusText : ""}`,
        };
        const error = respObject.Body || responseObject;
        return Promise.reject(error);
    }
    return respObject?.Body === undefined || respObject?.Body === null ? { Body: respObject } : respObject;
}

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (respObject.ResponseCode !== 0) {
        let responseObject = {
            message: `${respObject.Messages ?? respObject.Message} ${respObject.TansactionId ? respObject.TansactionId : ""
                } ${respObject.statusText ? respObject.statusText : ""}`,
        };
        const error = respObject.Body || responseObject;
        return Promise.reject(error);
    }

    return respObject?.Body === undefined || respObject?.Body === null ? { Body: respObject } : respObject;
}

  async GetQuestionTypes(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetQuestionTypes `,
      data: data,
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }
}
