import { fileConstants } from "../constants";
import { FileService } from "../../services/FileService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

const emptyAction = (actionType) => {
  return {
    type: actionType,
  };
};

export const getFiles = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileConstants.FILES_REQUEST));
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.getFiles(filesData);
      dispatch(success(fileData.Body, fileConstants.FILES_SUCCESS));
    } catch (error) {
      console.log("errorBandeja", error)
      dispatch(failure(error.message, fileConstants.FILES_FAILURE));
    }
  };
};

export const getGlobalFiles = (filesData) => {
  return async (dispatch) => {
    dispatch(request(fileConstants.FILES_REQUEST));
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.getGlobalFiles(filesData);
      dispatch(success(fileData.Body, fileConstants.FILES_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, fileConstants.FILES_FAILURE));
    }
  };
};

export const setFiles = (filesData) => {
  return {
    type: fileConstants.SET_FILES,
    payload: filesData,
  };
};

export const getTextEvidence = (textData) => {
  return async (dispatch) => {
    dispatch(request(fileConstants.TEXT_EVIDENCE_REQUEST));
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.getTextEvidence(textData);
      dispatch(success(fileData.Body, fileConstants.TEXT_EVIDENCE_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, fileConstants.TEXT_EVIDENCE_FAILURE));
    }
  };
};

export const ReorderParticipant = (reorderData) => {
  return async (dispatch) => {
    dispatch(request(fileConstants.REORDER_PARTICIPANT_REQUEST));
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.ReorderParticipant(reorderData);
      dispatch(success(fileData.Body, fileConstants.REORDER_PARTICIPANT_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, fileConstants.REORDER_PARTICIPANT_SUCCESS));
    }
  };
};

export const RegisterPrerequisites = (regPrerequisitesData) => {
  return async (dispatch) => {
    dispatch(request(fileConstants.REGISTER_PREREQUISITES_REQUEST));
    try {
      const fileService = FileService.getInstance();
      const fileData = await fileService.RegisterPrerequisites(regPrerequisitesData);
      dispatch(success(fileData.Body, fileConstants.REGISTER_PREREQUISITES_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, fileConstants.REGISTER_PREREQUISITES_FAILURE));
    }
  };
};

export const IntegrateDocumentToSign = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(fileConstants.INTEGRATE_DOCUMENT_TOSIGN_EMPTY));
    } else {
      dispatch(request(fileConstants.INTEGRATE_DOCUMENT_TOSIGN_REQUEST));
      try {
        const fileService = FileService.getInstance();
        const fileData = await fileService.IntegrateDocumentToSign(data);
        dispatch(success(fileData.Body, fileConstants.INTEGRATE_DOCUMENT_TOSIGN_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, fileConstants.INTEGRATE_DOCUMENT_TOSIGN_FAILURE));
      }
    }
  };
};

export const RegisterDocumentFields = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(fileConstants.REGISTER_DOCUMENT_FIELDS_EMPTY));
    } else {
      dispatch(request(fileConstants.REGISTER_DOCUMENT_FIELDS_REQUEST));
      try {
        const fileService = FileService.getInstance();
        const fileData = await fileService.RegisterDocumentFields(data);
        dispatch(success(fileData.Body, fileConstants.REGISTER_DOCUMENT_FIELDS_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, fileConstants.REGISTER_DOCUMENT_FIELDS_FAILURE));
      }
    }
  };
};

export const GetFilesCustomMasive = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(fileConstants.GETFILES_CUSTOM_MASSIVE_EMPTY));
    } else {
      dispatch(request(fileConstants.GETFILES_CUSTOM_MASSIVE_REQUEST));
      try {
        const fileService = FileService.getInstance();
        const fileData = await fileService.GetFilesCustomMasive(data);
        dispatch(success(fileData.Body, fileConstants.GETFILES_CUSTOM_MASSIVE_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, fileConstants.GETFILES_CUSTOM_MASSIVE_FAILURE));
      }
    }
  };
};

export const RegisterBatchUploadSignedProcess = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_EMPTY));
    } else {
      dispatch(request(fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_REQUEST));
      try {
        const fileService = FileService.getInstance();
        const fileData = await fileService.RegisterBatchUploadSignedProcess(data);
        dispatch(success(fileData.Body, fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_FAILURE));
      }
    }
  };
};

export const UploadMetadataBatchFileFC = (data, isEmpty = false) => {
  return async (dispatch) => {
    if (isEmpty) {
      dispatch(emptyAction(fileConstants.UPLOADMETADATAFC_BATCHFILE_EMPTY));
    } else {
      dispatch(request(fileConstants.UPLOADMETADATAFC_BATCHFILE_REQUEST));
      try {
        const fileService = FileService.getInstance();
        const fileData = await fileService.UploadMetadataBatchFile(data);
        dispatch(success(fileData.Body, fileConstants.UPLOADMETADATAFC_BATCHFILE_SUCCESS));
      } catch (error) {
        dispatch(failure(error.message, fileConstants.UPLOADMETADATAFC_BATCHFILE_FAILURE));
      }
    }
  };
};